import riot from 'riot'
 import { UserService } from '@common/services'
import './onboarding.scss'
riot.tag2('td-onboarding-admin-finish', '<div class="onboarding"> <div class="header"> <div class="col"> <div class="py-30 px-5 logo"> <img riot-src="{app.imageLogo}" height="{app.imageSize}"> </div> </div> </div> <div class="content intro"> <div class="title"> Parabéns! <br>Você concluiu a configuração inicial do TD Premium </div> <div class="subtitle mt-10"> Agora os usuários da sua empresa podem acessar o TD Premium. Você também já pode entrar na plataforma com o seu perfil de usuário ou ainda acompanhar quem da sua equipe concluiu o diagnóstico de percepção de maturidade digital. </div> <div class="btn-group mt-10"> <a href="configuracao/usuario" class="btn btn-hero"> Conferir lista de usuários </a> </div> <div class="btn-group mt-10"> <a href="" class="btn btn-hero btn-white"> Acessar TD Premium como usuário </a> </div> </div> </div>', '', '', function(opts) {


        const init = async () => {
            this.setMetaData('onboardingAdminFinished', true)
            await this.saveMetaData()
        }

        init()
});