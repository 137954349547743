import riot from 'riot'
 import './profile.scss'
import store from '@td-premium/store'
import avatarDefault from '@common/assets/media/avatars/avatar0.jpg'
import { UserService, UploadService } from '@common/services'
riot.tag2('td-profile', '<div class="content content-top"> <h1 class="main-title" if="{isOwnerOrAdmin()}"> <breadcrumb></breadcrumb> </h1> <div class="profile-sidebar col-3"> <div class="user-group"> <input style=" width: 0px; height: 0px; display: none; visibility: hidden; " ref="inputUpload" type="file"> <div class="photo" onclick="{changeAvatar}"> <span class="btn-change-photo"> <i class="fa fa-camera"></i> </span> <div class="avatar"> <img riot-src="{user.avatar || avatarDefault}" alt=""> </div> </div> <div class="user-name"> {user.name} </div> </div> <form action="#"> <td-input label="Email" required="{true}" error="error.email" model="user.email" disabled="{true}" type="email"></td-input> <td-input label="Nome Completo" required="{true}" model="user.name" error="error.name" disabled="{true}"></td-input> <td-input if="{isOwnerOrAdmin()}" label="CPF" placeholder="---" model="user.document" error="error.document" disabled="{true}"></td-input> <td-input if="{isOwnerOrAdmin()}" label="Telefone" placeholder="---" model="user.phone" error="error.phone" disabled="{true}"></td-input> </form> <a if="{isOwnerOrAdmin()}" href="perfil/editar" class="btn btn-hero btn-white"> Editar Perfil </a> </div> <div class="profile-content"> <yield></yield> </div> </div>', '', '', function(opts) {

        let session = store.get('session') || {}

        this.avatarDefault = avatarDefault
        this.user = session.user || {}

        this.on('update', () => {
            let session = store.get('session') || {}
            this.user = session.user || {}
        })

        this.on('mount', () => {

            const input = this.refs.inputUpload

            input
            .addEventListener('change', async (e) => {

                var file = input.files[0]
                var imageType = /image.*/

                if (!file) return

                if (file.type.match(imageType)) {
                    var reader = new FileReader();

                    reader.onload = (e) => {
                        this.value = reader.result
                        this.update();
                    }

                    reader.readAsDataURL(file)
                    this.loading = true
                    try {
                        const result = await UploadService.image(file, opts.data)
                        await UserService.current.put({
                            avatar: result[0]
                        })

                        this.user.avatar = result[0]

                        let session = store.get('session') || {}
                        session.user = this.user
                        store.set('session', session)

                        this.app.update()

                    } catch (error) {}

                    setTimeout(() => {
                        this.loading = false
                        this.update()
                    }, 1000)
                }

            })
        })

        this.changeAvatar = (e) => {
            e.preventDefault()
            this.refs.inputUpload.value = ''
            this.refs.inputUpload.click()
        }

});