import riot from 'riot'
 import { ReportService, DepartmentService } from '@common/services'
riot.tag2('td-dashboard-sector-consideration', '<div class="dashboard-card"> <div class="dashboard-card__header"> <div class="dashboard-card__header-title"> Considero o setor que eu trabalho altamente eficiente e produtivo </div> </div> <div class="dashboard-card__content"> <div class="dashboard-card__filter" show="{!opts.hideDepartment}"> <span class="dashboard-card__filter-label">Filtrar por</span> <div class="dashboard-card__filter-content"> <td-select class="select-small" model="filters.department" error="error.department" extra-data="{extraData}" onchange="{loadData}" onstart="{loadData}" data-value="_id" data-text="name" service="{DepartmentService}"></td-select> </div> </div> <td-chart-multi-bars if="{data}" data="{data}"></td-chart-multi-bars> </div> <div class="dashboard-card__footer dashboard-card__footer-legend-space"></div> </div>', '', '', function(opts) {

        this.DepartmentService = DepartmentService

        this.extraData = [{ _id: '', name: 'Todos' }]

        this.filters = {
            department: ''
        }

        this.query = {}

        const loadData = async (query) => {

            this._chartQueryString = JSON.stringify(opts.chartQuery)
            this.query = { ...this.query, ...query, ...opts.chartQuery }

            const data = await ReportService.getType('feedback-q2', this.query)

            const items = [
                {
                    label: 'Discordo totalmente',
                    value: data.totallyDisagree,
                    bgColor: '#ce00ff',
                    bgGradient: 'linear-gradient(to bottom, #b800ff 0%,#7f00ff 100%)'
                },
                {
                    label: 'Discordo em partes',
                    value: data.partlyDisagree,
                    bgColor: '#005bff',
                    bgGradient: 'linear-gradient(to bottom, #0097ff 0%,#0053ff 100%)'
                },
                {
                    label: 'Concordo em partes',
                    value: data.agreeInParts,
                    bgColor: '#00ceff',
                    bgGradient: 'linear-gradient(to bottom, #00e3ff 0%,#00a7ff 100%)'
                },
                {
                    label: 'Concordo totalmente',
                    value: data.agreeTotally,
                    bgColor: '#71ec8e',
                    bgGradient: 'linear-gradient(to bottom, #88f3aa 0%,#50e366 100%)'
                }
            ]

            this.update({
                data: { items }
            })
        }

        this.loadData = (data) => {
            if (opts.hideDepartment) {
                return;
            }
            loadData({department: data ? data._id || data : null})
        }

        if (opts.hideDepartment) {
            loadData()
        }

        this.on('update', () => {
            if ( this._chartQueryString != JSON.stringify(opts.chartQuery)) {
                this.data = null
                loadData()
            }
        })
});