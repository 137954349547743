import riot from 'riot'
 import './content-card.scss'
import { EventService } from '@common/services'
riot.tag2('content-card', '<div class="content-card"> <a href="{opts.link ? opts.link(item) : link(item)}" class="block block-rounded bg-image block-transparent d-flex flex-column {item.showTitle ? \'overlay-title\':\'\'}" riot-style="background-image: url(\'{item.image.thumbnailHorizontal}\');"> <div class="block-content py-0 bg-black-op flex-grow-1 d-flex flex-column justify-content-between"> <div class="d-flex justify-content-between mt-10"> <div class="block-play hover-show"> <i class="si si-control-play"></i> </div> <i if="{isLockContent(item)}" class="si si-lock"></i> </div> <div> <h5 if="{item.showTitle}" class="text-white font-w700 mb-5"> {item.title} </h5> <div class="d-flex justify-content-between mb-10"> <p if="{item.video}" class="video-duration text-muted text-white-op mb-0"> {videoTime(item.video.duration)} </p> <div if="{!opts.hideMylist}" class="block-actions ml-1"> <button if="{!item.myList}" onclick="{myList}" type="button" data-toggle="tooltip" data-placement="top" title="Adicionar à minha lista" class="btn btn-sm btn-circle btn-alt-info hover-show"> <i class="si si-plus"></i> </button> <button if="{item.myList}" type="button" onclick="{myList}" data-toggle="tooltip" data-placement="top" title="Remover da minha lista" class="btn btn-sm btn-circle btn-alt-info"> <i class="si si-check"></i> </button> </div> </div> </div> </div> </a> </div>', '', '', function(opts) {

        this.myList = async (e) => {
            e.preventDefault()

            this.item.myList = !this.item.myList

            this.update()

            await EventService.post({
                content: this.item._id,
                type: 'myList',
                value: this.item.myList
            })
        }

        this.link = (item) => {

            return (opts.path ? opts.path + '/' : '') + item._id
        }

        this.getProgress = (item) => {
            if (!item.progress) {
                return { width: '0%' }
            }
            return { width: this.item.progress.percent * 100 + '%' }
        }

        if (opts.item) this.item = opts.item

        this.on('update', () => {
            if (opts.item) this.item = opts.item
        })

});