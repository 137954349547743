import riot from 'riot'
 import { EventService } from '@common/services'
riot.tag2('content-button-mylist', '<div style="font-size: 25px; cursor: pointer;" class="control-btn add-list" onclick="{myList}"> <i if="{!opts.content.myList}" ref="addList" data-toggle="tooltip" data-placement="top" title="Adicionar à minha lista" class="si si-plus"> </i> <i if="{opts.content.myList}" ref="removeList" data-toggle="tooltip" data-placement="top" title="Remover da minha lista" class="si si-check"> </i> </div>', '', '', function(opts) {

        this.myList = async (e) => {
            e.preventDefault()

            opts.content.myList = !opts.content.myList

            $('.tooltip').remove()

            this.update()

            await EventService.post({
                content: opts.content._id,
                type: 'myList',
                value: opts.content.myList
            })
        }

        this.on('update', () => {
            $(this.refs.removeList).tooltip({ boundary: 'window' })
            $(this.refs.addList).tooltip({ boundary: 'window' })
        })

        this.on('mount', () => {
            $(this.refs.removeList).tooltip({ boundary: 'window' })
            $(this.refs.addList).tooltip({ boundary: 'window' })
        })
});