
const intro = {
    router: "intro",
    field: "intro",
    title: "Bem vindo ao TD Premium", 
    bgClass: 'bg-gradient-red score-welcome',
    hideInList: true,
    questions: [
        {
            title: `
            <p>
                Você começa a partir de agora uma nova etapa de descoberta e desenvolvimento na transformação digital.
                E para ter uma melhor experiência no TD Premium, convidamos você a responder o diagnóstico de 
                maturidade digital. É bem prático e leva apenas 10 minutos. Vamos lá! <br /><br />
                <small><i class="text-white">(Para melhor experiência recomendamos utilizar o navegador chrome)</i></small>
            </p>
            `,
            type: 'intro',
            button: {
                label: "Iniciar Diagnostico",
                link: "score/cultura-e-lideranca/0"
            } 
        }
    ]
}


const cultura_e_lideranca = {
    router: "cultura-e-lideranca",
    field: "cultura_e_lideranca",
    title: "Cultura e Liderança", 
    bgClass: 'bg-gradient-blue',
    type: 'dimension',
    desc: `Abordagem: Mindset, aspectos de liderança, inovação.`,
    questions: [
        {
            title: `
                <p>
                    A transformação digital nos fala sobre uma nova forma de pensar, criando uma cultura digital e
                    formando líderes dispostos a encarar os desafios que surgem nesse novo contexto.
                </p> <br />

                <p class="score-small">
                    Tópicos deste bloco:
                    Mindset, aspectos de liderança, inovação.
                </p>
            `,
            type: 'intro'     
        },
        {
            title: 'A transformação digital está mais para',
            tema: 'Mindset digital',
            type: 'options',
            answers: [
                {
                    label: 'uma jornada contínua.',
                    correct: true
                },
                {
                    label: 'uma meta a ser alcançada.',
                    correct: false
                }
            ]                        
        },
        {
            title: 'Sou reconhecido como um líder inovador por aqueles que convivem comigo',
            tema: 'Liderança',
            type: 'like-or-deslike'                   
        },
        {
            title: 'Costumo compartilhar experiências e conhecimento com minha equipe de trabalho',
            type: 'slider'
        },
        {
            title: 'No desenvolvimento de um novo negócio digital eu prefiro investir mais em',
            tema: 'Metodologias Ágeis',
            type: 'options',
            answers: [
                {
                    label: 'equipes multifuncionais em ambientes mais flexíveis para co-criação.',
                    correct: true
                },
                {
                    label: 'profissionais altamente técnicos em demandas específicas.',
                    correct: false
                }
            ]    
        },
        {
            title: 'A autonomia dos profissionais no trabalho é um indício de maturidade digital',
            tema: 'Mindset digital',
            type: 'slider'
        },
        {
            title: 'Consigo colaborar mais com as empresas',
            tema: 'Mindset digital',
            type: 'options',
            answers: [
                {
                    label: 'desenvolvendo ideias sozinho.',
                    correct: false
                },
                {
                    label: 'contribuindo com as ideias dos outros.',
                    correct: true
                }
            ]    
        }
    ]
}


const agilidade_operacional = {
    router: "agilidade-operacional",
    field: "agilidade_operacional",
    title: "Agilidade Operacional", 
    bgClass: 'bg-gradient-green',
    type: 'dimension',
    desc: `Abordagem: Ferramentas, metodologias ágeis, processos.`,
    questions: [
        {
            title: `
                <p>
                    A otimização dos processos entra como um dos pilares da TD, visando alcançar
                    mais agilidade das empresas, trazer respostas às demandas dos clientes e proporcionar
                    serviços mais eficientes.
                </p> <br />

                <p class="score-small">
                    Tópicos deste bloco:
                    Ferramentas, metodologias ágeis, processos.
                </p>
            `,
            type: 'intro'                      
        },
        {
            title: 'Quando desenvolvo novas ideias, prefiro',
            tema: 'Metodologias ágeis',
            type: 'options',
            answers: [
                {
                    label: 'opções mais elaboradas, reduzindo o risco de erros.',
                    correct: false
                },
                {
                    label: 'modelos simples, com menos qualidade, porém fáceis de testar.',
                    correct: true
                }
            ]                        
        },
        {
            title: 'Dos processos que executo utilizo automação em todos que são possíveis de serem automatizados.',
            type: 'slider'                    
        },
        {
            title: 'Reviso com frequência os processos que envolvem meu trabalho buscando melhor gestão dos recursos',
            tema: 'Metodologias ágeis',
            type: 'like-or-deslike'                    
        },
        {
            title: 'Gestão do tempo e produtividade são minhas características como profissional',
            type: 'slider'                    
        },
        {
            title: 'Das metodologias e práticas abaixo tenho estudado e/ou aplicado',
            tema: 'Metodologias ágeis',
            type: 'options',
            multiple: true,
            answers: [
                {
                    label: 'Squads',
                    correct: true
                },
                {
                    label: 'Scrum',
                    correct: true
                },
                {
                    label: 'Kanban',
                    correct: true
                },
                {
                    label: 'Lean Startups',
                    correct: true
                },
                {
                    label: 'Design Thinking',
                    correct: true
                },
                {
                    label: 'Canvas',
                    correct: true
                }
            ]                        
        },
        {
            title: 'Eu sou uma pessoa resiliente, flexível e disposta a ouvir posições divergentes.',
            tema: 'Mindset digital',
            type: 'slider'                
        }
    ]
}


const tecnologia_digital = {
    router: "tecnologia-digital",
    field: "tecnologia_digital",
    title: "Tecnologia Digital", 
    bgClass: 'bg-gradient-red',
    type: 'dimension',
    desc: `Abordagem: Análise de dados, métricas, indicadores, segurança, tecnologias futuras, gestão da informação.`,
    questions: [
        {
            title: `
                <p>
                    A tecnologia é base para o que chamamos de transformação digital. 
                    A evolução constante das ferramentas abrem novas perspectivas para os processos
                    e a relação das pessoas.
                </p> <br />

                <p class="score-small">
                    Tópicos deste bloco:
                    Análise de dados, métricas, indicadores, segurança, tecnologias futuras, gestão da informação.
                </p>
            `,
            type: 'intro'                      
        },
        {
            title: 'Tomo decisões e defino estratégias com base em sistemas analíticos',
            tema: 'Orientação a dados',
            type: 'like-or-deslike'                       
        },
        {
            title: 'Prefiro fazer o controle de dados em',
            tema: 'Orientação a dados',
            type: 'options',
            answers: [
                {
                    label: 'documentos do Word, planilhas do Excel e agendas eletrônicas.',
                    correct: false
                },
                {
                    label: 'dashboards, CRMs e G Suíte.',
                    correct: true
                }
            ]                    
        },
        {
            title: 'Com o uso de cloud computing percebo benefícios como',
            type: 'options',
            multiple: true,
            answers: [
                {
                    label: 'Segurança',
                    correct: true
                },
                {
                    label: 'Escala',
                    correct: true
                },
                {
                    label: 'Redução de custos',
                    correct: true
                },
                {
                    label: 'Flexibilidade',
                    correct: true
                },
                {
                    label: 'Sustentabilidade',
                    correct: true
                },
                {
                    label: 'Portabilidade',
                    correct: true
                }
            ]                     
        },
        {
            title: 'Agregar tecnologias de big data pode ajudar empresas a',
            tema: 'Orientação a dados',
            type: 'options',
            answers: [
                {
                    label: 'traduzir dados em vantagem competitiva.',
                    correct: true
                },
                {
                    label: 'usar os dados com exatidão para análises preditivas.',
                    correct: false
                }
            ]                  
        },
        {
            title: 'Considero como tecnologias que integram com chatbots',
            type: 'options',
            multiple: true,
            answers: [
                {
                    label: 'Machine learning',
                    correct: true
                },
                {
                    label: 'Inteligência artificial',
                    correct: true
                },
                {
                    label: 'Blockchain',
                    correct: true
                },
                {
                    label: 'Internet das coisas',
                    correct: true
                }
            ]                        
        },
        {
            title: 'Conheço os aspectos da legislação para segurança de dados',
            type: 'like-or-deslike'                
        }
    ]
}



const foco_no_cliente = {
    router: "foco-no-cliente",
    field: "foco_no_cliente",
    title: "Foco no Cliente", 
    bgClass: 'bg-gradient-pink',
    type: 'dimension',
    desc: `Abordagem: Experiência do cliente, persona, customer centric, customer success.`,
    questions: [
        {
            title: `
                <p>
                    Sejam como clientes internos ou externos, as pessoas assumem papel central 
                    na jornada da transformação digital. Com cada vez mais acesso à tecnologia,
                    o consumidor exige das empresas novas posições.
                </p> <br />

                <p class="score-small">
                    Tópicos deste bloco:
                    Experiência do cliente, persona, customer centric, customer success.
                </p>
            `,
            type: 'intro'                      
        },
        {
            title: 'Vejo como necessária a aplicação do conceito de customer experience nas estratégias de',
            tema: 'Mindset digital',
            type: 'options',
            multiple: true,
            answers: [
                {
                    label: 'Marketing',
                    correct: true
                },
                {
                    label: 'Vendas',
                    correct: true
                },
                {
                    label: 'Pós-Vendas',
                    correct: true
                }
            ]                      
        },
        {
            title: 'Os clientes tendem a ficar mais satisfeitos quando',
            type: 'options',
            answers: [
                {
                    label: 'conheço suas necessidades e facilito o contato por canais digitais.',
                    correct: true
                },
                {
                    label: 'oferto descontos com frequência e disponibilizo novos produtos.',
                    correct: false
                }
            ]                    
        },
        {
            title: 'Conheço as personas do meu negócio e reviso elas constantemente',
            type: 'like-or-deslike'      
        },
        {
            title: 'Considero importante levar em conta no desenvolvimento de novas soluções para clientes',
            tema: 'Metodologias Ágeis',
            type: 'options',
            multiple: true,
            answers: [
                {
                    label: 'Feedback dos clientes',
                    correct: true
                },
                {
                    label: 'Business Intelligence',
                    correct: true
                },
                {
                    label: 'Reuniões com equipe interna',
                    correct: true
                },
                {
                    label: 'Plano de negócio',
                    correct: false
                },
                {
                    label: 'Mapas mentais',
                    correct: true
                },
                {
                    label: 'Não auxilio no desenvolvimento de soluções',
                    correct: false
                }
            ]                      
        },
        {
            title: 'Reconheço a gamificação como uma possibilidade para retenção de clientes',
            type: 'slider'            
        },
        {
            title: 'Dentre os indicadores relacionados ao sucesso do cliente considero importante avaliar',
            tema: 'Orientação a dados',
            type: 'options',
            multiple: true,
            answers: [
                {
                    label: 'Lifetime Value',
                    correct: true
                },
                {
                    label: 'Churn',
                    correct: true
                },
                {
                    label: 'MQL',
                    correct: false
                }
            ]
        }
    ]
}


const visao_de_negocio = {
    router: "visao-de-negocio",
    field: "visao_de_negocio",
    title: "Visão de Negócio", 
    bgClass: 'bg-gradient-orange',
    type: 'dimension',
    desc: `Abordagem: Desenvolvimento de soluções, produtividade, crescimento exponencial, evolução do mercado.`,
    questions: [
        {
            title: `
                <p>
                    Empreendedorismo é a disposição ou capacidade de idealizar, coordenar e realizar projetos,
                    serviços e/ou negócios. Toda a mudança ocasionada pela TD estimula os profissionais a 
                    expandirem sua visão de negócio.
                </p> <br />

                <p class="score-small">
                    Tópicos deste bloco:
                    Desenvolvimento de soluções, produtividade, crescimento exponencial, evolução do mercado.
                </p>
            `,
            type: 'intro'                      
        },
        {
            title: 'No contexto empresarial, a transformação digital impacta em',
            type: 'options',
            multiple: true,
            answers: [
                {
                    label: 'Processos',
                    correct: true
                },
                {
                    label: 'Ferramentas',
                    correct: true
                },
                {
                    label: 'Pessoas',
                    correct: true
                }
            ]                      
        },
        {
            title: 'Empresas com foco em transformação digital visam',
            tema: 'Mindset digital',
            type: 'options',
            answers: [
                {
                    label: 'mudanças rápidas, assertividade nas informações e ciclo de vida de conhecimento reduzido.',
                    correct: true
                },
                {
                    label: 'aprendizado para longa duração, rotinas pré-estabelecidas e controle no fluxo de informações.',
                    correct: false
                }
            ]                    
        },
        {
            title: 'As soluções móveis apresentam baixo potencial de impacto na geração de receita das empresas.',
            tema: 'Orientação a dados',
            reverse: true,
            type: 'slider'      
        },        
        {
            title: 'Aumento frequentemente minha rede de contatos em vista de parcerias estratégicas',
            type: 'like-or-deslike'            
        },
        {
            title: 'Entendo como características fundamentais para a criação de novas soluções digitais',
            type: 'options',
            multiple: true,
            answers: [
                {
                    label: 'Ser simples',
                    correct: true
                },
                {
                    label: 'Centrada no cliente',
                    correct: true
                },
                {
                    label: 'Questionários densos',
                    correct: false
                },
                {
                    label: 'Prototipação',
                    correct: true
                },
                {
                    label: 'Flexível',
                    correct: true
                },
                {
                    label: 'Grandes equipes',
                    correct: false
                }
            ]                      
        },
        {
            title: 'Acompanho a evolução do mercado estando atento às ideias inovadoras',
            tema: 'Mindset digital',
            type: 'slider'
        }
    ]
}


const conclusao_1 = {
    router: "conclusao-feedback",
    field: "conclusao",
    title: "Conclusão", 
    hideInList: true,
    type: 'feedback',
    bgClass: 'bg-gradient-gray',
    questions: [
        {
            title: `
            <p>
                Parabéns você terminou de responder o diagnóstico de maturidade digital.
            </p>
            <p>
                Antes de ir para a plataforma de conteúdo, convidamos você a deixar um pequeno feedback 
                de pontos de melhoria para sua empresa. São apenas 6 questões práticas.
            </p>
            `,
            type: 'intro',
            button: {
                label: "Enviar Feedback",
                link: "score/feedback/0"
            } 
        }
    ]
}   


const feedback = {
    router: "feedback",
    field: "feedback",
    title: "Feedback para a Empresa", 
    bgClass: 'bg-gradient-purple',
    type: 'feedback',
    desc:`Contribua indicando pontos de melhorias para a empresa e para o seu setor.`,
    questions: [
        {
            title: `
                <p>
                Esta é uma área para que você contribua com a empresa indicando pontos de melhorias.
                Não se preocupe, as respostas aparecerão como usuários anônimos.
                </p>
            `,
            type: 'intro'                      
        },
        {
            title: 'Quais das dificuldades abaixo você encontra na sua empresa',
            type: 'options',
            multiple: true,
            answers: [
                {
                    label: 'Construir estratégias com base em análise de dados',
                    correct: true
                },
                {
                    label: 'Automatização de processos',
                    correct: true
                },
                {
                    label: 'Falta de lideranças inovadoras',
                    correct: true
                },
                {
                    label: 'Conquistar novos clientes',
                    correct: true
                },
                {
                    label: 'Manter os clientes satisfeitos',
                    correct: true
                },
                {
                    label: 'Engajamento dos colaboradores',
                    correct: true
                },
                {
                    label: 'Desenvolver uma estratégia digital',
                    correct: true
                },
                {
                    label: 'Aumentar nossa eficiência e produtividade',
                    correct: true
                },                
            ]                      
        },
        {
            title: 'Considero o setor que eu trabalho altamente eficiente e produtivo.',
            type: 'slider'                
        },
        {
            title: 'O que ainda pode ser melhorado no seu setor para ganhar mais eficiência e produtividade?',
            type: 'textarea'      
        },        
        {
            title: 'E o que na sua visão a empresa pode melhorar para ter um ambiente mais favorável à inovação.',
            type: 'textarea'            
        },
        {
            title: 'Qual o problema que você considera prioritário para a empresa solucionar.',
            type: 'textarea'              
        },
        {
            title: 'Me considero preparado para ajudar a empresa a se desenvolver na transformação digital.',
            type: 'slider'
        }
    ]
}

const conclusao_2 = {
    router: "conclusao",
    field: "conclusao",
    title: "Conclusão", 
    hideInList: true,
    bgClass: 'bg-gradient-gray',
    type: 'feedback',
    questions: [
        {
            title: `
            <p>
                Obrigado pelo seu feedback.
            </p>
            <p>
                Vamos agora para sua experiência com o melhor conteúdo sobre transformação digital do Brasil.
            </p>
            `,
            type: 'intro',
            button: {
                label: "Continuar no TD PREMIUM",
                link: "score/finalizar"
            } 
        }
    ]
}   


const conclusao = {
    router: "conclusao",
    field: "conclusao",
    title: "Conclusão", 
    bgClass: 'bg-gradient-gray',
    type: 'conclusao',
    hideInList: true,
    questions: [
        {
            title: `
            <p>
                Parabéns você terminou de responder o diagnóstico de maturidade digital.
            </p>
            <p>
                Vamos agora para sua experiência com o melhor conteúdo sobre transformação digital do Brasil.
            </p>
            `,
            type: 'intro',
            button: {
                label: "Continuar no TD PREMIUM",
                link: "score/finalizar"
            } 
        }
    ]
}   


export default  [
    intro,
    cultura_e_lideranca,
    agilidade_operacional,
    tecnologia_digital,
    foco_no_cliente,
    visao_de_negocio,
    conclusao,
    conclusao_1,
    feedback,
    conclusao_2
]