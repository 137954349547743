import riot from 'riot'
 import { ReportService, CourseService } from '@common/services'
riot.tag2('td-dashboard-courses-progress', '<div class="dashboard-card"> <div class="header"> <div class="card-title">Cursos ou trilhas</div> <div class="expand"> <i class="si si-size-fullscreen"></i> </div> </div> <td-chart-multi-bars if="{data}" data="{data}"></td-chart-multi-bars> <div class="footer"> <div class="info"> <i class="si si-info"></i> </div> </div> </div>', '', '', function(opts) {

        this.CourseService = CourseService

        this.filters = {}

        const loadData = async (course) => {
            const data = await ReportService.getType('progress-course', { course })

            const items = [
                {
                    label: 'Concluído',
                    value: data.completed,
                    bgColor: '#88f3aa',
                    bgGradient: 'linear-gradient(to bottom, #88f3aa 0%,#50e366 100%)'
                },
                {
                    label: 'Em andamento',
                    value: data.inProgress,
                    bgColor: '#ff9d00',
                    bgGradient: 'linear-gradient(to bottom, #ff9d00 0%,#ff7f00 100%)'
                },
                {
                    label: 'Não iniciado',
                    value: data.notStarted,
                    bgColor: '#31547e',
                    bgGradient: 'linear-gradient(to bottom, #31547e 0%,#466a8f 100%)'
                }
            ]

            this.update({
                data: { items }
            })
        }

        const initFilters = async () => {
            const data = await CourseService.findOne()
            this.filters.course = {
                _id: data._id,
                name: data.name
            }
            loadData(data._id)
        }

        initFilters()
});