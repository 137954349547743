import riot from 'riot'
 import './player.scss'
import 'plyr/dist/plyr.css'
import Plyr from 'plyr'
import { EventService } from '@common/services'
import favicon from '@td-premium/assets/media/favicons/favicon.png'
riot.tag2('td-player', '<div ref="container" class="player-container"> <div class="embed-container {showNext ? \'hide-control\' : \'\'}"> <video show="{mounted}" ref="player" controls crossorigin playsinline> </video> </div> <div ref="headerControl" if="{opts.fullsize}" class="header-control"> <h5> {content.title} </h5> <button onclick="{exitFullSize}" class="btn btn-alt-danger">Fechar</button> </div> <div ref="contentControl" if="{opts.fullsize && content.content}" class="content-control content-scroll"> <div class="content-control__inner"> <raw html="{content.content}"> </div> </div> <div ref="next" if="{next}" class="player-next content" show="{showNext}"> <h5 class="text-center"> Próximo </h5> <h3 class="text-center"> {next.title} </h3> <a href="" onclick="{playNext}" class="next-play"> <svg class="next-progress" width="120" height="120" viewbox="0 0 120 120"> <circle class="progress__value" cx="60" cy="60" r="40" fill="transparent" stroke="white" stroke-width="2"></circle> </svg> <div class="next-play-btn"> <i class="si si-control-end"></i> </div> </a> <button onclick="{cancelNext}" class="btn btn-outline-danger mt-20">Cancelar</button> </div> </div>', '', '', function(opts) {

        this.content = opts.content
        this.video = opts.content ? opts.content.video : null
        this.next = opts.next

        this.finished = false

        const finishVideo =  async (e) => {
            const instance = e.detail.plyr;

            if (opts.onended) opts.onended()
            if (this.nextContent) this.nextContent()
            eventProgressVideo({
                percent: instance.currentTime / instance.duration,
                seconds: instance.currentTime
            })
        }

        let lastTime = -2
        const timeupdate = async (e) => {
            const instance = e.detail.plyr;
            const currentTime = parseInt(instance.currentTime)

            if (this.content && this.content.progress && this.content.progress.seconds > currentTime) {
                return
            }

            if (parseInt(instance.currentTime) >= (lastTime + 3)) {
                lastTime = parseInt(instance.currentTime)
                eventProgressVideo({
                    percent: instance.currentTime / instance.duration,
                    seconds: instance.currentTime
                })
            }
        }

        const seeked = async (e) => {
            lastTime = -3
        }

        const eventProgressVideo = async (e) => {
            await EventService.post({
                content: this.content._id,
                type: 'videoProgress',
                value: {
                    percent: e.percent,
                    seconds: e.seconds
                }
            })
        }

        const mountPlayer = (player) => {
            if (!this.refs.player) return null

            this.player = new Plyr(this.refs.player, {
                debug: false,
                title: 'View From A Blue Moon',
                keyboard: {
                    global: true
                },
                tooltips: {
                    controls: true
                },
                captions: {
                    active: false
                }
            })

            return this.player
        }

        const getProvider = () => {
            if (!this.video || !this.video.url) return 'html5'
            if (this.video.url.indexOf('vimeo') > -1) return 'vimeo'
            if (this.video.url.indexOf('youtube') > -1) return 'youtube'
            return 'html5'
        }

        const updateData = () => {

            if (opts.fullsize) {
                $(this.root).addClass('fullsize')
                $('body').css({overflow: 'hidden'})
                $(this.refs.container).css({
                    width: document.body.offsetHeight * (1920 / 1080)
                })
            } else {
                $(this.root).removeClass('fullsize')
                $('body').css({overflow: ''})
                $(this.refs.container).css({width: ''})
            }

            if (this.finished)
                return

            this.content = opts.content
            this.next = opts.next
            this.video = this.content ? this.content.video : null

            if (!this.video || !this.video.url) return

            const _player = mountPlayer()

            if (!_player) return

            _player.source = {
                type: 'video',
                sources: [
                    {
                        src: this.video.url,
                        provider: getProvider()
                    }
                ],
                poster: this.content.photo ? this.content.photo.thumbnailHorizontal : ''
            }

            _player.on('ready', (event) => {
                if (this.content.progress && this.content.progress.percent <= 0.99) {
                    if (_player.provider === 'vimeo') {
                        _player.embed.setCurrentTime(this.content.progress.seconds)
                    }
                }

                if (opts.autoplay) {
                    _player.play()
                }

                if (opts.fullsize) {
                    $(this.root).addClass('fullsize')
                    $('body').css({overflow: 'hidden'})
                    $(this.refs.container).css({
                        width: document.body.offsetHeight * (1920 / 1080)
                    })

                    if (this.refs.headerControl)
                        $(_player.elements.container).append(this.refs.headerControl)

                    if (this.refs.contentControl)
                        $(_player.elements.container).append(this.refs.contentControl)
                } else {
                    $(this.root).removeClass('fullsize')
                    $('body').css({overflow: ''})
                    $(this.refs.container).css({width: ''})
                }

                if (this.refs.next)
                    $(_player.elements.container).append(this.refs.next)

                _player.off('ended', finishVideo)
                _player.on('ended', finishVideo)
                _player.off('timeupdate', timeupdate)
                _player.on('timeupdate', timeupdate)
                _player.off('seeked', seeked)
                _player.on('seeked', seeked)
            })

            this.player = _player
        }

        this.exitFullSize = () => {
            opts.fullsize = false;

            if(opts.onfullsizeexit) opts.onfullsizeexit()

            $(this.root).removeClass('fullsize')
            $('body').css({overflow: ''})
            $(this.refs.container).css({width: ''})
        }

        this.setData = (data) => {
            Object.keys(data).forEach((key) => {
                opts[key] = data[key]
            })
            this.update()
        }

        this.on('update', () => {
            updateData()
        })

        this.on('mount', () => {
            window.addEventListener('resize', resize)
            updateData()
        })

        const resize = () => {
            if (opts.fullsize) {
                $(this.refs.container).css({
                    width: document.body.offsetHeight * (16/9)
                })
            }
        }

        this.on('unmount', () => {
            this.cancelNext()
            $(this.root).removeClass('fullsize')
            $('body').css({overflow: ''})
            $(this.refs.container).css({width: ''})
            window.removeEventListener('resize', resize)
        })

        this.showNext = false
        let nextTimer = null

        this.nextContent = () => {
            if (nextTimer) clearTimeout(nextTimer)
            nextTimer = setTimeout(() => {
                this.playNext()
            }, 5000)
            this.update({ showNext: true, finished: true })
        }

        this.cancelNext = () => {
            if (nextTimer) clearTimeout(nextTimer)
            this.update({ showNext: false })
        }

        this.playNext = (e) => {
            if (e) e.preventDefault()
            this.cancelNext()

            if (opts.onnext) {
                return opts.onnext(this.next)
            }

            if (opts.nextLink) {
                return globalRouter.page(opts.nextLink(this.next))
            }

            globalRouter.page('/app/conteudo/' + this.next.slug)
        }
});