import riot from 'riot'
 import './answer-like-or-deslike.scss'
riot.tag2('td-answer-like-or-deslike', '<div class="btn-answer deslike {data.like === false ? \'active\': \'\'}" onclick="{deslike}"> <i class="icon animated"></i> <span>Discordo</span> </div> <div class="btn-answer like {data.like === true ? \'active\': \'\'}" onclick="{like}"> <i class="icon animated"></i> <span>Concordo</span> </div>', '', '', function(opts) {

        this.data = this.getMetaData('score.' + opts.dimension + '.' + opts.dimension+'_'+opts.index, {})

        this.deslike = () => {
            this.data.like = false
            this.data.value = 0
        }

        this.like = () => {
            this.data.like = true
            this.data.value = 100
        }
});