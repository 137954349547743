import riot from 'riot'
 import './cover-carousel.scss'
riot.tag2('cover-carousel', '<div ref="carousel" if="{!refresh}" class="owl-carousel owl-theme"> <div class="hero-bg" each="{item in items}"> <div class="hero-bg-img"> <img riot-src="{item.photo.cover}"> <div class="hero-bg-overlay"></div> <div class="hero-bg-overlay-top"></div> <div class="hero-bg-overlay-bottom"></div> </div> <div class="hero-bg-content"> <div class="hero-bg-left content pt-0 pr-0"> <div if="{item.category}" class="category"> {item.category.name} </div> <div class="title"> {item.title} </div> <div class="summary"> {item.summary} </div> <a href="{getItemLink(item)}" class="play-video"> Assistir <i class="fa fa-play"></i> </a> </div> <div class="hero-bg-right content pt-0 pl-0"> <a href="{getItemLink(item)}" class="play-video"> <i class="fa fa-play"></i> </a> </div> </div> </div> </div>', '', '', function(opts) {

        this.refresh = false
        this.items = []

        this.sidebar.on('sidebar_mini_toggle', () => {
            $(this.refs.carousel).trigger('refresh.owl.carousel')
        })

        this.on('update', (data) => {
            if (!data) {
                load()
            }
        })

        this.getItemLink = (item) => {
            if (item.trackers.length) {
                return `trilha/${item.trackers[0].slug}/${item.slug}`
            }
            return `conteudo/${item.slug}`
        }

        const load = () => {
            this.update({ refresh: true })
            this.update({ items: opts.items, refresh: false })

            $(this.refs.carousel).owlCarousel({
                animateOut: 'fadeOut',
                animateIn: 'fadeIn',
                loop: true,
                nav: false,
                items: 1,
                autoplay: true,
                autoplayTimeout: 10000,
                autoplayHoverPause: false
            })

            $('.hero-bg-content')
            .on('mouseenter', () => {
                $(this.refs.carousel).trigger('stop.owl.autoplay')
            })
            .on('mouseleave', () => {
                $(this.refs.carousel).trigger('play.owl.autoplay')
            })
        }

        this.on('mount', () => {
            load()
        })
});