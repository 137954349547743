import riot from 'riot'
 import './radar.scss'
riot.tag2('td-chart-radar', '<canvas ref="radar" width="300" height="300"></canvas> <div each="{label,index in opts.data.labels}" class="chart-label chart-label-{index}">{label.abbreviation}</div>', '', '', function(opts) {

		this.chart = null

		this.on('update',() => {
			this.chart.data.labels = opts.data.labels.map((o) => o.name)
			this.chart.data.datasets = opts.data.datasets
			this.chart.update()
		})

		this.on('mount',() => {

			const data = {
				labels: opts.data.labels.map((o) => o.name),
				datasets: opts.data.datasets
			}

			const options = {
				responsive: true,
				maintainAspectRatio: false,
				spanGaps: false,
				legend: {
					display: false
				},
				elements: {
					line: {
						tension: 0.000001,
						borderWidth: 1,
						borderCapStyle: 'round',
						borderJoinStyle: 'round'
					},
				},
				scale: {
					angleLines: {
						color: '#666'
					},
					gridLines: {
						lineWidth: 0,
						zeroLineWidth: 0,
						color: '#666',
						display: false
					},
					angleLines: {
			 			lineWidth: 0
			 		},
					pointLabels: {
						fontColor: '#fff',
						display: false
					},
					ticks: {
						min: 0,
						max: 100,
						stepSize: 1,
						display: false
					}
				},
				tooltips: {
					enabled: true,
					displayColors: false,
					callbacks: {
						label: function(tooltipItem, data) {
							return data.datasets[tooltipItem.datasetIndex].label + ': ' + data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] + '%';
						}
					}
				}
			}

			this.chart = new Chart(this.refs.radar, {
				type: 'radar',
				data: data,
				options: options
			})

		})

});