import riot from 'riot'
 import './drop-select.scss'
riot.tag2('td-drop-select', '<ul class="drop-select" ref="dropselect"> <li class="active"> <a href="#" class="label">{opts.default}</a> </li> <li each="{item, key in opts.data}"> <yield></yield> </li> </ul>', '', '', function(opts) {

        this.on('mount', () => {
            this.refs.dropselect.addEventListener('click',function(){
                var oldOpen = this.querySelector(".drop-select-open");
                var selected = this.querySelector(".active");

                this.classList.toggle('active');
                if(this.classList.contains('active')){
                    var menuItems = this.innerHTML;
                    var menuOpen = "<div class='drop-select-open'>" + menuItems + "</div>";
                    this.innerHTML = menuOpen;
                    this.insertBefore(selected, this.firstChild);
                } else {
                    var menuOpen = oldOpen.innerHTML;
                    this.innerHTML = menuOpen;
                };
            });
        })
});