import riot from 'riot'
 
import './score.scss'
import dimensions from './dimensions'
riot.tag2('td-score-old', '<div class="pt-perspective"> <virtual each="{dimension, dimensionKey in dimensions}"> <td-score-question each="{question, key in dimension.questions}" dimension="{dimension}" index="{key}" question="{question}" if="{showItem(dimension, dimensionKey, question, key)}" class="pt-page pt-page-{dimension.router}-{key}" prev="{getPrev(dimension, dimensionKey, question, key)}" next="{getNext(dimension, dimensionKey, question, key)}"></td-score-question> </virtual> <div class="progress" if="{currentQuestionObj}"> <div riot-style="width:{currentQuestionObj.percent}%;" class="progress-bar"></div> </div> </div>', '', '', function(opts) {

        this.dimensions = dimensions.filter((o) => {
            if (o.type=='feedback') return !this.isAgency() && this.isMultiUsers()
            if (o.type=='conclusao') return this.isAgency() || !this.isMultiUsers()
            return true
        })

        let progressTotal = 0

        this.dimensions.forEach((item, key) => {
            progressTotal += item.questions.length
        })

        let progressCount = 0

        this.dimensions.forEach((item, key) => {

            item.questions.forEach((q, qKey) => {
                progressCount++
                q.percent = progressCount / progressTotal * 100
            })
        })

        this.showItem = (dimension, dimensionKey, question, key) => {
            if (this.currentDimension == dimension.router && this.currentQuestion == key) {
                return true
            }

            if (
                this.currentQuestion == 0 &&
                this.dimensions[dimensionKey + 1] &&
                this.currentDimension == this.dimensions[dimensionKey + 1].router &&
                key == (dimension.questions.length -1)
            ) {
                return true
            }

            if (this.currentDimension == dimension.router && (
                this.currentQuestion == (key + 1) || this.currentQuestion == (key - 1)
            )) {
                return true
            }

            if (
                this.currentQuestion == (this.currentDimensionObj.questions.length -1) &&
                this.dimensions[dimensionKey - 1] && this.currentDimension == this.dimensions[dimensionKey - 1].router &&
                key == 0
            ) {
                return true
            }

        }

        this.getPrev = (dimension, dimensionKey, question, key) => {
            if (key === 0 && this.dimensions[dimensionKey - 1]) {
                const prevDimension = this.dimensions[dimensionKey - 1]
                return 'score/' + prevDimension.router + '/' + (prevDimension.questions.length -1)
            }

            if (key > 0) {
                return 'score/' + dimension.router + '/' + (key - 1)
            }
        }

        this.getNext = (dimension, dimensionKey, question, key) => {
            if ((key + 1) >= dimension.questions.length && this.dimensions[dimensionKey + 1]) {
                return 'score/' + this.dimensions[dimensionKey + 1].router + '/0'
            }

            if ((key + 1) < dimension.questions.length) {
                return 'score/' + dimension.router + '/' + (key + 1)
            }
        }

        const updateParams = () => {
            this.currentDimensionObj = this.dimensions.filter((o) => opts.params.dimension === o.router)[0]
            this.currentDimension = opts.params.dimension
            this.currentQuestion = Number(opts.params.question)
            this.currentQuestionObj = this.currentDimensionObj.questions.filter((o, index) =>
                this.currentQuestion === index
            )[0]
        }

        updateParams()

        this.on('mount', () => {
            const current = $(this.root).find('.pt-page-' + this.currentDimension + '-' + this.currentQuestion)

            let count = 0
            current.addClass('pt-page-current').addClass('pt-page-in')
            .on(AnimationStartEvent, () => {
                count++
            })
            .on(AnimationEndEvent, () => {
                count--
                if (count == 0) {
                    $currPage.off( AnimationStartEvent )
                    $currPage.off( AnimationEndEvent )
                    current.removeClass('pt-page-in')
                }
            })

        })

        this.on('update', () => {

            if (this.currentQuestion > Number(opts.params.question)) {
                this.prev({
                    current: this.currentDimension + '-' + this.currentQuestion,
                    target:  opts.params.dimension + '-' + opts.params.question,
                    changeDimension: this.currentDimension != opts.params.dimension
                })
                updateParams()
            } else if (this.currentQuestion <= Number(opts.params.question)) {
                this.next({
                    current: this.currentDimension + '-' + this.currentQuestion,
                    target:  opts.params.dimension + '-' + opts.params.question,
                    changeDimension: this.currentDimension != opts.params.dimension
                })
                updateParams()
            }
        })

        const AnimationEndEvent = 'animationend webkitAnimationEnd MSAnimationEnd oAnimationEnd'
        const AnimationStartEvent = 'animationstart webkitAnimationStart MSAnimationStart oAnimationStart'

        let endCurrPage = false
        let endTargetPage = false
        let isAnimating = false

        const onEndAnimation = ( $outpage, outClass, $inpage, inClass ) => {
            endCurrPage = false
            endTargetPage = false
            resetPage( $outpage, outClass, $inpage, inClass )
            isAnimating = false
        }

        const resetPage = ( $outpage, outClass, $inpage, inClass ) => {
            $outpage.removeClass('pt-page-current')
            $inpage.addClass('pt-page-current')

            if ($inpage.hasClass('pt-page-ontop')) {
                $outpage.removeClass(outClass)
                setTimeout(() => {
                    $inpage.removeClass(inClass)
                }, 500)
            } else {
                $outpage.removeClass('pt-page-ontop')
                $inpage.removeClass(inClass)
                setTimeout(() => {
                    $outpage.removeClass(outClass)
                }, 500)
            }
        }

        const animate = ($currPage, outClass, $targetPage, inClass) => {

            $currPage.removeClass(outClass).removeClass(inClass)
            $targetPage.removeClass(outClass).removeClass(inClass)

            $currPage.off( AnimationStartEvent )
            $currPage.off( AnimationEndEvent )

            $targetPage.off( AnimationStartEvent )
            $targetPage.off( AnimationEndEvent )

            let currentCount = 0

            $currPage.addClass( outClass )
            .on(AnimationStartEvent, () => {
                currentCount++
                endCurrPage = false
            })
            .on(AnimationEndEvent, () => {
                currentCount--
                if (currentCount==0) {
                    endCurrPage = true
                    if( endTargetPage ) {
                        $currPage.off( AnimationEndEvent )
                        $currPage.off( AnimationStartEvent )
                        onEndAnimation( $currPage, outClass, $targetPage, inClass )
                    }
                }
            })

            let targetCount = 0

            $targetPage.addClass( inClass )
            .on(AnimationStartEvent, () => {
                targetCount++
                endTargetPage = false
            })
            .on(AnimationEndEvent, () => {
                targetCount--
                if (targetCount == 0) {
                    endTargetPage = true
                    if( endCurrPage ) {
                        $targetPage.off( AnimationStartEvent )
                        $targetPage.off( AnimationEndEvent )
                        onEndAnimation( $currPage, outClass, $targetPage, inClass )
                    }
                }
            })

                setTimeout(() => {
                    onEndAnimation( $currPage, outClass, $targetPage, inClass )
                }, 2000)

        }

        this.prev = (e) => {
            const $currPage = $(this.root).find('.pt-page-' + e.current)
            const $targetPage = $(this.root).find('.pt-page-' + e.target).addClass('pt-page-current')

            animate(
                $currPage, 'pt-page-out',
                $targetPage, 'pt-page-in pt-page-ontop'
            )
        }

        this.next = (e) => {
            const $currPage = $(this.root).find('.pt-page-' + e.current)
            const $targetPage = $(this.root).find('.pt-page-' + e.target).addClass('pt-page-current')

            animate(
                $currPage, 'pt-page-out',
                $targetPage, 'pt-page-in pt-page-ontop'
            )
        }

});