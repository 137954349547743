import riot from 'riot'
 import './circle.scss'
riot.tag2('td-chart-circle', '<div class="content-chart"> <div class="text-content"> <div class="big-text"> {count || 0}<span class="dark-color"> / {total || 0}</span> </div> </div> <div class="single-chart"> <svg viewbox="0 0 36 36" class="circular-chart orange"> <defs> <lineargradient id="linear-circle" x1="0%" y1="0%" x2="0%" y2="50%"> <stop offset="0%" stop-color="#8300ff" stop-opacity="1.0"></stop> <stop offset="100%" stop-color="#e500ff" stop-opacity="1.0"></stop> </linearGradient> <lineargradient id="linear-circle-red" x1="0%" y1="0%" x2="0%" y2="50%"> <stop offset="0%" stop-color="#ff0092" stop-opacity="1.0"></stop> <stop offset="100%" stop-color="#ff0000" stop-opacity="1.0"></stop> </linearGradient> </defs> <path class="circle-bg" stroke-dasharray="100, 100" d="M18 2.0845 a 12.9155 12.9155 0 0 1 0 28.831 a 12.9155 12.9155 0 0 1 0 -28.831"></path> <path onmousemove="{showTooltip}" onmouseleave="{hideTooltip}" class="circle" stroke-dashoffset="5" stroke-dasharray="{percent} {100 - percent}" stroke="" d="M18 2.0845 a 12.9155 12.9155 0 0 1 0 28.831 a 12.9155 12.9155 0 0 1 0 -28.831"></path> </svg> </div> </div> <td-chart-tooltip percent="{percent}" ref="tooltip"> {opts.percent}% </td-chart-tooltip>', '', '', function(opts) {

		const updateData = () => {
			const data =  opts.data || {}
			this.count = data.count || 0
			this.total = data.total || 0

			if (this.total) {
				this.percent = Math.floor(this.count / this.total * 100)
			} else {
				this.percent = 0
			}
        }

        this.showTooltip = (e) => {
            this.refs.tooltip.setPosition(e)
        }

        this.hideTooltip = (e) => {
            this.refs.tooltip.hide()
        }

		this.on('update', () => {
			updateData()
		})

		updateData()
});