import riot from 'riot'
 import { UserService } from '@common/services'
import imageLogo from '@td-premium/assets/media/images/logo-branco.svg'
riot.tag2('td-score-finish', '<div class="score score-bg bg-gradient-blue"> <div class="header"> <div class="logo"> <img riot-src="{app.imageLogo}" height="{app.imageSize}"> </div> </div> <div class="score-body"> <div class="d-flex justify-content-center align-items-center flex-column"> <div class="animated animated-slow fadeIn" style="font-size: 20px;" if="{fadeIn}"> {textLoading} </div> <div> <div class="spinner-grow text-white mt-20" role="status"> <span class="sr-only">Loading...</span> </div> <div> </div> </div> </div>', '', '', function(opts) {

        this.finish = false
        this.textLoading = ''

        const loadingText = (text) => {
            this.fadeIn = false
            this.update()
            this.fadeIn = true
            this.textLoading = text
            this.update()
        }

        loadingText('Carregando informações.')

        let timers = []
        timers[0] = setTimeout(() => {
            loadingText('Identificando parâmetros.')
            timers[1] = setTimeout(() => {
                loadingText('Criando ambiente personalizado.')
                timers[2] = setTimeout(() => {
                    this.finish = true
                }, 2000)
            }, 2000)
        }, 2000)

        this.on('unmount', () => {
            clearTimeout(timers[0])
            clearTimeout(timers[1])
            clearTimeout(timers[2])
        })

        const init = async () => {
            await UserService.current.setup()
            this.setMetaData('onboardingFinished', true)
            const time = setInterval(() => {
                if (this.finish) {
                    clearInterval(time)
                    globalRouter.page('/app/dashboard/user')
                }
            }, 1000)
        }

        init()
});