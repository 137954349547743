import riot from 'riot'
 import './category.scss'
import { ContentService, CategoryService } from '@common/services'
import store from '@td-premium/store'
riot.tag2('td-category-item', '<div class="content content-top"> <div class="category-menu"> <h3 class="title"><a href="categoria">Categoria</a> ▸ <span title="{category.name}">{category.name}</span></h3> <td-drop-select data="{categories}" default="Selecione uma categoria"> <a href="categoria/{item.slug}" class="category-list-title"> {item.name} </a> </td-drop-select> </div> <div class="row" if="{contents}"> <td-video-card each="{item in contents}" class="col-12 col-md-3"></td-video-card> </div> </div>', '', '', function(opts) {

        this.category = {}
        this.contents = null

        const loadCategory = async () => {
            let category = await CategoryService.findOne({slug: opts.slug})
            this.update({ category })
            const contents = await ContentService.find({
                category:category._id
            })
            this.update({ contents })
        }

        loadCategory()

        this.categories = store.get('categories', [])

        const loadCategories = async () => {
            let categories = await CategoryService.find()

            store.set('categories', categories)

            this.update({ categories })

            for (let i = 0; i < categories.length; i++) {
                const category = categories[i]
                category.videos = await ContentService.find({
                    category: category._id,
                    limit: 1000
                })
            }

            categories = categories.filter((o) => o.videos.length)

            store.set('categories', categories)

            this.update({ categories })
        }

        loadCategories()

        this.enterCarousel = (key) => {
            return (e) => {
                e.preventUpdate = true
                this.refs['videocarousel'+key].showNavigation()
            }
        }

        this.leaveCarousel = (key) => {
            return (e) => {
                e.preventUpdate = true
                this.refs['videocarousel'+key].hideNavigation()
            }
        }
});