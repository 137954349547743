import riot from 'riot'
 import './video-carousel.scss'
import videoPlaceholder from '@td-premium/assets/media/images/video-placeholder.png'
riot.tag2('video-carousel', '<div class="carousel"> <div ref="carousel" if="{refresh}" class="owl-carousel owl-theme"> <div class="carousel-item" each="{item in Array(1).fill(0)}"><h4></h4></div> </div> <div ref="carousel" if="{!refresh}" class="owl-carousel owl-theme"> <td-video-card link="{link}" each="{item in items}"> </div> <a href="" onclick="{nextPage}" show="{navigation}" class="carousel-nav carousel-next"> <i class="fa fa-chevron-right"></i> </a> <a href="" onclick="{prevPage}" show="{navigation}" class="carousel-nav carousel-prev"> <i class="fa fa-chevron-left"></i> </a> </div>', '', '', function(opts) {

        this.videoPlaceholder = videoPlaceholder

        const itemsPerPage = 4

        this.refresh = true
        this.items = opts.items || []
        this.navigation = false

        this.link = (item) => {
            if (opts.link) {
                return opts.link(item)
            }
            return 'conteudo/' + item.slug
        }

        this.showNavigation = () => {
            if (this.items.length >= itemsPerPage)
                this.update({ navigation: true })
        }

        this.hideNavigation = () => {
            this.update({ navigation: false })
        }

        this.nextPage = (e) => {
            e.preventUpdate = true
            e.preventDefault()

            for (let index = 0; index < itemsPerPage; index++) {
                $(this.refs.carousel).trigger('next.owl.carousel')
            }
        }

        this.prevPage = (e) => {
            e.preventUpdate = true
            e.preventDefault()

            for (let index = 0; index < itemsPerPage; index++) {
                $(this.refs.carousel).trigger('prev.owl.carousel')
            }
        }

        this.sidebar.on('sidebar_mini_toggle', () => {
            $(this.refs.carousel).trigger('refresh.owl.carousel')
        })

        const load = () => {
            this.update({ refresh: true })
            this.update({ items: opts.items || [], refresh: false })

            $(this.refs.carousel).owlCarousel({
                margin: 20,
                nav: false,
                drag: false,
                loop: this.items.length >= itemsPerPage,
                items: itemsPerPage,
                lazyLoad: true,
                responsive: {
                    0: {
                        items: 1
                    },
                    500: {
                        items: 2
                    },
                    1000: {
                        items: 3
                    },
                    1250: {
                        items: itemsPerPage
                    }
                }
            })
        }

        this.on('mount', () => {
            load()
        })

});