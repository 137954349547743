import riot from 'riot'
 import './bar.scss'
riot.tag2('td-chart-bar', '<div class="bar-content"> <div class="label">{label}</div> <div class="bar"> <div class="percent" riot-style="{{width: percent +\'%\'}}"></div> <div if="{!value}" class="number">{percent}%</div> <div if="{value}" class="number">{value}</div> </div> </div>', '', '', function(opts) {

		const updateData = () => {
			const data =  opts.data || {}
			this.label = data.label || '---'
			this.percent = data.percent || 0
            this.value = data.value || 0

            if (this.percent) {
                this.percent = parseFloat(parseFloat(this.percent).toFixed(2))
            }

            if (this.value) {
                this.percevaluent = parseFloat(parseFloat(this.value).toFixed(2))
            }
		}

		this.on('update', () => {
			updateData()
		})

		updateData()
});