import riot from 'riot'
 import './user.scss'
import { UserService, DepartmentService } from '@common/services'
riot.tag2('td-config-user-form', '<h1 class="main-title"> <div class="d-flex align-items-center" if="{opts.edit}"> <breadcrumb if="{loadingData}" last="loading..."></breadcrumb> <breadcrumb if="{!loadingData}" last="{data.name}"></breadcrumb> </div> <div class="d-flex align-items-center" if="{!opts.edit}"> <breadcrumb></breadcrumb> </div> </h1> <form onsubmit="{onSubmit}"> <div class="row d-flex justify-content-center"> <div class="col-5"> <td-input required="true" label="Nome" placeholder="Nome" model="data.name" error="error.name"></td-input> <td-input label="Cargo" if="{isMultiUsers()}" placeholder="Cargo" model="data.office" error="error.office"></td-input> <td-select label="Permissão" placeholder="Permissão" model="data.type" filter="{service.TYPE.MANAGER}" filter-admin="{service.TYPE.ADMIN}" options="{{minimumResultsForSearch: -1}}" error="error.type"> <option if="{(opts.filter >= key || opts.filterAdmin == key)}" each="{item,key in parent.userTypes}" riot-value="{key}"> {item} </option> </td-select> </div> <div class="col-5"> <td-input required="true" label="Email" placeholder="Email" model="data.email" error="error.email"></td-input> <td-select if="{isMultiUsers()}" label="Departamento" placeholder="Departamento" model="data.department" error="error.department" data-value="_id" data-text="name" placeholder="" multiple="{true}" allowclear="{true}" service="{DepartmentService}"></td-select> </div> </div> <div class="row d-flex justify-content-center mt-20"> <div class="col-7"> <div if="{!loading}" class="form-group d-flex mt-40"> <button type="submit" class="btn btn-hero"> Salvar </button> <a href="configuracao/usuario" class="btn btn-hero btn-white"> Cancelar </a> </div> <div if="{loading}" class="form-group mt-40"> <span> <i class="fa fa-spinner fa-spin"></i> Salvando... </span> </div> </div> </div> </form>', '', '', function(opts) {

        this.DepartmentService = DepartmentService
        this.service = UserService
        this.userTypes = UserService.TYPE_CHOICES

        this.messages = {
            create: () => {
                return  'Usuário cadastrado com sucesso!'
            },
            update: () => {
                return 'Usuário atualizado com sucesso!'
            }
        }

        this.mixin('baseForm')
});