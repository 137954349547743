import 'babel-polyfill'

import './styles/**/*.scss'

import '@common/common'

import '@td-premium/components/**/*.tag'
import '@td-premium/app/*.tag'
import '@td-premium/pages'
import '@td-premium/mixins'

import moment from 'moment'
import 'moment/locale/pt-br.js'

moment.locale('pt-br')

import riot from 'riot'

import '@td-premium/mixins'


riot.mount('*')


if (module.hot) {
    module.hot.dispose(() => {
        setTimeout(() => {
            console.log('accept')
            window.location.reload();
        }, 500)
    })
}