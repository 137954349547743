import riot from 'riot'
 import { dynamicItems } from '../../utils.js'
riot.tag2('widget-banner-vertical', '<h4 if="{settings.title}" class="widget-title content"> {settings.title} </h4> <div class="widget-banner-vertical content" if="{!loading}"> <a each="{item in items}" href="{item.link}" class="widget-banner__item-card"> <div class="widget-banner__item-card--image"> <img riot-src="{item.image}"> </div> <div class="widget-banner__item-card--content"> <div if="{item.logo}" class="widget-banner__item-card--content__logo"> <img riot-src="{item.logo}"> </div> <div if="{!item.logo && item.title}" class="widget-banner__item-card--content__title"> {item.title} </div> <div if="{item.text}" class="widget-banner__item-card--content__text"> {item.text} </div> </div> </a> </div>', '', '', function(opts) {

        this.loading = true
        this.settings = opts.widget.settings

        const slider = () => {
            $('.widget-banner-vertical', this.root).slick({
                infinite: true,
                slidesToShow: 4,
                slidesToScroll: 4,
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            arrows: false,
                            centerMode: true,
                            centerPadding: '40px',
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            });
        }

        const init = async () => {
            const items = await dynamicItems(opts.widget.settings.items, {
                GroupService: {
                    title: {
                        key: 'name',
                        required: true
                    },
                    text: {
                        key: 'description'
                    },
                    image: {
                        key: 'image.vertical',
                        required: true
                    },
                    logo: {
                        key: 'image.logo'
                    },
                    link: (item) => this.getGroupUrl(item)
                }
            })

            this.update({loading: false, items})
            slider()
        }

        init()

});