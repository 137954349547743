import riot from 'riot'
 import avatarDefault from '@common/assets/media/avatars/avatar0.jpg'
import pathToRegexp from 'path-to-regexp'
riot.tag2('td-sidebar', '<nav id="sidebar"> <div id="sidebar-scroll"> <div class="sidebar-content"> <div class="content-header px-5"> <div class="content-header-section text-center align-parent w-100"> <button type="button" class="btn btn-circle btn-dual-secondary d-lg-none align-v-r" data-toggle="layout" data-action="sidebar_close"> <i class="fa fa-times text-danger"></i> </button> <div class="content-header-item"> <a class="link-effect font-w700 pl-20" href=""> <img riot-src="{app.imageLogo}" height="{app.imageSize}"> </a> </div> </div> </div> <div class="content-side content-side-full"> <ul class="nav-main"> <li each="{item in app.menu}" if="{!item.hide || !item.hide()}" class="{item.type === \'header\' ? \'nav-main-heading\': \'\'}"> <span if="{item.type === \'header\'}" class="sidebar-mini-visible"></span> <span if="{item.type === \'header\'}" class="sidebar-mini-hidden"> {item.label} </span> <a if="{item.type === \'menu\'}" ref="menu" onclick="{closeSidebar}" class="{item.submenu ? \'nav-submenu\':\'\'}" data-toggle="{item.submenu ? \'nav-submenu\':\'\'}" href="{item.href}"> <i class="{item.icon}"></i> <span class="sidebar-mini-hide">{item.label}</span> </a> <ul style="width: 300px" if="{item.submenu}"> <li each="{sub in item.submenu}"> <i class="{item.icon}"></i> <a href="{sub.href}">{sub.label}</a> </li> </ul> </li> </ul> </div> </div> </div> </nav>', '', '', function(opts) {


        this.avatarDefault = avatarDefault

        this.toggleSidebar = () => {
            Codebase.layout('sidebar_mini_toggle')
            this.trigger('sidebar_mini_toggle')
        }

        this.closeSidebar = () => {
            if (Modernizr.mq('only all and (max-width: 640px)')) {
                Codebase.layout('sidebar_close')
            }
        }

        this.setActive = (path) => {
            if (!this.refs.menu || !Array.isArray(this.refs.menu)) return
            this.refs.menu.forEach((a, index) => {
                $(a).removeClass('active')
                const pathname = a.pathname.replace('/app', '')
                if (pathname === '/' && path !== '/') return

                const regex = pathToRegexp(pathname)
                if(pathname && regex.exec(path)){
                    $(a).addClass('active')
                }
            })
        }

        riot.mixin('sidebar', {sidebar: this}, true)
});