import riot from 'riot'
 riot.tag2('td-questions-agilidade-operacional', '<div class="onboarding"> <div class="header"> <div class="col"> <div class="py-30 px-5 logo"> <img riot-src="{app.imageLogo}" height="{app.imageSize}"> </div> </div> </div> <div class="content temas"> <div class="col-6 tema-big"> <h1 class="title">AGILIDADE OPERACIONAL</h1> <p>No contexto da transformação digital, a otimização dos processos é um pilar fundamental para dar mais agilidade para as empresas, trazer respostas às demandas dos clientes e proporcionar serviços mais eficientes. Pensar os processos de forma diferente tende a gerar resultados ainda não explorados nas empresas.</p> <p>Nesta etapa vamos refletir sobre a aplicação de ferramentas, metodologias ágeis, além de confrontar seu mindset em alguns cenários.</p> <a href="onboarding/perguntas/cultura-e-lideranca" class="back-button btn text-white">Voltar</a> </div> <div class="col-6 question-list"> <td-question each="{item, index in questions}" index="{index+1}"></td-question> <button onclick="{save}" class="btn btn-hero">Avançar</button> </div> </div> </div>', '', '', function(opts) {


        const values = this.getMetaData('score.agilidade_operacional', {})

        this.save = async () => {
            this.saveAnswers()
            globalRouter.page('/app/onboarding/perguntas/tecnologia-digital')
        }

        this.questions = [
            { field: 'agilidade_operacional_1', weight: 3, tag: 'Ferramentas', title: 'Considero frequentemente o uso de ferramentas digitais nos processos operacionais, visando redução de custos, agilidade ou melhor gestão de recursos.' },
            { field: 'agilidade_operacional_2', weight: 2, tag: 'Metodologias ágeis', title: 'Utilizo recursos como mapas mentais para visualizar com mais clareza os pontos de atenção de um projeto.' },
            { field: 'agilidade_operacional_3', weight: 3, tag: 'Ferramentas', title: 'Sempre que possível opto por ferramentas com recursos de automação que possam substituir processos manuais e/ou repetitivos.' },
            { field: 'agilidade_operacional_4', weight: 1, reverse: true, tag: 'Mindset', title: 'Me sinto mais confortável trabalhando em projetos com rotinas fixas e pré-estabelecidas no longo prazo do que com projetos modulares de curta e média duração.' },
            { field: 'agilidade_operacional_5', weight: 2, tag: 'Metodologias ágeis', title: 'Estudo com frequencia métodos e alternativas práticas para implementar mudanças de maneira mais ágil.' },
            { field: 'agilidade_operacional_6', weight: 1, tag: 'Metodologias ágeis', title: 'Em vista de resolver determinados problemas considero viável reunir pessoas de departamentos distintos para formar uma nova equipe.' },
            { field: 'agilidade_operacional_7', weight: 1, reverse: true, tag: 'Mindset', title: 'Percebo que a reavaliação de processos em períodos pré-determinados ocasionam desperdício de tempo e prejudicam a eficiência.' },
        ]

        this.mixin('questions')
        this.prepare('agilidade_operacional')

});