import riot from 'riot'
 
import { ContentService } from '@common/services'
riot.tag2('td-history', '<div class="content content-top"> <h3 class="profile-main-title"> Histórico </h3> <div class="row" if="{result}"> <div class="col-md-3 col-12" each="{item in result}"> <content-card path="{\'historico/conteudo\'}" item="{item}"></content-card> </div> </div> </div>', '', '', function(opts) {

        const loadHistory = async () => {
            const result = await ContentService.history()
            this.update({ result })
        }

        loadHistory()

});