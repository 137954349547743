import riot from 'riot'
 import './dynamic-page.scss'
riot.tag2('dynamic-page', '<div each="{widget in page.widgets}" data-is="{\'widget-\'+widget.type}" widget="{widget}" class="td-widget"></div>', '', '', function(opts) {

        this.page = this.app.vertical.homepage || { }

        if (!this.page.widgets) {
            this.page.widgets = []
        }

});