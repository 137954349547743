import riot from 'riot'
 
import './user.scss'
import { UserService, DepartmentService } from '@common/services'
riot.tag2('td-config-user', '<h1 class="main-title"> <breadcrumb></breadcrumb> </h1> <div class="row"> <div class="col-4"> <td-input onchange="{loadDataDebounce}" onenter="{loadData}" model="query.search" type="text" required="{true}" placeholder="Busca"></td-input> </div> <div class="col-4"> <td-select model="query.department" placeholder="Todos os Departamentos" allowclear="{true}" onchange="{loadData}" data-value="_id" data-text="name" service="{DepartmentService}"></td-select> </div> <div class="col-4 text-right"> <a href="configuracao/usuario/novo" class="btn btn-hero">Novo Usuário</a> </div> </div> <table class="table table-striped table-vcenter"> <thead class="bg-gradient"> <tr> <th>Nome</th> <th>Departamento</th> <th class="text-center">Status</th> <th class="text-center">Score</th> <th class="text-center" style="width: 100px;">Ações</th> </tr> </thead> <tbody> <tr each="{item in data}"> <td> {item.name} </td> <td> {getDepartment(item)} </td> <td class="text-center"> <span if="{item.confirmed}" class="badge badge-primary">Ativo</span> <span if="{!item.confirmed}" class="badge badge-dark">Pendente</span> </td> <td class="text-center"> {item.metadata && item.metadata.score && item.metadata.score.percent ?                         item.metadata.score.percent  : \'---\'} </td> <td class="text-center"> <div class="btn-group"> <a href="configuracao/usuario/{item._id}" class="btn btn-sm btn-secondary" data-toggle="tooltip" title="Edit"> <i class="fa fa-pencil"></i> </a> <button onclick="{delete(item)}" type="button" class="btn btn-sm btn-secondary" data-toggle="tooltip" title="Delete"> <i class="fa fa-times"></i> </button> </div> </td> </tr> </tbody> </table>', '', '', function(opts) {

        this.service = UserService
        this.DepartmentService = DepartmentService

        this.query = {
            sort: '-createdAt',
            search: null
        }

        this.getDepartment = (item) => {
            return item.department && item.department.length ? item.department.map(o => o.name).join(', ') : '---'
        }

        this.messages = {
            delete: (item) => {
                return `
                    Você tem certeza que deseja <br />
                    deletar o usuário <strong>${item.name}</strong>?
                `
            }
        }

        this.mixin('baseList')
});