import riot from 'riot'
 import { dynamicItems } from '../../utils.js'
riot.tag2('widget-banner-horizontal', '<h4 if="{settings.title}" class="widget-title content"> {settings.title} </h4> <div class="widget-banner-horizontal content slick-dotted-inner slick-dotted-white"> <div each="{item in items}" class="block block-rounded block-transparent bg-image mb-0" riot-style="background-image: url(\'{item.image}\');"> <a href="{item.link}" class="d-block block-content widget-banner-horizontal__overlay"> <div class="widget-banner-horizontal__size--{settings.size} text-{item.align}"> <h1 if="{item.title}" class="font-w700 text-white mb-10">{item.title}</h1> <h2 if="{item.text}" class="h4 font-w400 text-white-op">{item.text}</h2> <button if="{item.button}" class="widget-btn btn">{item.button}</button> </div> </a> </div> </div>', '', '', function(opts) {

        this.loading = true
        this.settings = opts.widget.settings
        this.items = opts.widget.settings.items

        const slider = () => {
            $('.widget-banner-horizontal', this.root).slick({
                infinite: true,
                dots: this.items.length > 1,
                slidesToShow: 1,
                slidesToScroll: 1
            });
        }

        this.on('mount', () => {
            slider()
        })

});