import riot from 'riot'
 import './video-card.scss'
import { EventService, ContentService } from '@common/services'
riot.tag2('td-video-card', '<a href="{opts.link ? opts.link(item) : link(item)}" onmouseenter="{mouseenter}" onmouseleave="{mouseleave}" class="video-card {opts.itemClass}"> <div class="video-container"> <div class="video-thumb"> <img class="owl-lazy" data-src="{item.image.thumbnailHorizontal}" data-src-retina="{item.image.thumbnailHorizontal}" riot-src="{item.image.thumbnailHorizontal}"> </div> <i if="{isLockContent(item)}" class="video-lock si si-lock"></i> <div class="video-content"> <div class="video-title"> <div> {item.title} </div> </div> <div class="video-summary"> <div class="video-summary-text"> {item.summary} </div> <div class="video-summary-player"> <div class="play-video"> <i class="fa fa-play"></i> </div> </div> </div> </div> <div class="control {item.myList ? \'show\' : \'\'}"> <div class="control-btn add-list" onclick="{myList}"> <i if="{!item.myList}" ref="addList" data-toggle="tooltip" data-placement="top" title="Adicionar à minha lista" class="si si-plus"> </i> <i if="{item.myList}" ref="removeList" data-toggle="tooltip" data-placement="top" title="Remover da minha lista" class="si si-check"> </i> </div> </div> <div class="video-progress" riot-style="{getProgress(item)}"></div> </div> </a>', '', '', function(opts) {

        this.PRIVACY = ContentService.PRIVACY

        if (window.myListTag) {
            window.myListTag.on('update', (data) => {
                if (data && data.updateContent && data.updateContent._id === this.item._id) {
                    this.item.myList = data.updateContent.myList
                    this.update()
                }
            })
        }

        this.link = (item) => {
            if (item.course) {
                return 'curso/' + item.course.slug + '/' + item.slug
            }
            return 'conteudo/' + item.slug
        }

        this.myList = async (e) => {
            e.preventDefault()

            this.item.myList = !this.item.myList

            if (window.myListTag) {
                if (!this.item.myList) {
                    window.myListTag.myList = window.myListTag.myList.filter((data) => {
                        return data._id !== this.item._id
                    })
                } else {
                    window.myListTag.myList.unshift(this.item)
                }

                window.myListTag.update({
                    updateContent:  {
                        _id: this.item._id,
                        myList: this.item.myList
                    }
                })
            }

            $('.tooltip').remove()

            this.update()

            await EventService.post({
                content: this.item._id,
                type: 'myList',
                value: this.item.myList
            })
        }

        this.on('update', () => {
            $(this.refs.removeList).tooltip({ boundary: 'window' })
            $(this.refs.addList).tooltip({ boundary: 'window' })
        })

        this.on('mount', () => {
            $(this.refs.removeList).tooltip({ boundary: 'window' })
            $(this.refs.addList).tooltip({ boundary: 'window' })
        })

        this.getProgress = (item) => {
            if (!item.progress) {
                return { width: '0%' }
            }
            return { width: this.item.progress.percent * 100 + '%' }
        }
});