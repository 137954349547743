import riot from 'riot'
 import { ReportService } from '@common/services'
riot.tag2('td-dashboard-dimension-ranking', '<div class="dashboard-card"> <div class="dashboard-card__header"> <div class="dashboard-card__header-title">Ranking</div> </div> <div class="dashboard-card__content"> <div class="dashboard-card__filter w-100"> <span class="dashboard-card__filter-label">Filtrar por</span> <div class="dashboard-card__filter-content"> <td-select class="select-small" model="filter" onchange="{onchange}" onstart="{onchange}" placeholder="Todas as dimenções" options="{{minimumResultsForSearch: -1}}"> <option each="{dimension in parent.dimensions}" riot-value="{dimension.key}"> {dimension.label} </option> </td-select> </div> </div> <div class="row my-30 w-100" if="{data}"> <div class="col-6 d-flex justify-content-center align-items-center"> <div class="numbers"> <span class="big-text">{data.total}</span> <span class="small-text"> / 5</span> </div> </div> <div class="col-6 d-flex justify-content-center align-items-center"> <div class="rating"> <i class="fa {getStarClass(1)}"></i> <i class="fa {getStarClass(2)}"></i> <i class="fa {getStarClass(3)}"></i> <i class="fa {getStarClass(4)}"></i> <i class="fa {getStarClass(5)}"></i> </div> </div> </div> <div class="data-list mt-30" if="{data && data.list.length}"> <div class="dark-color mb-5">Ranking por colaborador</div> <div each="{item in data.list}" class="row data-list__item"> <div class="col">{item.name}</div> <div class="col-2">{item.percent}</div> </div> </div> </div> </div>', '', '', function(opts) {

		this.dimensions = Object.keys(ReportService.DIMENSIONS).map((key) => {
			return {
				...ReportService.DIMENSIONS[key],
				key
			}
		})

		this.dimensions.unshift({
			label: 'Todas as dimenções',
			key: 0
		})

        this.query = {}
		this.data = {}

		this.getStarClass = (number) => {
			const result = number - this.data.totalFloat
			if (result <= 0) return 'fa-star'
			if (result >= 1) return 'fa-star-o'
			return 'fa-star-half-o'
		}

		this.onchange = (value) => {
			loadData({dimension: value ? value : undefined})
		}

		const loadData = async (query = {}) => {

            this._chartQueryString = JSON.stringify(opts.chartQuery)
            this.query = { ...this.query, ...opts.chartQuery, ...query }

            if (this.query.dimension == 0) {
                delete this.query.dimension
            }

			const data = await ReportService.getType('dimensions-ranking', this.query)

            data.total = (data.total / 10 / 2).toFixed(1)
            if (isNaN(data.total)) {
                data.total = 0
            }
			data.totalFloat = parseFloat(data.total)

			data.list = data.list.map((o) => {
				o.percent = (o.percent / 10 / 2).toFixed(1)
				return o
			})

			this.update({ data })
        }

        this.on('update', () => {
            if ( this._chartQueryString != JSON.stringify(opts.chartQuery)) {
                this.data = null
                loadData()
            }
        })

});