import riot from 'riot'
 import { AuthService } from '@common/services'
import store from '@td-premium/store'
riot.tag2('signup-index', '<div class="signup-content col-7 active"> <h1 class="h4 font-w300 mt-20 text-center">Cadastre seus dados de acesso.</h1> <form onsubmit="{onsubmit}" autocomplete="off"> <td-input label="Empresa" required="{true}" placeholder="Empresa" model="data.name" error="error.name" if="{data.metadata.users > 1}"></td-input> <div class="row"> <div class="col-md-6 pr-5"> <td-input label="Nome Completo" required="{true}" placeholder="Nome Completo" model="data.ownerName" error="error.ownerName" if="{data.metadata.users > 1}"></td-input> <td-input label="Nome Completo" required="{true}" placeholder="Nome Completo" model="data.name" error="error.name" if="{data.metadata.users === 1}"></td-input> </div> <div class="col-md-6 pl-5"> <td-input label="Email" required="{true}" placeholder="Email" error="error.email" model="data.email" type="email"></td-input> </div> </div> <div class="row"> <div class="col-md-6 pr-5"> <td-input label="Senha" type="password" required="{true}" autocomplete="new-password" placeholder="Senha" error="error.senha" model="data.password"></td-input> </div> <div class="col-md-6 pl-5"> <td-input label="Confirme sua senha" type="password" required="{true}" autocomplete="new-password" placeholder="Confirme sua senha" model="data.confirmPassword" error="error.confirmPassword"></td-input> </div> </div> <div class="row"> <div class="col-md-6 pr-5"> <td-input label="CPF / CNPJ" placeholder="CPF / CNPJ" model="data.document" error="error.document" type="number"></td-input> </div> <div class="col-md-6 pl-5"> <td-input label="Telefone" required="{true}" placeholder="Telefone" model="data.phone" error="error.phone" type="number"></td-input> </div> </div> <button type="submit" class="btn btn-block btn-hero btn-noborder btn-rounded btn-alt-primary"> <i if="{loading}" class="fa fa-spinner fa-spin"></i> <span if="{!loading}"> Cadastrar </span> </button> </form> </div>', '', '', function(opts) {

        const query = queryString()

        this.data = {
            email: query.email || '',
            name: query.name || '',
            metadata: {
                users: Number(query.users) || 1
            }
        }

        this.onsubmit = async (e) => {
            e.preventDefault()

            if (this.loading)
                return

            this.loading = true

            try {
                await AuthService.signup(this.data)
                globalRouter.page('/app')
                this.app.update()
            } catch (error) {
                this.error = error.message
                this.update({ loading: false })
            }
        }
});