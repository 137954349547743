import riot from 'riot'
 import { ReportService } from '@common/services'
riot.tag2('td-dashboard-difficulties', '<div class="dashboard-card"> <div class="dashboard-card__header"> <div class="dashboard-card__header-title"> Quais das dificuldades abaixo você encontra na sua empresa: </div> </div> <div class="dashboard-card__content justify-content-start"> <div class="w-100" if="{data}"> <div class="mb-15" each="{option in data.options}"> <td-chart-bar data="{option}"></td-chart-bar> </div> </div> </div> </div>', '', '', function(opts) {

        this.query = {}

		const loadData = async (query = {}) => {
            this._chartQueryString = JSON.stringify(opts.chartQuery)
            this.query = { ...this.query, ...opts.chartQuery, ...query }
			const data = await ReportService.getType('feedback-q1', this.query)
			this.update({ data })
        }

        loadData()

        this.on('update', () => {
            if ( this._chartQueryString != JSON.stringify(opts.chartQuery)) {
                this.data = null
                loadData()
            }
        })

});