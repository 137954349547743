import riot from 'riot'
 import './course.scss'
import moment from 'moment'
import { CourseService } from '@common/services'
import store from '@td-premium/store'
riot.tag2('td-course', '<div class="content content-top course-list"> <div class="category-menu"> <h3 class="title">Cursos</h3> </div> <div class="row" if="{courses.length}"> <div each="{course in courses}" class="col-md-4 mb-30"> <div class="td-card"> <div class="td-card-img"> <img riot-src="{course.photo.thumbnailHorizontal}"> </div> <div class="td-card-item-content"> <div class="td-card-title">{course.name}</div> <div class="td-card-sub-title"> <div class="author" if="{course.author}"> {course.author.name} </div> <div class="hours"> {videoTime(course.duration)} </div> </div> <div class="td-card-desc"> {course.summary} </div> <div class="action-group"> <a href="curso/{course.slug}" class="btn btn-hero btn-white"> {course.view ? \'Continuar\' : \'Começar agora\'} </a> </div> </div> </div> </div> </div> </div>', '', '', function(opts) {

        this.courses = []

        const loadCourses = async () => {
            let courses = await CourseService.find()
            this.update({ courses })
        }

        loadCourses()

});