import riot from 'riot'
 import './external.scss'
import { AuthService } from '@common/services'
riot.tag2('td-forgot-password', '<div class="row mx-0 justify-content-center"> <div class="hero-static col-lg-6 col-xl-4 d-flex align-items-center"> <div class="content content-full overflow-hidden"> <div class="py-50 text-center"> <a href=""> <img riot-src="{app.imageLogo}" height="{app.imageSize}"> </a> </div> <div class="alert alert-success" if="{showMessage}"> Você deverá receber em breve um e-mail permitindo a redefinição de sua senha. Por favor, certifique-se de verificar sua caixa de spam se você não encontrar o e-mail. </div> <form if="{!showMessage}" onsubmit="{onsubmit}"> <td-input label="E-mail" placeholder="E-mail" model="data.email" type="email"></td-input> <button type="submit" class="btn btn-block btn-hero btn-noborder btn-rounded btn-alt-primary"> <i if="{loading}" class="fa fa-spinner fa-spin"></i> <span if="{!loading}"> Redefinir Senha </span> </button> </form> <div class="text-center py-20"> <a href="login">Clique aqui para entrar</a> </div> </div> </div> </div>', '', '', function(opts) {


        this.data = {}

        this.onsubmit = async (e) => {
            e.preventDefault()

            if (this.loading)
                return

            this.loading = true

            try {
                await AuthService.forgotPassword(this.data)
                this.update({ showMessage: true })
            } catch (error) {
                this.error = error.message
                this.update({ loading: false })
            }
        }
});