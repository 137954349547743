import riot from 'riot'
 import './category.scss'
import { ContentService, CategoryService } from '@common/services'
import store from '@td-premium/store'
riot.tag2('td-category', '<div class="content content-top"> <div class="category-menu"> <h3 class="title">Categorias</h3> <td-drop-select data="{categories}" default="Selecione uma categoria"> <a href="categoria/{item.slug}" class="category-list-title"> {item.name} </a> </td-drop-select> </div> <home-category each="{item, key in categories}"></home-category> </div>', '', '', function(opts) {

        this.categories = []

        const loadCategories = async () => {
            let categories = await CategoryService.find({type: 1, limit: 999})
            let trackers = await CategoryService.find({type: 2, limit: 999})
            this.update({ categories: [...trackers, ...categories] })
        }

        loadCategories()

});