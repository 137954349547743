import riot from 'riot'
 import { ContentService, CategoryService } from '@common/services'
import store from '@td-premium/store'
riot.tag2('td-home-category-load', '<home-category each="{item, key in categories}"></home-category>', '', '', function(opts) {

        this.categories = []

        const loadCategories = async (page) => {
            let categories = await CategoryService.find({ type: CategoryService.TYPE.NORMAL, limit: 3, page: (page || 1) - 1 })
            this.categories = [].concat(this.categories, categories)
            this.update()
        }

        this.mixin('infiniteScroll')

        this.on('nextPage', async () => {
            await loadCategories(this._page)
            this._loading = false
        })

        this.on('mount', () => {
            this.infiniteScroll()
            this.nextPage()
        })

        this.on('unmount', () => {
            this.destroyInfiniteScroll()
        })

});