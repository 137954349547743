
import riot from 'riot'

riot.mixin('questions', {
    prepare: function(type) {
        this.type = type
        const values = this.getMetaData('score.' + type, {})

        this.questions.forEach((o, i) => {
            const d = values[type+'_'+(i+1)]
            o.value = d ? d.value : undefined
        })
    },
    saveAnswers: async function() {
        let data = {}
        let points = 0
        let totalWeight = 0

        this.questions.forEach((o) => {
            data[o.field] = {
                value: o.value || 0,
                weight: o.weight
            }
            points += o.valueWeight
            totalWeight += o.weight * 100
        })

        this.setMetaData('score.' + this.type, data)
        this.setMetaData('score.result.' + this.type, Math.floor(points / totalWeight * 100))
        return await this.saveMetaData()        
    }
})