import riot from 'riot'
 import './external.scss'
import { AuthService, CourseService } from '@common/services'
import store from '@td-premium/store'
riot.tag2('td-login', '<div class="row mx-0 justify-content-center"> <div class="hero-static col-lg-6 col-xl-4 d-flex align-items-center"> <div class="content content-full overflow-hidden"> <div class="py-50 text-center"> <a href=""> <img riot-src="{app.imageLogo}" height="{app.imageSize}"> </a> </div> <form onsubmit="{onsubmit}"> <div class="{groupEmail}"> <div class="col-12"> <div class="form-group"> <label for="email">E-mail</label> <input ref="email" id="email" type="text" class="form-control" placeholder="Email" autofocus> </div> <div class="invalid-feedback animated fadeIn"> {emailError} </div> </div> </div> <div class="{groupPassword}"> <div class="col-12"> <div class="form-group"> <label for="password">Senha</label> <input ref="password" id="password" type="password" class="form-control" placeholder="Senha"> </div> <div class="invalid-feedback animated fadeIn"> {passwordError} </div> </div> </div> <div class="{groupLogin}"> <div class="col-12"> <div class="invalid-feedback animated fadeIn"> {loginError} </div> </div> <div class="col-12 mb-10"> <button type="submit" class="btn btn-block btn-hero btn-noborder btn-rounded btn-alt-primary"> <i if="{loading}" class="fa fa-spinner fa-spin"></i> <span if="{!loading}"> Entrar </span> </button> </div> <div class="col-12 mb-10 text-center"> <a href="esqueceu-a-senha" class="o-5"> Esqueci minha senha </a> </div> </div> </form> </div> </div> </div>', '', '', function(opts) {


        this.groupPassword = {
            'form-group': true,
            'row': true,
            'is-invalid': false
        }

        this.groupEmail = {
            'row': true,
            'is-invalid': false
        }

        this.groupLogin = {
            'row': true,
            'is-invalid': false
        }

        this.onsubmit = async (e) => {
            e.preventDefault()

            if (this.loading)
                return

            this.groupEmail['is-invalid'] = false
            this.groupPassword['is-invalid'] = false
            this.groupLogin['is-invalid'] = false

            if (!this.refs.email.value) {
                this.groupEmail['is-invalid'] = true
                this.emailError = 'Por favor insira um email'
            }

            if (!this.refs.password.value) {
                this.groupPassword['is-invalid'] = true
                this.passwordError = 'Por favor insira uma senha'
            }

            if (this.groupPassword['is-invalid'] || this.groupPassword['is-invalid']) {
                return this.update()
            }

            this.loading = true

            try {
                const data = await AuthService.login(
                    this.refs.email.value,
                    this.refs.password.value
                )

                setTimeout(async () => {
                    if (!data.active || data.pedding) {
                        window.location = '/app/assinar'
                        return
                    }

                    if (data.permissions.all || (data.permissions.groups.length && data.permissions.courses.length)) {
                        return window.location = '/app'
                    }

                    if (opts.course || data.permissions.courses.length == 1) {
                        const courses = data.permissions.courses
                        if (courses.length === 1) {
                            const course = await CourseService.findOne({_id: courses[0]})
                            window.location = '/app/curso/' + course.slug
                        } else {
                            window.location = '/app/curso'
                        }
                    } else if (opts.group || data.permissions.groups.length == 1) {
                        window.location = '/app/grupo/'+data.permissions.groups[0]+'/conteudo'
                    } else {
                        window.location = '/app'
                    }

                }, 1000)

            } catch (error) {

                this.groupLogin['is-invalid'] = true
                this.loginError = error.message
                this.loading = false

                setTimeout(() => {
                    this.update()
                }, 1000)
            }

        }

});