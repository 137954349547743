import riot from 'riot'
 
import { ContentService } from '@common/services'
riot.tag2('td-search-page', '<div class="content content-top"> <h3 class="profile-main-title"> Resultados de "{q}" </h3> <div class="row" if="{result}"> <div class="col-md-3" each="{item in result}"> <content-card path="{\'busca/\'+q+\'/conteudo\'}" item="{item}"></content-card> </div> </div> </div>', '', '', function(opts) {

        this.q = decodeURI(opts.q)

        this.result = null

        const loadResults = async () => {
            const result = await ContentService.find({
                search: this.q
            })
            this.update({ result })
        }

        loadResults()

        this.on('update', () => {
            var newQ = decodeURI(opts.q)
            if (newQ != this.q) {
                this.q = newQ
                loadResults()
            }

        })

});