import riot from 'riot'
 import './group.scss'
import { GroupService, ContentService } from '@common/services'
riot.tag2('td-group', '<div class="td-group" if="{group}"> <div class="bg-image mb-0" riot-style=" background-color: {group.color || \'#1c1c1e\'}; {group.image && group.image.cover ? \'background-image: url(\\\'\'+group.image.cover+\'\\\')\': \'\'}"> <div class="bg-black-overlay"> <div class="content pb-20 pt-50 text-left"> <div class="pt-50 pb-50"> <div if="{group.parent}" class="td-group__parent text-muted text-uppercase mb-20"> {group.parent.name} </div> <h1 class="font-w700 text-white mb-10">{group.name}</h1> <h2 if="{group.description}" class="h4 font-w400 text-white-op mb-30"> {group.description} </h2> </div> </div> </div> </div> <nav class="breadcrumb push bg-black-op text-body-color-light px-0" style="margin-bottom: 60px;"> <div class="content py-0"> <a class="breadcrumb-item" href="">Home</a> <virtual each="{item in breadcrumb}"> <a if="{!item.last}" class="breadcrumb-item" href="{item.url}">{item.label}</a> <span if="{item.last}" class="breadcrumb-item active">{item.label}</span> </virtual> </div> </nav> <div class="content pt-0" if="{contents && contents.length}"> <div class="row"> <div class="col-md-3" each="{item in contents}"> <content-card path="{path}" item="{item}"></content-card> </div> </div> </div> <virtual if="{group}"> <div class="group-content mb-20 pb-20" each="{item in group.children}"> <td-group-contents path="{path}" name="{item.name}" group-id="{item._id}"></td-group-contents> </div> </virtual> </div>', '', '', function(opts) {

        this.path = 'grupo/'+ opts.groupId +'/conteudo'

        const getBreadcrumb = (item, last=true) => {
            return [...(item.parent ? getBreadcrumb(item.parent, false) : []), {
                url: this.getGroupUrl(item),
                label: item.name,
                last
            }]
        }

        const init = async () => {

            this.contents = await ContentService.find({
                groups__in: opts.groupId
            })

            this.group = await GroupService.findById(opts.groupId, { ancestors: true, childrens: true, counts: true })
            this.path = 'grupo/'+ this.group._id +'/conteudo'

            this.breadcrumb = getBreadcrumb(this.group)
            this.update()
        }

        this.on('update', ()=> {
            if (this.group && this.group._id != opts.groupId) {
                init()
            }
        })

        init()
});