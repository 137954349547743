import riot from 'riot'
 import { ContentService } from '@common/services'
riot.tag2('home-category-track', '<div if="{item.videos.length}" class="category-list" onmouseenter="{enterCarousel()}" onmouseleave="{leaveCarousel()}"> <a href="categoria/{item.slug}" class="category-list-title"> {item.name} </a> <video-carousel link="{videoUrl}" ref="videocarousel" items="{item.videos}"></video-carousel> </div>', '', '', function(opts) {

        this.item.videos = []

        this.videoUrl = (item) => {
            if (!this.item) {
                return 'content/' + item.slug
            }
            return 'trilha/' + this.item.slug + '/' + item.slug
        }

        const loadVideos = async () => {

            this.item.videos = await ContentService.find({
                _id__in: this.item.contents.join(','),
                limit: 1000
            })

            this.update()
        }

        loadVideos()

        this.enterCarousel = (key) => {
            return (e) => {
                e.preventUpdate = true
                this.refs.videocarousel.showNavigation()
            }
        }

        this.leaveCarousel = (key) => {
            return (e) => {
                e.preventUpdate = true
                this.refs.videocarousel.hideNavigation()
            }
        }
});