import riot from 'riot'
 riot.tag2('td-questions-tecnologia-digital', '<div class="onboarding"> <div class="header"> <div class="col"> <div class="py-30 px-5 logo"> <img riot-src="{app.imageLogo}" height="{app.imageSize}"> </div> </div> </div> <div class="content temas"> <div class="col-6 tema-big"> <h1 class="title">TECNOLOGIA DIGITAL</h1> <p>A tecnologia é base para o que chamamos de transformação digital. E, naturalmente, falar em ferramentas digitais leva-nos à outras reflexões importantes como análise de dados, métricas, indicadores, segurança, tecnologias futuras, gestão da informação, entre outros aspectos.</p> <a href="onboarding/perguntas/agilidade-operacional" class="back-button btn text-white">Voltar</a> </div> <div class="col-6 question-list"> <td-question each="{item, index in questions}" index="{index+1}"></td-question> <button onclick="{save}" class="btn btn-hero">Avançar</button> </div> </div> </div>', '', '', function(opts) {


        this.save = async () => {
            this.saveAnswers()
            globalRouter.page('/app/onboarding/perguntas/experiencia-do-cliente')
        }

        this.questions = [
            { field: 'tecnologia_digital_1', weight: 1, tag: 'Análise de dados', title: 'Entendo o impacto que a coleta, estruturação e leitura inteligente de dados podem ocasionar em meu trabalho.' },
            { field: 'tecnologia_digital_2', weight: 3, tag: 'KPI\'s', title: 'Em cada projeto que executo tenho mapeados os indicadores de sucesso das ações.' },
            { field: 'tecnologia_digital_3', weight: 2, tag: 'Cloud computing', title: 'Sei dos benefícios que o armazenamento de dados na nuvem (cloud computing) podem trazer para meu trabalho.' },
            { field: 'tecnologia_digital_4', weight: 1, tag: 'Segurança de dados', title: 'Prezo pela segurança digital dos arquivos que temos armazenados, seguindo as orientações do time de TI.' },
            { field: 'tecnologia_digital_5', weight: 2, reverse: true, tag: 'IoT e Impressão 3D', title: 'Não vejo aplicações reais da Internet das Coisas e da impressão 3D, sendo tecnologias que devem cair em desuso nos próximos anos.' },
            { field: 'tecnologia_digital_6', weight: 3, tag: 'Análise de dados', title: 'Tomo decisões com base em sistemas analíticos, utilizando análise de dados para construção de estratégias.' },
            { field: 'tecnologia_digital_7', weight: 1, reverse: true, tag: 'Gestão da informação', title: 'É melhor controlar as informações da empresa em documentos do Word e planilhas segmentadas do Excel do que compilar tudo em um único ambiente.' },
        ]

        this.mixin('questions')
        this.prepare('tecnologia_digital')

});