import riot from 'riot'
 import card from 'card'
import { AuthService } from '@common/services'
import store from '@td-premium/store'
riot.tag2('signup-payment', '<div class="signup-content active col-7" if="{!loadingPage}"> <h1 class="h4 font-w300 mt-20 text-center">Para finalizar, só faltam seus dados de pagamento</h1> <form ref="form" onsubmit="{onsubmit}"> <div class="credit-card mb-20"></div> <div class="row"> <div class="col-md-6 pr-5"> <td-input required="{true}" name="number" placeholder="Número do Cartão" error="error.card_number" model="data.card_number"></td-input> </div> <div class="col-md-6 pl-5"> <td-input required="{true}" name="name" placeholder="Nome (igual ao cartão)" error="error.holder_name" model="data.holder_name"></td-input> </div> </div> <div class="row"> <div class="col-md-6 pr-5"> <td-input required="{true}" name="expiry" placeholder="Validade" error="error.card_expiration" model="data.card_expiration"></td-input> </div> <div class="col-md-6 pl-5"> <td-input required="{true}" name="cvc" placeholder="CVC" error="error.card_cvv" model="data.card_cvv"></td-input> </div> </div> <button type="submit" class="btn btn-block btn-hero btn-noborder btn-rounded btn-alt-primary"> <i if="{loading}" class="fa fa-spinner fa-spin"></i> <span if="{!loading}"> Finalizar </span> </button> </form> </div>', '', '', function(opts) {

        this.loadingPage = true

        const init = () => {
            if (!store.get('plan')) {
               return globalRouter.page('/app/cadastro/plano')
            }

            this.data = {
                plan: store.get('plan')._id
            }

            this.loadingPage = false
        }

        init()

        this.on('mount', () => {
            new Card({
                form: this.refs.form,
                container: '.credit-card',
                masks: {
                    cardNumber: '•'
                },
                placeholders: {
                    number: '•••• •••• •••• ••••',
                    name: 'Nome Completo',
                    expiry: '••/••••',
                    cvc: '•••'
                },
                messages: {
                    validDate: 'expire\ndate',
                    monthYear: 'mm/yy'
                }
            })
        })

        this.onsubmit = async (e) => {
            e.preventDefault()

            if (this.loading)
                return

            this.loading = true

            try {
                await AuthService.payment(this.data)

                $success({
                    toast: false,
                    position: 'center',
                    title: 'Sucesso!',
                    html: 'Pagamento realizado com sucesso!',
                    showConfirmButton: true,
                    timer: null
                })
                .then((result) => {
                    globalRouter.page('/app')
                })

            } catch (error) {

                $error({
                    toast: false,
                    position: 'center',
                    title: 'Error!',
                    html: error.message,
                    showConfirmButton: true,
                    timer: null
                })

                this.update({ loading: false })
            }
        }

});