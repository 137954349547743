import riot from 'riot'
 import { ContentService, CategoryService } from '@common/services'
import store from '@td-premium/store'
riot.tag2('td-home-category-track-load', '<home-category-track each="{item, key in trackers}"></home-category-track>', '', '', function(opts) {

        this.trackers = []

        const loadTrackers = async () => {
            let trackers = await CategoryService.find({ type: CategoryService.TYPE.TRACK })
            this.update({ trackers })
        }

        loadTrackers()

});