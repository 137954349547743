import riot from 'riot'
 import './dashboard.scss'
riot.tag2('td-dashboard-user', '<div class="content content-top dashboard-page"> <div class="category-menu"> <h3 class="title">Relatório individual</h3> </div> <div class="dashboard-group row row-eq-height"> <div class="col-md-12 mb-30"> <td-dashboard-user-profile user-id="{userId}" class="dashboard-tag"></td-dashboard-user-profile> </div> <div class="col-md-4 mb-30"> <td-dashboard-digital-maturity hide-department="{true}" user-id="{userId}" class="dashboard-tag"></td-dashboard-digital-maturity> </div> <div class="col-md-4 mb-30"> <td-dashboard-dimensions hide-department="{true}" user-id="{userId}" class="dashboard-tag"></td-dashboard-dimensions> </div> <div class="col-md-4 mb-30"> <td-dashboard-dominances hide-department="{true}" user-id="{userId}" class="dashboard-tag"></td-dashboard-dominances> </div> </div> </div>', '', '', function(opts) {

        this.userId = opts.userId == 'user' ? this.currentUser()._id :  opts.userId;

        this.on('mount', function () {

            let expanded = false
            let cacheRect = null

			$('.dashboard-card__header-expand').on('click', function() {

                const cardTag = $(this).closest('.dashboard-tag')[0]._tag

                const card = $(this).closest('.dashboard-card')
                const page = $(this).closest('.dashboard-page')
                const group = card.closest('.dashboard-group')
                const allCards = page.find('.dashboard-card')

                const rect = card[0].getBoundingClientRect()
                const groupRect = group[0].getBoundingClientRect()

                if (!expanded) {

                    cacheRect = rect

                    card.css({top: rect.top, left: rect.left, height: rect.height, width: rect.width})

                    card.addClass('open-card')
                    allCards.addClass('close-card')
                    card.removeClass('close-card')

                    setTimeout(() => {
                        page.find('.category-menu').css({opacity: 0})
                        card.css({top: 80, left: groupRect.left, height: 'calc(100vh - 100px)', width: groupRect.width})
                    })

                    expanded = true
                    cardTag.trigger('open-card')
                } else {

                    card.css({top: cacheRect.top, left: cacheRect.left, height: cacheRect.height, width: cacheRect.width})

                    setTimeout(() => {
                        page.find('.category-menu').css({opacity: 1})
                        allCards.removeClass('close-card')
                    }, 100)

                    setTimeout(() => {
                        allCards.removeAttr('style')
                        allCards.removeClass('open-card')
                    }, 200)

                    expanded = false
                    cardTag.trigger('close-card')
                }

			})
        })
});