import riot from 'riot'
 riot.tag2('td-questions-experiencia-do-cliente', '<div class="onboarding"> <div class="header"> <div class="col"> <div class="py-30 px-5 logo"> <img riot-src="{app.imageLogo}" height="{app.imageSize}"> </div> </div> </div> <div class="content temas"> <div class="col-6 tema-big"> <h1 class="title">EXPERIÊNCIA DO CLIENTE</h1> <p>O conceito de experiência do cliente é amplamente abordado na jornada de transformação digital. A maioria das ações que fazemos nas empresas tem como finalidade as pessoas. Logo elas assumem um papel central quando são consideradas nossos clientes.</p> <p>Sendo o cliente todo aquele que consome o seu produto e/ou serviço, vamos conferir algumas questões relevantes desse cenário.</p> <a href="onboarding/perguntas/tecnologia-digital" class="back-button btn text-white">Voltar</a> </div> <div class="col-6 question-list"> <td-question each="{item, index in questions}" index="{index+1}"></td-question> <button onclick="{save}" class="btn btn-hero">Avançar</button> </div> </div> </div>', '', '', function(opts) {


        this.save = async () => {
            this.saveAnswers()
            globalRouter.page('/app/onboarding/perguntas/empreendedorismo-digital')
        }

        this.questions = [
            { field: 'experiencia_do_cliente_1', weight: 2, tag: 'Metodologias ágeis', title: 'Acredito que testar possíveis soluções para nossos clientes, despendendo recursos em protótipos simplificados, pode tornar nossos produtos mais lucrativos.' },
            { field: 'experiencia_do_cliente_2', weight: 3, tag: 'Personas', title: 'Tenho mapeadas as necessidades das personas da empresa e sei quais soluções oferecer para cada perfil de cliente, aumentando a geração de valor do meu produto.' },
            { field: 'experiencia_do_cliente_3', weight: 3, reverse: true, tag: 'Gamificação', title: 'As dinâmicas de gamificação prejudicam o engajamento dos clientes, geralmente dificultando o processo de retenção.' },
            { field: 'experiencia_do_cliente_4', weight: 2, tag: 'Jornada do Cliente', title: 'Entendo os hábitos de consumo dos meus clientes e levo isso em consideração para proporcionar uma melhor experiência durante a jornada do consumidor.' },
            { field: 'experiencia_do_cliente_5', weight: 2, reverse: true, tag: 'Mindset', title: 'Considero que a preocupação com melhorias no produto dispersa a equipe de pós-vendas.' },
            { field: 'experiencia_do_cliente_6', weight: 1, tag: 'Ferramentas', title: 'O uso de uma plataforma de atendimento omnichannel pode contribuir com o aumento das vendas e/ou retenção de clientes.' },
            { field: 'experiencia_do_cliente_7', weight: 1, tag: 'Análise de dados', title: 'Administrar os dados dos clientes em uma plataforma digital favorece a análise do comportamento do usuário.' },
        ]

        this.mixin('questions')
        this.prepare('experiencia_do_cliente')

});