import riot from 'riot'
 import { UserService } from '@common/services'
import store from '@td-premium/store'
riot.tag2('td-onboarding-admin-user', '<div class="onboarding"> <div class="header"> <div class="col"> <div class="py-30 px-5 logo"> <img riot-src="{app.imageLogo}" height="{app.imageSize}"> </div> </div> </div> <div class="content temas"> <div class="col-6 tema-big"> <h1 class="title">Cadastro de usuários</h1> <p> Informe agora o nome de cada usuário e o e-mail correspondente. Após clicar em avançar, cada usuário receberá um convite para cadastrar sua senha e ter acesso ao TD Premium. </p> <a href="onboarding/admin/departamento" class="back-button btn text-white"> Voltar </a> </div> <div class="col-6 temas-options-container justify-content-between flex-column"> <div class="two-fields input-multi-lines flex flex-column flex-1 justify-content-center"> <div model-loop class="add-group" each="{user,index in filter(users)}"> <td-input class="{\'user-input-name-\'+index}" required="{true}" model="user.name" placeholder="Nome do usuário" onkeydown="{onkeydownName(index,user)}"></td-input> <td-input class="{\'user-input-email-\'+index}" required="{true}" model="user.email" placeholder="Email do usuário" onkeydown="{onkeydownEmail(index,user)}" type="email"></td-input> <a href="#" tabindex="-1" onclick="{remove(user)}" class="si si-close" title="Remover campo"></a> </div> <a href="#" onclick="{add}" class="si si-plus" title="Adicionar mais usuários" if="{countUsers>users.length}"></a> <button onclick="{save}" class="btn btn-hero mt-20"> <i if="{loading}" class="fa fa-spinner fa-spin"></i> <span if="{!loading}"> Avançar </span> </button> </div> </div> </div> </div>', '', '', function(opts) {
        const session = store.get('session')

        this.countUsers = session.permissions.users

        this.users = []

        this.remove = (user) => {
            return (e) => {
                e && e.preventDefault()
                if (user._id) {
                    return user._deleted = true
                }
                const index = this.users.indexOf(user)
                this.users.splice(index, 1)
            }
        }

        this.add = (e) => {
            e && e.preventDefault()
            if (this.countUsers === this.users.length) return false
            this.users.push({
                name: '',
                email: ''
            })
            return true
        }

        this.save = async (e) => {
            e && e.preventDefault()

            if (this.loading) return

            this.loading = true

            try {
                await UserService.book(this.users.filter((o) => {
                    if ((!o.name || !o.email) && o._id)
                        o._deleted = true
                    return o._deleted || (!!o.name && !!o.email)
                }))

                globalRouter.page('onboarding/admin/finalizar')
            } catch (error) {
                window.location.reload()
            }

        }

        this.filter = () => {
            return this.users.filter((o) => {
                return !o._deleted
            })
        }

        this.onkeydownName = (index, user) => {
            return (e) => {
                const code = e.keyCode || e.which

                if (code == '8') {
                    if (!user.name && !user.email && this.filter(this.users).length > 1) {
                        this.remove(user)()
                        setTimeout(() => {
                            const input = $('.user-input-email-'+ (index-1) +' input')
                            if (input.length) {
                                input[0].focus()
                            }
                        })
                    }
                    return;
                }
            }
        }

        this.onkeydownEmail = (index, user) => {
            return (e) => {
                const code = e.keyCode || e.which

                if (code == '8') {
                    if (!user.email) {
                        setTimeout(() => {
                            const input = $('.user-input-name-'+ (index) +' input')
                            if (input.length) {
                                input[0].focus()
                            }
                        })
                    }
                    return;
                }

                if (code == '13' || code == '9') {

                    if (index == this.filter(this.users).length - 1) {
                        e.preventDefault()

                        if ( this.add() ) {
                            setTimeout(() => {
                                $('.user-input-name-'+ (index+1) +' input')[0].focus()
                            })
                        }

                    } else if (code == '13') {
                        setTimeout(() => {
                            $('.user-input-name-'+ (index+1) +' input')[0].focus()
                        })
                    }

                    return;
                }
            }
        }

        const loadUsers = async () => {
            const users = await UserService.find({
                _id__nin: session.user._id
            })

            if (users.length) {
                this.users = users
            } else {
                for (let i = 0; i < 3; i++) {
                    this.add()
                }
            }

            this.update()
        }

        loadUsers()
});