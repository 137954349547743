import riot from 'riot'
 import './tooltip.scss'
riot.tag2('td-chart-tooltip', '<div class="chart-tooltip" if="{_show}" riot-style="{_postion}"> <yield></yield> </div>', '', '', function(opts) {

        this._show = false
        this._postion = {}

        this.hide = () => {
            this._show = false
            this.update()
        }

        this.setPosition = (e) => {
            const x = e.clientX + 20
            const y = e.clientY - 10

            this._show = true
            this._postion = {
                left: x+ 'px',
                top: y + 'px'
            }
            this.update()
        }
});