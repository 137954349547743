import riot from 'riot'
 import avatarDefault from '@common/assets/media/avatars/avatar0.jpg'
import { ReportService, UserService } from '@common/services'
riot.tag2('td-dashboard-user-profile', '<div class="dashboard-card dashboard-card_small-height"> <div class="dashboard-card__header"> <div class="dashboard-card__header-title">Perfil</div> </div> <div class="dashboard-card__content dashboard-card__content-row"> <div class="dashboard-card__content-left dashboard-card__light-bg dashboard-card__content-radius py-0 px-20"> <div class="row w-100 mx-0" if="{data}"> <div class="col-md-5 d-flex justify-content-center"> <div class="card-avatar"> <img riot-src="{data.avatar || avatarDefault}" alt="Imagem de perfil"> </div> </div> <div class="col-md-7 d-flex align-items-center"> <div class="card-info"> <div class="user-name"> {data.name} </div> <div class="user-department"> {data.department ? data.department.name : \'----\'} </div> <div class="user-score" if="{data.metadata.score && data.metadata.score.percent}"> {data.metadata.score.percent / 10 / 2} <i class="fa fa-star"></i> </div> </div> </div> </div> </div> <div class="dashboard-card__content-right justify-content-start align-items-start py-10 px-20"> <div class="row mx-0 w-100 card-top-index"> <div class="col-md-9 d-flex align-items-center"> <div class="dashboard-card__text-dark">Progresso</div> </div> <div class="col-md-3"> <a href="configuracao/perfil" if="{currentUser()._id == opts.userId}" class="btn btn-td-outline-primary"> Editar Perfil </a> </div> </div> <div class="row mx-0 w-100 h-100 my-50" if="{count}"> <div class="col-md-5 px-0"> <div class="time-group "> <div class="time-group__number"> {count.videos} </div> <div class="time-group__label"> Vídeos assistidos </div> </div> </div> <div class="col-md-7 px-0"> <div class="time-group no-border"> <div class="time-group__number"> {count.hours}<span>h</span> {count.minutes}<span>min</span> </div> <div class="time-group__label"> Total </div> </div> </div> </div> </div> </div> </div>', '', '', function(opts) {

        this.avatarDefault = avatarDefault

        const LoadVideoCount = async (userId) => {
            const count = await ReportService.user.getType('user-videos-count', {user: userId})

            count.videos = count.videos || 0
            count.seconds = count.seconds || 0
            count.hours = Math.floor(count.seconds / 3600)
            count.minutes = Math.floor((count.seconds - (count.hours * 3600)) / 60)

            this.update({ count })
        }

        LoadVideoCount(opts.userId)

        const loadData = async (userId) => {
            let data = null

            if (this.currentUser()._id == userId) {
                data = await UserService.current.get()
            } else {
                data = await UserService.findById(userId)
            }

            this.update({ data })
        }

        loadData(opts.userId)

});