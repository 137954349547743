import riot from 'riot'
 import './old-home.scss'
import { ContentService, CategoryService } from '@common/services'
import store from '@td-premium/store'
riot.tag2('td-old-home', '<cover-carousel items="{covers}"></cover-carousel> <div class="content content-full content-relative"> <home-mylist></home-mylist> <home-free if="{initialize}"></home-free> <td-home-category-track-load if="{initialize}"></td-home-category-track-load> <td-home-category-load if="{initialize}"></td-home-category-load> </div>', '', '', function(opts) {


        this.covers = store.get('covers', [])

        const loadConvers = async () => {
            const covers = await ContentService.find({
                status: ContentService.STATUS.PUBLISHED,
                cover: true
            })

            store.set('covers', covers)

            this.update({ covers, initialize: true})
        }

        loadConvers()

});