import riot from 'riot'
 import { DepartmentService } from '@common/services'
riot.tag2('td-onboarding-admin-department', '<div class="onboarding"> <div class="header"> <div class="col"> <div class="py-30 px-5 logo"> <img riot-src="{app.imageLogo}" height="{app.imageSize}"> </div> </div> </div> <div class="content temas"> <div class="col-6 tema-big"> <h1 class="title">Cadastro de departamentos</h1> <p> Informe no quadro ao lado quais são os departamentos dos usuários que terão acesso ao TD Premium. </p> <a href="onboarding/admin" class="back-button btn text-white">Voltar</a> </div> <div class="col-6 temas-options-container justify-content-between flex-column"> <div class="input-multi-lines flex flex-column flex-1 justify-content-center"> <div model-loop class="add-group" each="{department,index in filter(departments)}"> <td-input class="{\'department-input-\'+index}" required="{true}" model="department.name" placeholder="Nome do departamento" onkeydown="{onkeydown(index,department)}"></td-input> <a href="#" tabindex="-1" onclick="{remove(department)}" class="si si-close" title="Remover campo"></a> </div> <a href="#" onclick="{add}" class="si si-plus mb-20" title="Adicionar mais departamentos"></a> <button onclick="{save}" class="btn btn-hero"> <i if="{loading}" class="fa fa-spinner fa-spin"></i> <span if="{!loading}"> Avançar </span> </button> </div> </div> </div> </div>', '', '', function(opts) {

        this.departments = []

        this.remove = (department) => {
            return (e) => {
                e && e.preventDefault()
                if (department._id) {
                    return department._deleted = true
                }
                const index = this.departments.indexOf(department)
                this.departments.splice(index, 1)
            }
        }

        this.add = (e) => {
            e && e.preventDefault()
            this.departments.push({
                name: ''
            })
        }

        this.save = async (e) => {
            e && e.preventDefault()

            if (this.loading) return

            this.loading = true

            try {
                await DepartmentService.book(this.departments.filter((o) => {
                    if (!o.name && o._id)
                        o._deleted = true
                    return o._deleted || !!o.name
                }))
                globalRouter.page('/app/onboarding/admin/usuario')

            } catch (error) {
                window.location.reload()
            }

        }

        this.filter = () => {
            return this.departments.filter((o) => {
                return !o._deleted
            })
        }

        this.onkeydown = (index, department) => {
            return (e) => {
                const code = e.keyCode || e.which

                if (code == '8') {
                    if (!department.name && this.filter(this.departments).length > 1) {
                        this.remove(department)()
                        setTimeout(() => {
                            const input = $('.department-input-'+ (index-1) +' input')
                            if (input.length) {
                                input[0].focus()
                            }
                        })
                    }
                    return;
                }

                if (code == '13' || code == '9') {

                    if (index == this.filter(this.departments).length - 1) {
                        e.preventDefault()

                        this.add()

                        setTimeout(() => {
                            $('.department-input-'+ (index+1) +' input')[0].focus()
                        })

                    } else if (code == '13') {
                        setTimeout(() => {
                            $('.department-input-'+ (index+1) +' input')[0].focus()
                        })
                    }

                    return;
                }
            }
        }

        const loadDepartments = async () => {
            const departments = await DepartmentService.find({})

            if (departments.length) {
                this.departments = departments
            } else {
                for (let i = 0; i < 3; i++) {
                    this.add()
                }
            }

            this.update()
        }

        loadDepartments()
});