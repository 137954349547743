import riot from 'riot'
 import { CategoryService } from '@common/services'
riot.tag2('td-onboarding-categories', '<div class="onboarding"> <div class="header"> <div class="col"> <div class="py-30 px-5 logo"> <img riot-src="{app.imageLogo}" height="{app.imageSize}"> </div> </div> </div> <div class="content temas"> <div class="col-6 tema-big"> <h1 class="title">Título explicando</h1> <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p> <a href="onboarding/perguntas/empreendedorismo-digital" class="back-button btn text-white">Voltar</a> </div> <div class="col-6 temas-options-container justify-content-center"> <div class="temas-options justify-content-center" if="{categories}"> <div class="item animated fadeIn {isSelected(item) ? \'selected\': \'\'}" each="{item in categories}" onclick="{onSelect(item)}"> <span class="tema-title">{item.name}</span> </div> </div> <div class="btn-group"> <button onclick="{save}" class="btn btn-hero">Começar</button> </div> </div> </div> </div>', '', '', function(opts) {


        this.selected = this.getMetaData('categories', [])

        this.save = async () => {
            this.setMetaData('categories', this.selected)
            await this.saveMetaData()
            globalRouter.page('/app/onboarding/finalizar')
        }

        this.isSelected = (item) => {
            return this.selected.indexOf(item._id) > -1
        }

        this.onSelect = (item) => {
            return () => {
                const index = this.selected.indexOf(item._id)
                if (index > -1) {
                    this.selected.splice(index, 1)
                } else {
                    this.selected.push(item._id)
                }

                if (this.selected.length > 5) {
                    this.selected.shift()
                }
            }
        }

        const loadCategories = async () => {
            let categories = await CategoryService.find({type: 1})
            this.update({ categories })
        }

        loadCategories()
});