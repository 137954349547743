import riot from 'riot'
 import { ReportService } from '@common/services'
riot.tag2('td-dashboard-answers', '<div class="dashboard-card dashboard-card__scroll"> <div class="dashboard-card__header"> <div class="dashboard-card__header-title"> {opts.title} </div> </div> <div class="dashboard-card__content" ref="scroll"> <div if="{data && data.length}" class="dashboard-card__inner dashboard-card__light-bg dashboard-card__radius" each="{answer in data}"> <span>{answer}</span> </div> <div class="d-flex justify-content-center align-items-center h-100 w-100" if="{data && !data.length}"> Nenhuma reposta encontrada. </div> </div> </div>', '', '', function(opts) {

        this.mixin('infiniteScroll')

        this.data = []

        this.query = {
            limit: 20,
            question: opts.questionId
        }

        this.on('nextPage', async () => {
            const data = await loadData({page: this._page})

            if (data.length !== this.query.limit ) {
                this._initScroll = false
            }

            data.map((item) => {
                this.data.push(item)
            })

            this.update({ _loading: false })
        })

        this.on('mount', () => {
            this.infiniteScroll(this.refs.scroll)
        })

		const loadData = async (query = {}) => {
            this.query = { ...this.query, ...opts.chartQuery, ...query,  }
            const data = await ReportService.getType('feedback-answers', this.query)
            return data
		}

		const fetchData = async () => {
            this._chartQueryString = JSON.stringify(opts.chartQuery)
            const data = await loadData()
            this.update( { data } )
            this.resetInfiniteScroll()
        }

        fetchData()

        this.on('update', async () => {
            if ( this._chartQueryString != JSON.stringify(opts.chartQuery)) {
                this.data = null
                fetchData()
            }
        })

});