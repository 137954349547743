import riot from 'riot'
 import { UserService } from '@common/services'
riot.tag2('td-onboarding-finish', '<div class="onboarding"> <div class="header"> <div class="col"> <div class="py-30 px-5 logo"> <img riot-src="{app.imageLogo}" height="{app.imageSize}"> </div> </div> </div> <div class="content temas"> <div class="col-12 temas-options-container justify-content-center flex-column"> <div class="animated animated-slow fadeIn" if="{fadeIn}"> {textLoading} </div> <div> <div class="spinner-grow text-danger mt-20" role="status"> <span class="sr-only">Loading...</span> </div> <div> </div> </div> </div>', '', '', function(opts) {


        this.finish = false
        this.textLoading = ''

        const loadingText = (text) => {
            this.fadeIn = false
            this.update()
            this.fadeIn = true
            this.textLoading = text
            this.update()
        }

        loadingText('Carregando informações.')

        let timers = []
        timers[0] = setTimeout(() => {
            loadingText('Identificando parâmetros.')
            timers[1] = setTimeout(() => {
                loadingText('Criando ambiente personalizado.')
                timers[2] = setTimeout(() => {
                    this.finish = true
                }, 2000)
            }, 2000)
        }, 2000)

        this.on('unmount', () => {
            clearTimeout(timers[0])
            clearTimeout(timers[1])
            clearTimeout(timers[2])
        })

        const init = async () => {
            await UserService.current.setup()
            this.setMetaData('onboardingFinished', true)
            const time = setInterval(() => {
                if (this.finish) {
                    clearInterval(time)
                    globalRouter.page('/app')
                }
            }, 1000)
        }

        init()
});