import riot from 'riot'
 riot.tag2('td-question', '<div class="td-question"> <div class="td-question-number"> {number} </div> <div class="td-question-name"> {item.title} </div> <td-input model="slider" type="slider" slider-options="{{                 min: 0,                 max: 100,                 from: 0,                 grid: true,                 postfix: \'%\',                 grid_num: 3,                 prettify: stages,                 _count: 4,                 onChange: onChange,                 onStart: onStart             }}"></td-input> </div>', '', '', function(opts) {
        this.number = ("0" + opts.index).slice(-2)
        this.item = opts.item || this.item

        this.on('update', () => {
            this.number = ("0" + opts.index).slice(-2)
            this.item =  opts.item || this.item
        })

        const self = this

        this.onStart = function(e) {
            self.item.value = self.item.value !== undefined ? self.item.value : (self.item.reverse ? 100 : 0)

            e.from = (
                self.item.reverse ? 100 - self.item.value : self.item.value
            )

            self.item.valueWeight = self.item.value * self.item.weight
        }

        this.stages = function(v) {
            if (!this._count) return v
            this._count--;

            if(v == 100) return "Concordo Totalmente"
            if(v >= 66) return "Concordo em Partes"
            if(v >= 33) return "Discordo em Partes"
            if(v >= 0) return "Discordo Totalmente"
        }

        this.onChange = function(e) {
            const v = e.from

            self.item.value = self.item.reverse ? 100 - v : v
            self.item.valueWeight = self.item.value * self.item.weight

            e.slider.find('.irs-grid-text').removeClass('active')

            if(v >= 83) return e.slider.find('.irs-grid-text.js-grid-text-3').addClass('active')
            if(v >= 50) return e.slider.find('.irs-grid-text.js-grid-text-2').addClass('active')
            if(v >= 17) return e.slider.find('.irs-grid-text.js-grid-text-1').addClass('active')
            if(v >= 0) return e.slider.find('.irs-grid-text.js-grid-text-0').addClass('active')
        }
});