import riot from 'riot'
 import './support.scss'
import { SupportService } from '@common/services'
import store from '@td-premium/store'
riot.tag2('td-support', '<div class="support-container"> <div class="support-chat" if="{message}"> <div class="title">Obrigado por sua mensagem.</div> <div class="subtitle">Entraremos em contato pelo e-mail indicado por você.</div> <div class="subtitle">Até breve!</div> <div class="text-center"> <button onclick="{newMessage}" class="btn btn-hero">Nova Mensagem</button> </div> </div> <div class="support-chat" if="{!message}"> <div class="title">Como podemos ajudar você?</div> <div class="subtitle">Deixe abaixo sua mensagem para nossa equipe.</div> <form onsubmit="{onsubmit}"> <td-input model="data.user_name" placeholder="Nome"></td-input> <td-input model="data.user_email" placeholder="Email"></td-input> <td-select placeholder="Sua Solicitação" model="data.solicitation"> <option value="Aumentar meu plano">Aumentar meu plano</option> <option value="Problemas no sistema">Problemas no sistema</option> <option value="Questões financeiras">Questões financeiras</option> <option value="Outro assunto">Outro assunto</option> </td-select> <td-textarea model="data.message" placeholder="Sua mensagem"></td-textarea> <div class="text-center"> <button class="btn btn-hero">Enviar</button> </div> </form> </div> </div>', '', '', function(opts) {

		const user = store.get('session').user

		this.data = {
			user_name: user.name,
			user_email: user.email
		}

		this.newMessage = (e) => {
			e.preventDefault()
			this.show = true
			this.message = false
		}

		this.onsubmit = async (e) => {
			e.preventDefault()
			try {
				await SupportService.post(this.data)
				this.update({message: true, data: {}})
			} catch (error) {
				this.update({message, error})
			}
		}
});