import riot from 'riot'
 import './answer-slider.scss'
riot.tag2('td-answer-slider', '<td-input ref="slider" model="slider" type="slider" slider-options="{{             min: 0,             max: 100,             from: 50,             grid: true,             grid_num: 3,             prettify: stages,             onChange: onChange,             onStart: onStart,             onFinish: onFinish,             _count: 4,             _startCount: 2,             _start: false         }}"></td-input>', '', '', function(opts) {

        this.data = this.getMetaData('score.' + opts.dimension + '.' + opts.dimension+'_'+opts.index, {})
        const self = this

        this.stages = function(v) {

            setTimeout(() => {
                $(self.refs.slider.root).find('.irs-min').html('0%')
                $(self.refs.slider.root).find('.irs-max').html('100%')
            })

            if (this._start && !this._startCount && this._value === null) return 'Arraste para escolher'

            if (this._start) this._startCount--

            if (!this._count) return v+'%';
            this._count--

            if(v == 100) return "Concordo totalmente"
            if(v >= 66) return "Concordo em partes"
            if(v >= 33) return "Discordo em partes"
            if(v >= 0) return "Discordo totalmente"

        }

        this.onStart = function(e) {
            this._value = self.data.value >= 0 ? (opts.reverse ? 100 - self.data.value : self.data.value) : null
            this._value = null
            this._start = true

            if (self.data.value >= 0)
                e.from = self.data.value
        }

        this.onChange = function(e) {
            const v = e.from

            e.slider.find('.irs-grid-text').removeClass('active')
            this._value = opts.reverse ? 100 - v : v
            self.data.value = this._value

            if(v >= 83) return e.slider.find('.irs-grid-text.js-grid-text-3').addClass('active')
            if(v >= 50) return e.slider.find('.irs-grid-text.js-grid-text-2').addClass('active')
            if(v >= 17) return e.slider.find('.irs-grid-text.js-grid-text-1').addClass('active')
            if(v >= 0) return e.slider.find('.irs-grid-text.js-grid-text-0').addClass('active')
        }

        this.onFinish = function() {

        }

});