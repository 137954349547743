import riot from 'riot'
 import pathToRegexp from 'path-to-regexp'
riot.tag2('breadcrumb', '<virtual each="{item in items}"> <a href="{item.path}" if="{!item.last}">{item.title}</a> <span class="active" if="{item.last}">{item.title}</span> <span class="separator" if="{!item.last}">▸</span> </virtual>', '', '', function(opts) {
        const routers = Object.keys(window.routers)
        const items = []

        let findPath = true

        routers.forEach((path) => {

            const regexp = pathToRegexp(path.replace(/\/$/ig, ''))

            if (regexp.exec(ctx.path) && findPath) {
                let route = window.routers[path]
                findPath = false

                route = !route.opts.dataTitle ? route.parentRoute : route

                items.push({
                    last: true,
                    title: opts.last || route.opts.dataTitle
                })

                let parentRoute = route.parentRoute

                while(parentRoute != null) {
                    if (!parentRoute.opts.dataTitle)
                        break;
                    items.push({
                        title: parentRoute.opts.dataTitle,
                        path: parentRoute.fullPath.replace('/', '')
                    })
                    parentRoute = parentRoute.parentRoute
                }
            }
        })
        this.items = items.reverse()
});