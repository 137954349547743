import riot from 'riot'
 import avatarDefault from '@common/assets/media/avatars/avatar0.jpg'
import store from '@td-premium/store'
import pathToRegexp from 'path-to-regexp'
import { UserService, UploadService } from '@common/services'
riot.tag2('td-config-menu', '<div class="menu-admin"> <div class="user-group" if="{user}"> <input style=" width: 0px; height: 0px; display: none; visibility: hidden; " ref="inputUpload" type="file"> <div class="photo" title="Alterar Foto" onclick="{changeAvatar}"> <span class="btn-change-photo"> <i class="fa fa-camera"></i> </span> <div class="avatar"> <img riot-src="{user.avatar}" alt=""> </div> </div> <div class="user-name my-20"> {user.name} </div> </div> <virtual each="{item in menu}"> <a ref="menu" if="{item.type === \'link\' && !item.hide}" href="{\'configuracao/\' + item.href}" class="menu-item"> <i class="{item.icon}"></i> {item.label} </a> <span if="{item.type === \'separator\' && !item.hide}" class="menu-subtitle"> {item.label} </span> </virtual> </div>', '', '', function(opts) {

        this.user = this.currentUser()
        this.user.avatar = this.user.avatar || avatarDefault

        this.menu = [
            {
                type: 'separator',
                label: 'Configurações'
            },
            {
                type: 'link',
                label: 'Perfil',
                href: 'perfil',
                icon: 'si si-user'
            },
            {
                type: 'link',
                label: 'Usuários',
                href: 'usuario',
                icon: 'si si-users',
                hide: !(this.isManagerOrAadmin() && this.isMultiUsers())
            },
            {
                type: 'link',
                label: 'Departamentos',
                href: 'departamento',
                icon: 'si si-directions',
                hide: !(this.isAdmin() && this.isMultiUsers())
            },
            {
                type: 'separator',
                label: 'Financeiro',
                hide: !(this.isOwnerOrAdmin() && this.isMultiUsers())
            },
            {
                type: 'link',
                label: 'Assinatura',
                href: 'assinatura',
                icon: 'si si-wallet',
                hide: !(this.isOwnerOrAdmin() || this.isAgency())
            }
        ]

        this.setActive = (path) => {
            if (!this.refs.menu) return
            const menuItems = Array.isArray(this.refs.menu) ? this.refs.menu : [this.refs.menu]
            menuItems.forEach((a, index) => {

                $(a).removeClass('active')

                const pathname = a.pathname.replace('/app', '')

                if (pathname === '/configuracao/' && path !== '/configuracao/') return

                if(pathname && path.indexOf(pathname) === 0){
                    $(a).addClass('active')
                }
            })
        }

        this.on('mount', () => {

            const input = this.refs.inputUpload

            input
            .addEventListener('change', async (e) => {

                var file = input.files[0]
                var imageType = /image.*/

                if (!file) return

                if (file.type.match(imageType)) {
                    var reader = new FileReader();

                    reader.onload = (e) => {
                        this.value = reader.result
                        this.update();
                    }

                    reader.readAsDataURL(file)
                    this.loading = true
                    try {
                        const result = await UploadService.image(file, opts.data)
                        await UserService.current.put({
                            avatar: result[0]
                        })

                        this.user.avatar = result[0]

                        let session = store.get('session') || {}
                        session.user = this.user
                        store.set('session', session)

                        this.app.update()

                    } catch (error) {}

                    setTimeout(() => {
                        this.loading = false
                        this.update()
                    }, 1000)
                }

            })
        })
        this.changeAvatar = (e) => {
            e.preventDefault()
            this.refs.inputUpload.value = ''
            this.refs.inputUpload.click()
        }

        riot.mixin('configMenu', {configMenu: this}, true)

});