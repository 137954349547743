import riot from 'riot'
 import './content.scss'
import { ContentService } from '@common/services'
riot.tag2('td-content', '<div class="content content-top"> <div class="row single-content" if="{!loading}"> <div class="col main-content" if="{!blocked}"> <td-player autoplay="{true}" content="{content}" next="{nextContent}"></td-player> <div class="row"> <div class="col"> <h4 class="mt-20"> {content.title} </h4> </div> <div class="col-1 d-flex justify-content-center align-items-center"> <content-button-mylist content="{content}"></content-button-mylist> </div> </div> <div class="summary"> {content.summary} </div> <div class="body"> <raw html="{content.content}"></raw> </div> <div class="comments"> <h3 class="title">Comentários</h3> <div id="disqus_thread"></div> </div> </div> <div class="col-12 blocked-content" if="{blocked}"> <h3 class="title"> Conteúdo indisponível nesse plano. </h3> <a href="assinar" class="btn btn-primary">Alterar plano</a> </div> <div class="col-3 recomendations" if="{!blocked}"> <h4 class="title">Veja também</h4> <td-video-card each="{item in contents}"></td-video-card> </div> </div> </div>', '', '', function(opts) {

        this.blocked = false
        this.loading = true

        this.content = {}
        this.nextContent = {}
        this.contents = []
        const MAX_RELATED_CONTENT = 5

        const loadContent = async () => {
            let content
            try {
                content = await ContentService.findOne({slug: opts.slug})
            } catch (e) {
                if (e && e.blocked) {
                    return this.update({blocked: true, loading: false})
                }
                return globalRouter.page('/app/404');
            }

            this.update({ content: content, loading: false })

            let contents = await ContentService.find({
                _id__nin: content._id,
                createdAt__gte: content.createdAt,
                groups__in: content.groups.join(','),
                limit: MAX_RELATED_CONTENT
            })

            if ( contents.length < MAX_RELATED_CONTENT) {
                const moreContents = await ContentService.find({
                    _id__nin: [content._id].concat(contents.map((o) => o._id)).join(','),
                    limit: MAX_RELATED_CONTENT - contents.length,
                    groups__in: content.groups.join(',')
                })
                contents = contents.concat(moreContents)
            }

            this.update({ contents, nextContent: contents[0] })
        }

        loadContent()

        var disqus_config = function () {
            this.page.url = window.location.href;
            this.page.identifier = window.location.pathname;
        };
        (function() {
        var d = document, s = d.createElement('script');
        s.src = 'https://td-premium-transformacao-digital.disqus.com/embed.js';
        s.setAttribute('data-timestamp', +new Date());
        (d.head || d.body).appendChild(s);
        })();
});