import riot from 'riot'
 import './external.scss'
import { AuthService } from '@common/services'
riot.tag2('td-reset-password', '<div class="row mx-0 justify-content-center"> <div class="hero-static col-lg-6 col-xl-4 d-flex align-items-center"> <div class="content content-full overflow-hidden"> <div class="py-50 text-center"> <a href=""> <img riot-src="{app.imageLogo}" height="{app.imageSize}"> </a> </div> <form onsubmit="{onsubmit}" if="{!loadingPage}"> <td-input label="Nova Senha" placeholder="Nova Senha" type="password" error="error.password" model="data.password"></td-input> <td-input label="Confirmar Nova Senha" placeholder="Confirmar Nova Senha" type="password" error="error.confirmPassword" model="data.confirmPassword"></td-input> <div if="{typeof error === \'string\'}" class="text-danger"> {error} </div> <button type="submit" class="btn btn-block btn-hero btn-noborder btn-rounded btn-alt-primary"> <i if="{loading}" class="fa fa-spinner fa-spin"></i> <span if="{!loading}"> Alterar Senha </span> </button> </form> <div if="{loadingPage && error}" class="text-center text-danger py-20"> {error} </div> <div class="text-center py-20"> <a href="login">Clique aqui para entrar</a> </div> </div> </div> </div>', '', '', function(opts) {


        this.loadingPage = true
        const checkUrl = async () => {
            try {
                const query = queryString()
                await AuthService.checkResetPassword({token: query._})
                this.update({loadingPage: false})
            } catch (error) {
                this.update({error: error.message})
            }

        }

        checkUrl()

        this.data = {}

        this.onsubmit = async (e) => {
            e.preventDefault()

            if (this.loading)
                return

            this.loading = true

            try {
                const query = queryString()
                this.data.token = query._
                await AuthService.resetPassword(this.data)

                $success({
                    toast: false,
                    position: 'center',
                    title: 'Sucesso!',
                    html: 'Senha alterada com sucesso!',
                    showConfirmButton: true,
                    timer: null
                })
                .then((result) => {
                    globalRouter.page('/app/login')
                })

            } catch (error) {
                this.update({ loading: false, error: error.message })
            }
        }
});