import riot from 'riot'
 import moment from 'moment'
import { CustomerService, SubscriptionService } from '@common/services'
riot.tag2('profile-plan', '<div class="mb-30" if="{subscription}"> <h3 class="profile-section-title">Gerenciar Plano</h3> <button if="{subscription.active}" onclick="{cancelSubscription}" class="btn btn-hero btn-white mr-10"> Cancelar Assinatura </button> <a href="assinar" if="{!subscription.active}" class="btn btn-hero btn-white mr-10"> Nova Assinatura </a> <a href="assinar" if="{subscription.active}" class="btn btn-hero btn-white mr-10"> Alterar Plano </a> </div> <div class="mb-30" if="{bills}"> <h3 class="profile-section-title">Faturas</h3> <table class="table table-striped table-vcenter"> <thead class="bg-gradient"> <tr> <th>Plano</th> <th>Período</th> <th>Valor</th> </tr> </thead> <tbody> <tr each="{bill in bills}"> <td> {bill.plan} </td> <td> Período #{bill.period.cycle} ({bill.period.start} - {bill.period.end}) </td> <td> R$ {bill.price} </td> </tr> </tbody> </table> </div>', '', '', function(opts) {

        this.subscription = null

        const loadSubscription = async () => {
            const subscription = await SubscriptionService.current.get()
            this.update({
                subscription: {
                    active: subscription.active,
                    planName: subscription.plan.name,
                    nextBillingAt: moment(
                        new Date(subscription.nextBillingAt)
                    ).format('L'),
                    nextBillingPrice: parseFloat(
                        subscription.nextBillingPrice
                    ).toLocaleString('pt-BR', {minimumFractionDigits: 2}),
                    expireAt: moment(
                        new Date(subscription.expireAt)
                    ).format('L')
                }
            })
        }

        loadSubscription()

        this.bills = null

        const loadBills = async () => {
            let bills = await CustomerService.current.bills()
            bills = bills.map((bill) => {
                return {
                    plan: bill.plan.name.replace('Premium - ', ''),
                    period: {
                        cycle: bill.period.cycle,
                        start: moment(
                            new Date(bill.period.start_at)
                        ).format('L'),
                        end: moment(
                            new Date(bill.period.end_at)
                        ).format('L')
                    },
                    price: parseFloat(
                        bill.price
                    ).toLocaleString('pt-BR', {minimumFractionDigits: 2})
                }
            })
            this.update({ bills })
        }

        loadBills()

        this.cancelSubscription = async () => {

            const result = await $confirm({
                html: `
                    Tem certeza que deseja cancelar sua assinatura?
                    O cancelamento passa a vigorar ao fim deste ciclo de cobrança:
                    <strong>${this.subscription.nextBillingAt}</strong>.
                `
            })

            if ( result.value ) {
                try {

                    this.subscription.active = false
                    this.update()

                    const data = await SubscriptionService.current.cancel()

                } catch (error) { console.log(error) }
            }
        }

});