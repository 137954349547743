import riot from 'riot'
 import './department.scss'
import { DepartmentService } from '@common/services'
riot.tag2('td-config-department-form', '<h1 class="main-title"> <div class="d-flex align-items-center" if="{opts.edit}"> <breadcrumb if="{loadingData}" last="loading..."></breadcrumb> <breadcrumb if="{!loadingData}" last="{data.name}"></breadcrumb> </div> <div class="d-flex align-items-center" if="{!opts.edit}"> <breadcrumb></breadcrumb> </div> </h1> <div class="row d-flex justify-content-center"> <div class="col-6"> <form onsubmit="{onSubmit}"> <td-input required="true" label="Nome" onblur="{changeSlugIfEmpty}" model="data.name" error="error.name"></td-input> <div if="{!loading}" class="form-group d-flex mt-40"> <button type="submit" class="btn btn-hero"> Salvar </button> <a href="configuracao/departamento" class="btn btn-hero btn-white">Cancelar</a> </div> <div if="{loading}" class="form-group mt-40"> <span> <i class="fa fa-spinner fa-spin"></i> Salvando... </span> </div> </form> </div> </div>', '', '', function(opts) {

        this.service = DepartmentService

        this.messages = {
            create: () => {
                return  'Departamento cadastrado com sucesso!'
            },
            update: () => {
                return 'Departamento atualizado com sucesso!'
            }
        }

        this.formatSlug = (e) => {
            this.data.slug = kebabCase(e.target.value)
            this.update({data: this.data})
        }

        this.mixin('baseForm')
});