import riot from 'riot'
 import { AuthService, VerticalService } from '@common/services'
import imageLogo from '@common/assets/media/images/logo.png'
import store from '@td-premium/store'
import './app.scss'
import pathToRegexp from 'path-to-regexp'
riot.tag2('td-app', '<virtal if="{initialized}"> <div if="{!connect && (loggedIn && !isPathExeternal())}" id="page-container" class="{classes}"> <div class="loading-bar"></div> <td-sidebar if="{!hideSidebar()}" ref="sidebar"></td-sidebar> <td-header if="{!hideHeader()}" ref="header"></td-header> <main id="main-container"> <td-routers></td-routers> <div if="{!hideFooter()}" class="copyright">© {vertical.name} 2020. Todos os direitos reservados.</div> </main> </div> <div if="{!connect && (!loggedIn || isPathExeternal())}" id="page-container" class="main-content-boxed"> <main id="main-container"> <router base="/app" resolve="{state}"> <route path="/"> <td-login></td-login> </route> <route path="/login"> <td-login></td-login> </route> <route path="/esqueceu-a-senha"> <td-forgot-password></td-forgot-password> </route> <route path="/redefinir-senha"> <td-reset-password></td-reset-password> </route> <route path="/invitation"> <td-invitation></td-invitation> </route> <route path="/reward"> <td-reward></td-reward> </route> <route path="/invitation/assinar"> <td-invitation assinar="{true}"></td-invitation> </route> <route path="/invitation/curso"> <td-invitation curso="{true}"></td-invitation> </route> <route path="/invitation/grupo"> <td-invitation group="{true}"></td-invitation> </route> <route path="/cadastro" abstract="{true}"> <td-signup></td-signup> </route> <route path="/certified/:code"> <td-certified code="{ctx.params.code}"></td-certified> </route> <route path="/free-register/:reservation"> <td-free-register reservation="{ctx.params.reservation}"></td-free-register> </route> </router> </main> </div> </virtal>', '', '', function(opts) {


        const query = window.queryString()

        this.initialized = false

        const init = async () => {
            this.vertical = await VerticalService.current.get()

            if (this.vertical.dbName == 'money_play') {
                (function(h,o,t,j,a,r){
                    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                    h._hjSettings={hjid:1773131,hjsv:6};
                    a=o.getElementsByTagName('head')[0];
                    r=o.createElement('script');r.async=1;
                    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                    a.appendChild(r);
                })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
            }

            this.imageLogo = this.vertical.logo ? this.vertical.logo.image || imageLogo : imageLogo
            this.imageSize = this.vertical.logo ? this.vertical.logo.size || '15px' : '15px'

            document.title = this.vertical.name

            const menu = (this.vertical.menu||[]).map((o) => {
                return {
                    type: 'menu',
                    label: o.label,
                    href: o.url
                }
            })

            this.menu = [
                ...menu,
                {
                    type: 'header',
                    label: 'Dashboard',
                    hide: () => {
                        return !(this.isMultiUsers() && this.isManagerOrAadmin())
                    }
                },

                {
                    type: 'menu',
                    label: 'Relatório individual',
                    href: 'dashboard/user',
                    hide: () => {
                        return !this.isMultiUsers()
                    }
                },
                {
                    type: 'menu',
                    label: 'Visão Geral',
                    href: 'dashboard',
                    hide: () => {
                        return !(this.isMultiUsers() && this.isManagerOrAadmin())
                    }
                }

            ]

            this.menuHeader = [
                ...menu,
                {
                    type: 'submenu',
                    label: 'Dashboard',
                    href: 'dashboard',
                    hide: () => {
                        return !this.isMultiUsers()
                    },
                    submenu: [
                        {
                            type: 'menu',
                            label: 'Relatório individual',
                            href: 'dashboard/user'
                        },
                        {
                            type: 'menu',
                            label: 'Visão Geral',
                            href: 'dashboard',
                            hide: () => {
                                return !this.isManagerOrAadmin()
                            }
                        }
                    ]
                }
            ]

            this.update({initialized: true})
        }

        init()

        if (query && query.connect) {
            this.connect = true
            const connect = async () => {
                store.set('loggedIn', true)
                store.set('token', query.connect)
                store.set('session', {user: {}})
                await AuthService.check()
                window.location = '/app'
                this.update()
            }
            connect()
        }

        this.externalRouters = [
            '/login',
            '/esqueceu-a-senha',
            '/redefinir-senha',
            '/cadastro',
            '/certified',
            '/invitation',
            '/invitation/assinar',
            '/invitation/curso',
            '/reward',
            '/free-register/:reservation'
        ]

        this.myList = []

        this.isPathExeternal = () => {
            return this.externalRouters.filter((url) => {
                const regex = pathToRegexp(url)
                return regex.exec(window.location.pathname.replace(/^\/app/, '').replace(/\/$/g, ''))
            }).length
        }

        this.hideHeaderPaths = [
            '/onboarding(.*)',
            '/score',
            '/score/finalizar',
            '/score/:dimension/:index'
        ]

        this.hideHeader = () => {
            return this.hideHeaderPaths.filter((url) => {
                const regex = pathToRegexp(url)
                return regex.exec(window.location.pathname.replace(/^\/app/, '').replace(/\/$/g, ''))
            }).length
        }

        this.hideSidebarPaths = [
            '/onboarding(.*)',
            '/score',
            '/score/finalizar',
            '/score/:dimension/:index'
        ]

        this.hideSidebar = () => {
            return this.hideSidebarPaths.filter((url) => {
                const regex = pathToRegexp(url)
                return regex.exec(window.location.pathname.replace(/^\/app/, '').replace(/\/$/g, ''))
            }).length
        }

        this.hideFooterPaths = [
            '/onboarding(.*)',
            '/score',
            '/score/:dimension/:index'
        ]

        this.hideFooter = () => {
            return this.hideFooterPaths.filter((url) => {
                const regex = pathToRegexp(url)
                return regex.exec(window.location.pathname.replace(/^\/app/, '').replace(/\/$/g, ''))
            }).length
        }

        const checkStore = () => {
            this.loggedIn = store.get('loggedIn')
        }

        this.on('update', () => {
            checkStore()

            if (this.loggedIn && window.FS) {
                const session = store.get('session')

                window.FS.identify(session.user._id, {
                    displayName: session.user.name,
                    email: session.user.email
                })

                window.FS.setUserVars({
                    customerId: session.user.customer._id,
                    customer: session.user.customer.name
                });
            }
        })

        checkStore()

        this.on('updated', () => {
            Codebase.init()
        })

        this.on('mount', () => {
            Codebase.init()
        })

        this.logout = async (e) => {
            if (e) e.preventDefault()
            await AuthService.logout()
            window.location = '/app/login'
        }

        this.classes = {
            'sidebar-o': false,
            'sidebar-inverse': true,
            'sidebar-mini': true,
            'enable-page-overlay': true,
            'side-scroll': true,
            'main-content-boxed': true,
            'side-trans-enabled': true,
            'page-header-fixed': true,
            'page-header-glass': true,
            'page-header-inverse': true
        }

        $('body').on('scroll', (e) => {
            if (e.target.scrollTop > 10){
                $('#page-container').addClass('page-header-scroll')
            } else {
                $('#page-container').removeClass('page-header-scroll')
            }
        })

        riot.mixin('app', {app: this}, true)

        this.state = async (ctx) => {
            if (!this.isPathExeternal()) {
               window.location = '/app/login'
               return false
            }

            return true
        }

});