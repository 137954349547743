import riot from 'riot'
 import { ReportService } from '@common/services'
riot.tag2('td-dashboard-answered-diagnosis', '<div class="dashboard-card"> <div class="dashboard-card__header"> <div class="dashboard-card__header-title">Maturidade digital</div> <div class="dashboard-card__header-expand"> <i class="si si-size-fullscreen"></i> </div> </div> <div class="dashboard-card__content dashboard-card__content-row {expanded ? \'card-expanded\': \'\'}"> <div if="{data}" class="dashboard-card__content-left dashboard-card__content-radius"> <td-chart-circle data="{data}" class="chart-color__red {expanded ? \'chart-size__large\':\'\'}"></td-chart-circle> <p>Responderam o diagnóstico</p> </div> <div class="dashboard-card__content-right align-items-start pt-30" if="{expanded}"> <div class="w-100 d-flex flex-column align-items-start justify-content-start py-10 px-30"> <div class="dashboard-card__filter mt-30"> <span class="dashboard-card__filter-label">Filtrar por</span> <div class="dashboard-card__filter-content"> <td-select class="select-small" onchange="{onChangeStatus}" onstart="{onChangeStatus}" model="filterStatus"> <option riot-value="{1}">Responderam</option> <option riot-value="{0}">Não Responderam</option> </td-select> </div> </div> <div class="row data-list__header"> <div class="col-8 dark-color">Usuários</div> <div class="col-4 dark-color d-flex justify-content-end">Data do cadastro</div> </div> </div> <div ref="scroll" class="dashboard-card__content-inner align-items-start justify-content-start py-10 px-30 mb-20"> <div class="data-list"> <div each="{user in list}" class="row data-list__item-selection data-list__item" model-loop> <div class="col-8"> <span class="ml-5"> {user.name} </span> </div> <div class="col-4 d-flex justify-content-end align-items-center"> {formatDate(user.createdAt, \'ll\')} </div> </div> </div> </div> </div> </div> </div>', '', '', function(opts) {

        this.filterStatus = 1
        this.list = []

		const loadData = async (query = {}) => {
            this._chartQueryString = JSON.stringify(opts.chartQuery)
            this.query = { ...this.query, ...opts.chartQuery, ...query }
			const data = await ReportService.getType('answered-diagnosis-count', {...opts.chartQuery, ...query})
			this.update({ data })
		}

        loadData()

        this.on('update', () => {
            if ( this._chartQueryString != JSON.stringify(opts.chartQuery)) {
                this.data = null
                loadData()
            }
        })

        this.mixin('infiniteScroll')

        const loadDataList = async (query = {}) => {
            this.query = { ...this.query, ...opts.chartQuery, ...query }
            const list = await ReportService.getType('answered-diagnosis-list', this.query)
            return list
		}

        this.on('nextPage', async () => {
            const list = await loadDataList({page: this._page})

            if (list.length !== this.query.limit ) {
                this._initScroll = false
            }

            list.map((item) => {
                this.list.push(item)
            })

            this.update({ _loading: false })
        })

        this.on('open-card', async () => {
            this.list = []

            this.query = {
                limit: 20,
                status: true
            }

            this.update( { expanded: true } )

            const list = await loadDataList()

            this.update( { list } )

            setTimeout(() => {
                this.infiniteScroll(this.refs.scroll)
            })
        })

        this.on('close-card', () => {
            this.destroyInfiniteScroll(this.refs.scroll)
            this.update( { expanded: false } )
        })

        this.onChangeStatus = async (status) => {
            this.query.status = Number(status)
            const list = await loadDataList()
            this.update({ list })
            this.resetInfiniteScroll()
        }

});