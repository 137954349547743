import riot from 'riot'
 riot.tag2('td-questions-cultura-e-lideranca', '<div class="onboarding"> <div class="header"> <div class="col"> <div class="py-30 px-5 logo"> <img riot-src="{app.imageLogo}" height="{app.imageSize}"> </div> </div> </div> <div class="content temas"> <div class="col-6 tema-big"> <h1 class="title">CULTURA E LIDERANÇA</h1> <p>Para que a transformação digital aconteça de maneira efetiva é necessário que as pessoas estejam abertas a uma nova forma de pensar, criando uma cultura digital e formando líderes dispostos a encarar os desafios que surgem nesse novo contexto.</p> <p>Vamos começar analisando questões relacionadas ao seu mindset, aspectos de liderança e inovação.</p> <a href="onboarding" class="back-button btn text-white">Voltar</a> </div> <div class="col-6 question-list"> <td-question each="{item, index in questions}" index="{index+1}"></td-question> <button onclick="{save}" class="btn btn-hero">Avançar</button> </div> </div> </div>', '', '', function(opts) {


        const values = this.getMetaData('score.cultura_e_lideranca', {})

        this.save = async () => {
            this.saveAnswers()
            globalRouter.page('/app/onboarding/perguntas/agilidade-operacional')
        }

        this.questions = [
            { field: 'cultura_e_lideranca_1', weight: 1, tag: 'Mindset', title: 'Estou convencido que o processo de transformação digital é um efeito global e impacta não somente empresas, mas o cotidiano das pessoas.' },
            { field: 'cultura_e_lideranca_2', weight: 2, tag: 'Mindset', title: 'Tenho consciência dos fatores que envolvem a transformação digital quando falamos em ferramentas, processos e pessoas.' },
            { field: 'cultura_e_lideranca_3', weight: 2, reverse: true, tag: 'Mindset', title: 'Percebo que as habilidades necessárias para atuar na transformação digital pertecem somente à geração dos Millennials.' },
            { field: 'cultura_e_lideranca_4', weight: 1, reverse: true, tag: 'Inovação', title: 'Prefiro ser reconhecido como um profissional que traz segurança por seguir processos lineares do que como um agente de mudanças disposto a testar novos modelos com frequencia.' },
            { field: 'cultura_e_lideranca_5', weight: 3, tag: 'Liderança', title: 'Identifico com facilidade processos críticos do meu ambiente de trabalho e busco alternativas viáveis em conjunto com a equipe.' },
            { field: 'cultura_e_lideranca_6', weight: 2, tag: 'Mindset', title: 'Costumo interagir com meus colegas de trabalho a fim de compartilhar conhecimentos e favorecer um ambiente de crescimento colaborativo.' },
            { field: 'cultura_e_lideranca_7', weight: 3, reverse: true, tag: 'Liderança', title: 'Na posição de líder, optaria por desenhar estratégias de acordo com padrões analógicos e de conhecimento do time, ao invés de provar modelos digitais ainda não habituais.' },
        ]

        this.mixin('questions')
        this.prepare('cultura_e_lideranca')

});