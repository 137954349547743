import riot from 'riot'
 import './answer-options.scss'
riot.tag2('td-answer-options', '<virtual if="{!opts.multiple}"> <label each="{option, key in opts.options}" for="radio-{opts.dimension}-{opts.index}-{key}" class="btn-answer radio"> <input type="radio" checked="{data.options[key] && data.options[key].checked ? \'checked\' : undefined}" onchange="{onchange(option,key)}" name="radio" id="radio-{opts.dimension}-{opts.index}-{key}"> <span> {option.label} </span> <svg viewbox="0 0 200 200"> <circle cx="100" cy="100" r="75" fill="#ffff"></circle> </svg> </label> </virtual> <virtual if="{opts.multiple}"> <label each="{option, key in opts.options}" for="checkbox-{opts.dimension}-{opts.index}-{key}" class="btn-answer checkbox"> <input type="checkbox" checked="{data.options[key] && data.options[key].checked ? \'checked\' : undefined}" onchange="{onchange(option,key)}" name="checkbox-1" id="checkbox-{opts.dimension}-{opts.index}-{key}"> <span> {option.label} </span> <svg viewbox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"> <path d="M 10 60 L 40 86 L 100 20" stroke="#ffff" stroke-dasharray="140" stroke-dashoffset="140"></path> </svg> </label> </virtual>', '', '', function(opts) {
        this.data = this.getMetaData('score.' + opts.dimension + '.' + opts.dimension+'_'+opts.index, {})

        this.data.options = this.data.options || {}

        if (opts.multiple) {
            this.data.value = 0
        }

        this.onchange = (option,key) => {
            return (e) => {

                let value = 0

                opts.options.forEach((item, key) => {
                    let node

                    if (opts.multiple) {
                        node = this.root.querySelector(`#checkbox-${opts.dimension}-${opts.index}-${key}`)
                    } else {
                        node = this.root.querySelector(`#radio-${opts.dimension}-${opts.index}-${key}`)
                    }

                    this.data.options[key] = {
                        checked: node.checked,
                        correct: item.correct
                    }

                    if (this.data.options[key].checked && item.correct) {
                        value += 100
                    }

                    if (!this.data.options[key].checked && !item.correct) {
                        value += 100
                    }
                })

                this.data.value = Math.floor((value / opts.options.length))
            }
        }
});