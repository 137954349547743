import riot from 'riot'
 import dimensions from './dimensions'
riot.tag2('td-score-list', '<div class="content content-top course-list"> <div class="category-menu"> <h3 class="title">Diagnóstico</h3> </div> <div class="row" if="{dimensions.length}"> <div each="{dimension in dimensions}" class="col-md-4 mb-30"> <div class="td-card td-score-card"> <div class="td-card-img {dimension.bgClass} d-flex flex-column justify-content-center align-items-center"> <div class="td-card-title mt-30">{dimension.title}</div> <div class="td-card-sub-title"> <div class="hours"> {getNumberOfAnswers(dimension)} </div> </div> </div> <div class="td-card-content"> <div class="td-card-desc"> {dimension.desc} </div> <div class="action-group"> <a if="{!isFinish(dimension)}" href="score/{dimension.router}/0" class="btn btn-hero {dimension.bgClass}"> {getButtonText(dimension)} </a> <div if="{isFinish(dimension)}" class="btn btn-hero isfinish"> Concluído <i class="icon icon-check-line"></i> </div> </div> </div> </div> </div> </div> </div>', '', '', function(opts) {

        this.dimensions = dimensions.filter((o) => {
            if (o.hideInList) return false

            if (o.type == 'feedback' && !this.isMultiUsers()) return false

            return true
        })

        const getNumberOfAnswers = (dimension) => {
            const list = this.getMetaData('score.' + dimension.field)
            let count = 0

            if (list) {
                Object.keys(list).forEach((k) => {
                    if (list[k].value != null && list[k].value != undefined) count++
                })
            }

            return count
        }

        this.getNumberOfAnswers = (dimension) => {
            return getNumberOfAnswers(dimension) + ' de ' + (dimension.questions.length - 1)
        }

        this.getButtonText = (dimension) => {
            const count = getNumberOfAnswers(dimension)

            if (!count) return 'Iniciar Diagnóstico'
            return 'Continuar'
        }

        this.isFinish = (dimension) => {
            const count = getNumberOfAnswers(dimension)
            const max = (dimension.questions.length - 1)

            return count == max
        }

});