import riot from 'riot'
 import './track.scss'
import { ContentService, CategoryService } from '@common/services'
riot.tag2('td-track', '<div class="content content-top"> <div class="row single-content" if="{!loading}"> <div class="col main-content" if="{!blocked}"> <td-player content="{content}" next="{nextContent}" next-link="{nextVideoUrl}"></td-player> <div class="row"> <div class="col"> <h4 class="mt-20"> {content.title} </h4> </div> <div class="col-1 d-flex justify-content-center align-items-center"> <td-content-mylist content="{content}"></td-content-mylist> </div> </div> <div class="summary"> {content.summary} </div> <div class="body"> <raw html="{content.content}"></raw> </div> <div class="comments"> <h3 class="title">Comentários</h3> <div id="disqus_thread"></div> </div> </div> <div class="col-12 blocked-content" if="{blocked}"> <h3 class="title"> Conteúdo indisponível nesse plano. </h3> <a href="assinar" class="btn btn-primary">Alterar plano</a> </div> <div class="col-3 recomendations" if="{!blocked}"> <h4 class="title">Veja também</h4> <td-video-card link="{videoUrl}" each="{item in contents}"></td-video-card> </div> </div> </div>', '', '', function(opts) {

        this.blocked = false
        this.loading = true

        this.content = {}
        this.nextContent = {}
        this.contents = []
        const MAX_RELATED_CONTENT = 5

        const loadContent = async () => {
            let content
            try {
                content = await ContentService.findOne({slug: opts.contentSlug})
            } catch (e) {
                if (e && e.blocked) {
                    return this.update({blocked: true, loading: false})
                }
                return globalRouter.page('/app/404');
            }

            this.update({ content: content, loading: false })

            let track = await CategoryService.findOne({slug: opts.trackSlug})

            let add = false
            let count = 0

            let contents = []

            let _ids = track.contents.filter((o) => {
                if (o._id === content._id) {
                    add = true
                } else if (add && count <= MAX_RELATED_CONTENT) {
                    count++
                    return o
                }
            }).map((o) => o._id).join(',')

            if (_ids) {
                contents = await ContentService.find({
                    _id__in: _ids,
                    limit: MAX_RELATED_CONTENT
                })
            }

            _ids = track.contents.filter((o) => {
                if (o._id === content._id) {
                    add = false
                } else if (add && count <= MAX_RELATED_CONTENT) {
                    count++
                    return o
                }
            }).map((o) => o._id).join(',')

            if ( _ids && contents.length < MAX_RELATED_CONTENT) {
                const moreContents = await ContentService.find({
                    limit: MAX_RELATED_CONTENT - contents.length,
                    _id__in: _ids
                })
                contents = contents.concat(moreContents)
            }

            let _contents = track.contents.map((o) => {
                return contents.filter((c) => c._id === o._id)[0]
            }).filter((o) => !!o)

            this.update({ contents: _contents, nextContent: _contents[0] })
        }

        loadContent()

        this.videoUrl = (item) => {
            console.log(item)
            return 'trilha/' + opts.trackSlug + '/' + item.slug
        }

        this.nextVideoUrl = (next) => {
            return 'trilha/' + opts.trackSlug + '/' + next.slug
        }

        var disqus_config = function () {
            this.page.url = window.location.href;
            this.page.identifier = window.location.pathname;
        };
        (function() {
        var d = document, s = d.createElement('script');
        s.src = 'https://td-premium-transformacao-digital.disqus.com/embed.js';
        s.setAttribute('data-timestamp', +new Date());
        (d.head || d.body).appendChild(s);
        })();
});