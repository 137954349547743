import riot from 'riot'
 import './hero.scss'
riot.tag2('widget-hero', '<div class="widget-hero slick-dotted-inner slick-dotted-white"> <div each="{item in items}" class="bg-image slick-dotted-inner slick-dotted-white" riot-style="background-image: url(\'{item.image}\'); background-color: #1c1c1e;"> <div class="widget-hero__overlay"> <a href="{item.link}" class="content py-30 d-block"> <div class="py-100 text-{item.align}"> <div if="{item.subtitle}" class="widget-hero__small_title text-muted text-uppercase mb-20"> {item.subtitle} </div> <h1 if="{item.title}" class="widget-hero__big_title font-w700 text-white mb-10"> {item.title} </h1> <h2 if="{item.text}" class="widget-hero__text h4 font-w400 text-white-op mb-30"> {item.text} </h2> <button if="{item.button}" class="widget-btn btn"> {item.button} </button> </div> </a> </div> </div> </div>', '', '', function(opts) {

        this.settings = opts.widget.settings
        this.items = opts.widget.settings.items

        const slider = () => {
            if (this.items.length > 1) {
                $('.widget-hero', this.root).slick({
                    infinite: true,
                    dots: true,
                    slidesToShow: 1,
                    slidesToScroll: 1
                })
            }
        }

        this.on('mount', () => {
            slider()
        })
});