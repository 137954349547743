import riot from 'riot'
 
import { ContentService, CategoryService } from '@common/services'
riot.tag2('td-mylist', '<div class="content content-top"> <h3 class="profile-main-title"> Minha Lista </h3> <virtual each="{track in trackers}" if="{track.videos.length}"> <h5 class="profile-main-title mt-30"> {track.name} </h5> <div class="row"> <div class="col-md-3 col-12" each="{item in track.videos}"> <content-card path="{\'minha-lista/conteudo\'}" hide-mylist="{true}" item="{item}"></content-card> </div> </div> </virtual> <h5 class="profile-main-title mt-30" if="{myList && myList.length}"> Favoritos </h5> <div class="row" if="{myList && myList.length}"> <div class="col-md-3 col-12" each="{item in myList}"> <content-card path="{\'minha-lista/conteudo\'}" item="{item}"></content-card> </div> </div> </div>', '', '', function(opts) {

        window.myListTag = this

        this.myList = []

        const loadMyList = async () => {
            let myList = await ContentService.myList()
            this.update({myList})
        }

        loadMyList()

        const loadTrackers = async () => {
            let trackers = await CategoryService.find({ type: CategoryService.TYPE.TRACK })

            for (let i = 0; i < trackers.length; i++) {
                const track = trackers[i];
                track.videos = await ContentService.find({
                    _id__in: track.contents.join(','),
                    limit: 1000
                })
            }

            this.update({ trackers })
        }

        loadTrackers()

});