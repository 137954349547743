import riot from 'riot'
 import './group-contents.scss'
import { GroupService, ContentService } from '@common/services'
riot.tag2('td-group-contents', '<div class="td-group-contents" if="{contents && contents.length}"> <h4 if="{group}" class="widget-title content py-0"> {group.name} <div class="float-right"> <a href="{getGroupUrl(group)}" class="btn btn-sm btn-more">Ver Todos</a> </div> </h4> <div class="group__slider content py-0 slick-dotted-white" if="{contents}"> <content-card path="{path}" each="{item in contents}"></content-card> </div> </div>', '', '', function(opts) {

        this.name = opts.name

        this.path = opts.path

        this.contents = []

        const slider = () => {
            $('.group__slider', this.root).slick({
                dots: this.contents.length >= 4,
                infinite: true,
                slidesToShow: 4,
                slidesToScroll: 4,
                responsive: [
                    {
                        dots: true,
                        breakpoint: 1040,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3
                        }
                    },
                    {
                        dots: true,
                        breakpoint: 789,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            dots: true,
                            arrows: false,
                            centerMode: true,
                            centerPadding: '10px',
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            });
        }

        const init = async () => {

            const group = await GroupService.findById(opts.groupId)

            this.group = group

            this.contents = await ContentService.find({ group: opts.groupId, limit: 12 })

            this.update()
            slider()
        }

        init()
});