import riot from 'riot'
 import { ContentService } from '@common/services'
riot.tag2('home-free', '<div if="{isFree()}" class="category-list" onmouseenter="{enterCarousel()}" onmouseleave="{leaveCarousel()}"> <a href="/minha-lista" class="category-list-title"> Grátis </a> <video-carousel ref="videocarousel" items="{data}"></video-carousel> </div>', '', '', function(opts) {

        this.data = []

        const loadFree = async () => {
            let data = await ContentService.find({
                privacy: ContentService.PRIVACY.FREE
            })
            this.update({data})
        }

        loadFree()

        this.enterCarousel = (key) => {
            return (e) => {
                e.preventUpdate = true
                this.refs.videocarousel.showNavigation()
            }
        }

        this.leaveCarousel = (key) => {
            return (e) => {
                e.preventUpdate = true
                this.refs.videocarousel.hideNavigation()
            }
        }
});