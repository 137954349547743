import riot from 'riot'
 
import './score.scss'
import store from '@td-premium/store'
import dimensions from './dimensions'
riot.tag2('td-score', '<virtual each="{dimension, dimensionKey in dimensions}"> <route dimension="{dimension}" dimension-key="{dimensionKey}" each="{question, key in dimension.questions}" path="{\'/\' + dimension.router + \'/\' + key}"> <td-score-question dimension="{opts.dimension}" index="{key}" question="{question}" class="pt-page-{opts.dimension.router}-{key}" prev="{parent.getPrev(opts.dimension, opts.dimensionKey, question, key)}" next="{parent.getNext(opts.dimension, opts.dimensionKey, question, key)}"></td-score-question> <div class="progress" if="{question}"> <div riot-style="width:{question.percent}%;" class="progress-bar"></div> </div> </route> </virtual>', '', '', function(opts) {

        this.dimensions = dimensions.filter((o) => {
            if (o.type=='feedback') return !this.isAgency() && this.isMultiUsers()
            if (o.type=='conclusao') return this.isAgency() || !this.isMultiUsers()
            return true
        })

        let customer = this.currentCustomer()

        if (customer && customer._id === '5dded8a7098a4a058d84b6a0') {
            this.dimensions[0].title = 'Bem vindo, colaborador do SEBRAE MG';
            this.dimensions[0].questions[0].title = `
                <p>
                    Convidamos você a responder o diagnóstico de maturidade digital. <br />
                    Por gentileza, preencha o diagnóstico levando em conta sua prática e vivência no dia a dia do trabalho. <br/><br />
                    É bem prático e leva apenas 10 minutos. Vamos lá!<br /><Br />
                    <small><i class="text-white">(Para melhor experiência recomendamos utilizar o navegador chrome)</i></small>
                </p>
            `;
        }

        let progressTotal = 0

        this.dimensions.forEach((item, key) => {
            progressTotal += item.questions.length
        })

        let progressCount = 0

        this.dimensions.forEach((item, key) => {

            item.questions.forEach((q, qKey) => {
                progressCount++
                q.percent = progressCount / progressTotal * 100
            })
        })

        this.getPrev = (dimension, dimensionKey, question, key) => {
            if (key === 0 && this.dimensions[dimensionKey - 1]) {
                const prevDimension = this.dimensions[dimensionKey - 1]
                return 'score/' + prevDimension.router + '/' + (prevDimension.questions.length -1)
            }

            if (key > 0) {
                return 'score/' + dimension.router + '/' + (key - 1)
            }
        }

        this.getNext = (dimension, dimensionKey, question, key) => {
            if ((key + 1) >= dimension.questions.length && this.dimensions[dimensionKey + 1]) {
                return 'score/' + this.dimensions[dimensionKey + 1].router + '/0'
            }

            if ((key + 1) < dimension.questions.length) {
                return 'score/' + dimension.router + '/' + (key + 1)
            }
        }

});