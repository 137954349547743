import riot from 'riot'
 import './upgrade.scss'
import { PlanService, SubscriptionService } from '@common/services'
import sortBy from 'lodash/sortBy'
riot.tag2('td-subscribe-upgrade', '<div class="content content-top dark-bg"> <h1>Escolha o seu plano</h1> <div class="row" if="{plans}"> <div each="{plan in plans}" class="col-md-6 col-xl-3"> <a onclick="{selectPlan(plan)}" href="" class="block {plan.current ? \'current-plan\' : \'\'} plan-card block-link-pop block-rounded block-bordered text-center"> <div class="block-header block-header-full"> <h3 class="block-title text-black font-w600"> {plan.name} </h3> </div> <div class="block-content bg-body-light"> <div class="h1 font-w700 text-primary mb-10">R$ {plan.priceFormat}</div> <div class="h5 text-black">{plan.interval}</div> </div> <div class="block-content plan-content-description"> <raw if="{plan.description}" html="{plan.description}"></raw> </div> <div class="block-content block-content-full"> <span class="btn btn-hero btn-sm btn-rounded btn-noborder btn-alt-primary"> {plan.current ? \'Plano Atual\' : \'Alterar Plano\'} </span> </div> </a> </div> </div> </div>', '', '', function(opts) {


        const formatPlan = (plan, subscription) => {
            plan.price = 0

            plan.products.forEach((product) => {
                plan.price += product.price || product.item.pricing.value
            })

            if (plan.recurrence.intervalCount == 12) {
                plan.interval = 'por ano'
            } else if (plan.recurrence.intervalCount == 6) {
                plan.interval = 'semestral'
            } else {
                plan.interval = 'por mês'
            }

            if (plan.pricing.discount && (plan.pricing.discount.percentage || plan.pricing.discount.amount)) {
                const discount = plan.pricing.discount

                if (discount.type === 'percentage') {
                    plan.price -= plan.price * (Number(discount.percentage) / 100)
                } else {
                    plan.price -= Number(discount.amount)
                }
            }

            plan.priceFormat = parseFloat(plan.price).toFixed(2).toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }).replace('.', ',')

            return plan
        }

        const loadData = async () => {
            const subscription = await SubscriptionService.current.get()

            const plans = await PlanService.find({status: 1})

            let plansList = plans.filter((plan) => {
                if (!subscription.plan) return true

                if (subscription.plan._id == plan._id)
                    plan.current = true

                return true
            }).map(formatPlan)

            this.update({ plans: sortBy(plansList, ['price']) })
        }

        loadData()

        this.selectPlan = (plan) => {
            return (e) => {
                e.preventDefault();
                globalRouter.page('assinar/' + plan._id)
            }
        }
});