import riot from 'riot'
 import { ContentService } from '@common/services'
riot.tag2('home-mylist', '<div if="{myList.length}" class="category-list" onmouseenter="{enterCarousel()}" onmouseleave="{leaveCarousel()}"> <a href="/minha-lista" class="category-list-title"> Minha Lista </a> <video-carousel ref="videocarousel" items="{myList}"></video-carousel> </div>', '', '', function(opts) {


        this.on('unmount', () => {
            window.myList = null
        })

        window.myListTag = this

        this.myList = []

        const loadMyList = async () => {
            let myList = await ContentService.myList()
            this.update({myList})
        }

        loadMyList()

        this.enterCarousel = (key) => {
            return (e) => {
                e.preventUpdate = true
                this.refs.videocarousel.showNavigation()
            }
        }

        this.leaveCarousel = (key) => {
            return (e) => {
                e.preventUpdate = true
                this.refs.videocarousel.hideNavigation()
            }
        }
});