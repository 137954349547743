import riot from 'riot'
 import { ReportService } from '@common/services'
riot.tag2('td-dashboard-prepared', '<div class="dashboard-card"> <div class="dashboard-card__header"> <div class="dashboard-card__header-title"> Preparado para ajudar a empresa a se desenvolver na Transformação Digital </div> </div> <div class="dashboard-card__content"> <td-chart-multi-bars type="vertical" if="{data}" data="{data}"></td-chart-multi-bars> </div> <div class="dashboard-card__footer dashboard-card__footer-legend-space"></div> </div>', '', '', function(opts) {


        this.query = {}

        const loadData = async (query = {}) => {
            this._chartQueryString = JSON.stringify(opts.chartQuery)
            this.query = { ...this.query, ...opts.chartQuery, ...query }
            const data = await ReportService.getType('feedback-q6', this.query)

            const items = [
                {
                    label: 'Discordo totalmente',
                    value: data.totallyDisagree,
                    bgColor: '#ce00ff',
                    bgGradient: 'linear-gradient(to bottom, #b800ff 0%,#7f00ff 100%)'
                },
                {
                    label: 'Discordo em partes',
                    value: data.partlyDisagree,
                    bgColor: '#005bff',
                    bgGradient: 'linear-gradient(to bottom, #0097ff 0%,#0053ff 100%)'
                },
                {
                    label: 'Concordo em partes',
                    value: data.agreeInParts,
                    bgColor: '#00ceff',
                    bgGradient: 'linear-gradient(to bottom, #00e3ff 0%,#00a7ff 100%)'
                },
                {
                    label: 'Concordo totalmente',
                    value: data.agreeTotally,
                    bgColor: '#71ec8e',
                    bgGradient: 'linear-gradient(to bottom, #88f3aa 0%,#50e366 100%)'
                }
            ]

            this.update({
                data: { items }
            })
        }

        loadData()

        this.on('update', () => {
            if ( this._chartQueryString != JSON.stringify(opts.chartQuery)) {
                this.data = null
                loadData()
            }
        })
});