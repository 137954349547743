import riot from 'riot'
 
import './department.scss'
import { DepartmentService } from '@common/services'
riot.tag2('td-config-department', '<h1 class="main-title"> <breadcrumb></breadcrumb> </h1> <div class="row"> <div class="col-4"> <td-input onchange="{loadDataDebounce}" onenter="{loadData}" model="query.search" type="text" required="{true}" placeholder="Busca"></td-input> </div> <div class="col-8 text-right"> <a href="configuracao/departamento/novo" class="btn btn-hero">Novo Departamento</a> </div> </div> <table class="table table-striped table-vcenter"> <thead class="bg-gradient"> <tr> <th>Nome</th> <th class="text-center" style="width: 100px;">Ações</th> </tr> </thead> <tbody> <tr each="{item in data}"> <td> {item.name} </td> <td class="text-center"> <div class="btn-group"> <a href="configuracao/departamento/{item._id}" class="btn btn-sm btn-secondary" data-toggle="tooltip" title="Edit"> <i class="fa fa-pencil"></i> </a> <button onclick="{delete(item)}" type="button" class="btn btn-sm btn-secondary" data-toggle="tooltip" title="Delete"> <i class="fa fa-times"></i> </button> </div> </td> </tr> </tbody> </table>', '', '', function(opts) {

        this.service = DepartmentService

        this.query = {
            sort: '-createdAt',
            search: null
        }

        this.messages = {
            delete: (item) => {
                return `
                    Você tem certeza que deseja <br />
                    deletar o departamento <strong>${item.name}</strong>?
                `
            }
        }

        this.mixin('baseList')
});