import riot from 'riot'
 import { PlanService } from '@common/services'
import store from '@td-premium/store'
riot.tag2('signup-plan', '<div class="signup-content active col-9"> <h1 class="h4 font-w300 mt-20 text-center">Agora, escolha o plano que mais se encaixe em suas necessidade.</h1> <div class="planos"> <div class="plano row text-center" each="{plan in plans}" onclick="{selectPlan(plan)}"> <div class="title">{plan.name}</div> <div class="summary" if="{plan.description}"><raw html="{plan.description}"></raw></div> <div class="price"> <virtual if="{plan.hasDiscount}"> <div class="price-before">de <span>{plan.basePrice}</span></div> <span class="price-number">por <span>{plan.price}</span></span> <div class="price-condition" if="{plan.cycle > 1}">nas primeiras {plan.cycle} parcelas</div> <div class="price-condition" if="{plan.cycle == 1}">em uma única fatura</div> </virtual> <virtual if="{!plan.hasDiscount}"> <span class="price-number"><span>{plan.price}</span></span> </virtual> </div> <button type="submit" class="btn btn-block btn-hero btn-outline btn-rounded btn-alt-primary"> Escolher Plano </button> </div> <div class="plano row text-center corp"> <div class="title">Corporativo</div> <div class="summary"> <ul> <li> Acesso <ul> <li>Ilimitado</li> </ul> </li> <li> Vídeos <ul> <li>+100</li> </ul> </li> <li> Usuários <ul> <li>Vários</li> </ul> </li> </ul> </div> <button type="submit" class="btn btn-block btn-hero btn-outline btn-rounded btn-consultor"> Fale com um<br>consultor </button> </div> </div> </div>', '', '', function(opts) {

        this.plans = store.get('plans') || []

        const loadPlans = async () => {
            let plans = await PlanService.find()

            plans = plans.map((data) => {
                let hasDiscount = false
                const basePrice = parseFloat(data.pricing.basePrice)
                let price = parseFloat(data.pricing.basePrice)
                const discount = parseFloat(data.pricing.discount.amount || 0)

                if (data.pricing.discount.cycle) {
                    if (data.pricing.discount.type === 'percent') {
                        price = price + (price * (discount / 100))
                    } else {
                        price = price - discount
                    }
                }

                if (basePrice > price) {
                    hasDiscount = true
                }

                return {
                    _id: data._id,
                    name: data.name,
                    basePrice: 'R$ ' + basePrice.toLocaleString('pt-BR', {minimumFractionDigits: 2}),
                    price: 'R$ ' + price.toLocaleString('pt-BR', {minimumFractionDigits: 2}),
                    description: data.descriptionHtml,
                    cycle: data.pricing.discount.cycle,
                    hasDiscount
                }
            })

            store.set('plans', plans)
            this.update({plans})
        }

        loadPlans()

        this.selectPlan = (plan) => {
            return (e) => {
                e.preventDefault()
                store.set('plan', plan)
                globalRouter.page('/app/cadastro/pagamento')
            }
        }

});