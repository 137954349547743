import riot from 'riot'
 import './multi-bars.scss'
riot.tag2('td-chart-multi-bars', '<div class="multi-bars multi-bars__horizontal" if="{opts.type!=\'vertical\'}"> <div class="multi-bars__part" each="{item, key in items}" onmousemove="{showTooltip(item)}" onmouseleave="{hideTooltip}" riot-style="width:{item.percent}%; --oldPercent: {oldPercents[key] || 100}%; --bgColor:{item.bgColor}; --bgGradient:{item.bgGradient};"> <span class="tooltip">{item.value}</span> </div> </div> <div class="multi-bars multi-bars__vertical" if="{opts.type==\'vertical\'}"> <div class="multi-bars__bar" each="{item in items}" onmousemove="{showTooltip(item)}" onmouseleave="{hideTooltip}" riot-style="--bgColor:{item.bgColor}; --bgGradient:{item.bgGradient}; "> <div class="multi-bars__part" riot-style="height: {item.percent}%;"> <span class="tooltip">{item.value}</span> </div> </div> </div> <div class="chart-legends {opts.type == \'vertical\' ? \'d-flex flex-wrap justify-content-center\' : \'\'}"> <div class="chart-legends__text" each="{item in items}" riot-style="--bgColor: {item.bgColor};"> {item.label} </div> </div> <td-chart-tooltip data="{currentSelect}" ref="tooltip"> {opts.data.percentValue}% </td-chart-tooltip>', '', '', function(opts) {

        this.oldPercents = {}

		const updateData = () => {
			const { items } =  opts.data || {}

			if (!items || !items.length) return

            let total = 0
            let remove = 0

			items.forEach((o, key) => {
                total += o.value || 0
			})

			items.forEach((o) => {
				o.percentValue = Math.floor(o.value / total * 100)
                o.percent = (o.value / total * 100) - remove
                if (opts.type != 'vertical' && o.percent <= 0) {
                    o.percent = 2
                    remove += 2
                }
			})

			this.items = items
            this.total = total
        }

        this.showTooltip = (item) => {
            return (e) => {
                e.preventUpdate = true
                this.currentSelect = item
                this.refs.tooltip.setPosition(e)
            }
        }

        this.hideTooltip = (e) => {
            e.preventUpdate = true
            this.refs.tooltip.hide()
        }

		this.on('update', () => {
			updateData()
        })

        const saveOldPercent = () => {
            this.items.forEach((o, key) => {
                this.oldPercents[key] = o.percent
            })
        }

        this.on('updated', () => {
            saveOldPercent()
        })

        updateData()
        setTimeout(() => {
            saveOldPercent()
        }, 200)
});