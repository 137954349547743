import riot from 'riot'
 import './external.scss'
import { FreeLinkService, AuthService } from '@common/services'
import store from '@td-premium/store'
riot.tag2('td-free-register', '<div class="row mx-0 justify-content-center"> <div class="hero-static col-lg-8 col-xl-6 d-flex align-items-center"> <div class="content content-full overflow-hidden"> <div class="py-50 text-center"> <a href=""> <img riot-src="{app.imageLogo}" height="{app.imageSize}"> </a> </div> <div class="form-wrapper"> <form onsubmit="{onsubmit}"> <td-input label="Nome Completo *" required="{true}" placeholder="Nome Completo" model="data.name" error="error.name"></td-input> <td-input label="Email *" required="{true}" placeholder="Email" error="error.email" model="data.email" type="email"></td-input> <td-input label="Telefone *" placeholder="Telefone" model="data.phone" type="tel" mask="00 0000-00009" mask-options="{{reverse: false,clearIfNotMatch:true}}" required="{true}"></td-input> <div class="row"> <div class="col-md-6 pr-10"> <td-input required="{true}" label="Senha *" placeholder="Senha" type="password" autocomplete="new-password" error="error.password" model="data.password"></td-input> </div> <div class="col-md-6 pl-10"> <td-input required="{true}" label="Confirmar Senha *" placeholder="Confirmar Senha" type="password" autocomplete="new-password" error="error.confirmPassword" model="data.confirmPassword"></td-input> </div> </div> <div if="{typeof error === \'string\'}" class="text-danger"> {error} </div> <button type="submit" class="btn btn-block btn-hero btn-noborder mt-20 btn-rounded btn-alt-primary"> <i if="{loading}" class="fa fa-spinner fa-spin"></i> <span if="{!loading}"> Criar conta e entrar </span> </button> </form> </div> </div> </div> </div>', '', '', function(opts) {

        this.data = {
            reservation: opts.reservation
        }

        this.onsubmit = async (e) => {
            e.preventDefault()

            if (this.loading)
                return

            this.loading = true

            try {
                const result = await FreeLinkService.register(this.data)

                const query = queryString()
                store.set('loggedIn', true)
                store.set('token', result.token)

                $success({
                    toast: false,
                    position: 'center',
                    title: 'Sucesso!',
                    html: 'Cadastrado com sucesso!',
                    showConfirmButton: true,
                    timer: null
                })
                .then(async () => {

                    await AuthService.check()

                    const data = result.data

                    window.location = '/app'
                })

            } catch (error) {
                this.update({ loading: false, error: error.message })
            }
        }
});