import riot from 'riot'
 import './content-modal.scss'
import { ContentService } from '@common/services'
riot.tag2('content-modal', '<div class="content-modal fade show"> <a href="{opts.backUrl}" class="content-modal__backdrop"></a> <div class="content-modal__container content content-full"> <div class="content-modal__inner" style="background-image: url(\'assets/media/various/bg-pattern-inverse.png\');"> <div class="content-modal__header"> <ul class="nav nav-tabs-block"> <li class="nav-item"> <a class="nav-link {currentTab == \'main\' ? \'active\': \'\'}" onclick="{showTab(\'main\')}" href=""> Conteúdo </a> </li> </ul> <a class="close-modal" href="{opts.backUrl}"> <i class="si si-close"></i> </a> </div> <div class="content-modal__content h-100" if="{currentTab == \'main\' && blocked}"> <blocked-content></blocked-content> </div> <div class="content-modal__content h-100" if="{currentTab == \'main\' && content && !blocked}"> <div class="content-modal__content--main h-100"> <td-player if="{content.video}" onnext="{onnext}" autoplay="{true}" content="{content}" next="{nextContent}"></td-player> <h3 class="content-heading text-black pt-10 mb-0"> {content.title} <div class="float-right"> <content-button-mylist content="{content}"></content-button-mylist> </div> </h3> <p class="body text-black mt-20"> <raw html="{content.content||\'\'}"></raw> </p> </div> <div class="content-modal__content--sidebar py-20 pr-20 h-100"> <content-card each="{item in playlist}" onclick="{clickCard(item)}" link="{link}"></content-card> </div> </div> <div class="content-modal__content h-100" if="{currentTab == \'articles\'}"> <div class="content-modal__content--main h-100"> <h3 class="content-heading text-black pt-10 mb-0"> Articles </h3> </div> </div> </div> </div> </div>', '', '', function(opts) {

        const MAX_RELATED_CONTENT = 5
        const loadContent = async (o) => {
            let content
            try {
                content = await ContentService.findById(o.contentId)
            } catch (e) {
                if (e && e.blocked) {
                    return this.update({blocked: true, loading: false})
                }
                return globalRouter.page('/app/404');
            }

            let extraQuery = o.query || {}

            if (o.groupId) {
                extraQuery.group = o.groupId
            } else if (!o.query && content.groups && content.groups.length) {
                extraQuery.groups__in = content.groups.join(',')
            }

            let playlist = await ContentService.find({
                _id__nin: content._id,
                createdAt__gte: content.createdAt,
                limit: MAX_RELATED_CONTENT,
                course__exists: false,
                ...extraQuery
            })

            if ( playlist.length < MAX_RELATED_CONTENT) {
                const moreContents = await ContentService.find({
                    _id__nin: [content._id].concat(playlist.map((o) => o._id)).join(','),
                    limit: MAX_RELATED_CONTENT - playlist.length,
                    ...extraQuery
                })
                playlist = [...playlist, ...moreContents]
            }

            this.update({content, playlist, nextContent: playlist[0]})
        }

        loadContent({
            contentId: opts.contentId,
            groupId: opts.groupId,
            query: opts.query
        })

        const clickCard = (item) => {
            this.update({content: null})

            history.pushState({
                id: 'modal'
            }, document.title, '/app/' + opts.path + '/'+ item._id);

            loadContent({
                contentId: item._id
            })
        }

        this.clickCard = (item) => {
            return (e) => {
                e.preventDefault();
                e.stopPropagation();
                clickCard(item)
            }
        }

        this.onnext = (next) => {
            clickCard(next)
        }

        this.link = ()=>'javascript:void;'

        this.currentTab = 'main'

        this.showTab = (tab) => {
            return (e) => {
                e.preventDefault()
                this.currentTab = tab
            }
        }

        let scrollTop = document.documentElement.scrollTop

        this.on('mount', () => {
            scrollTop = document.documentElement.scrollTop
            $('body').addClass('overflow-hidden')
            $('#page-header').hide()

            setTimeout(() => {
                $('#page-header').show()
            }, 1000)
        })

        this.on('unmount', () => {
            $('body').removeClass('overflow-hidden')
            $('#page-header').show()
            document.documentElement.scrollTop = scrollTop
        })
});