import riot from 'riot'
 import './half-circle.scss'
riot.tag2('td-chart-half-circle', '<div class="content-chart"> <div class="text-content"> <div class="numbers"> <div class="left">0%</div> <div class="right">100%</div> </div> <yield></yield> </div> <div class="single-chart"> <svg viewbox="0 0 36 36" class="circular-chart orange"> <defs> <lineargradient id="linear" x1="0%" y1="0%" x2="0%" y2="50%"> <stop offset="0%" stop-color="#00f2ff" stop-opacity="1.0"></stop> <stop offset="100%" stop-color="#00b8ff" stop-opacity="1.0"></stop> </linearGradient> </defs> <path class="circle-bg" stroke-dasharray="60, 100" d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"></path> <path class="circle" onmousemove="{showTooltip}" onmouseleave="{hideTooltip}" stroke-dashoffset="0" stroke-dasharray="{percent} 100" stroke="url(#linear)" d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"></path> </svg> </div> </div> <td-chart-tooltip percent="{percentText}" ref="tooltip"> {opts.percent}% </td-chart-tooltip>', '', '', function(opts) {

		const updateData = () => {
			const data =  opts.data || {}
            this.percent = (data.percent / 100 * 60)
            this.percentText = data.percent
		}

		this.on('update', () => {
			updateData()
        })

        this.showTooltip = (e) => {
            this.refs.tooltip.setPosition(e)
        }

        this.hideTooltip = (e) => {
            this.refs.tooltip.hide()
        }

		updateData()
});