import riot from 'riot'
 import './signup.scss'
import { AuthService } from '@common/services'
import store from '@td-premium/store'
import signupScreen from '@td-premium/assets/media/images/signup-screen.jpg'
riot.tag2('td-signup', '<div class="row justify-content-center"> <div class="col-sm-8"> <div class="row"> <div class="col"> <div class="py-30 px-5 logo"> <img riot-src="{app.imageLogo}" height="{app.imageSize}"> </div> </div> </div> <signup-index></signup-index> </div> <div class="col-sm-4"> <div class="slider"> <div class="slide"> <div class="slide-img" riot-style="background-image:url({signupScreen})"></div> </div> </div> </div> </div>', '', '', function(opts) {

        this.signupScreen = signupScreen

        this.logout = async (e) => {
            e.preventDefault()
            await AuthService.logout()
            globalRouter.page('/app')
        }

});