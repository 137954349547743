import riot from 'riot'
 import './group-list.scss'
import { GroupService } from '@common/services'
riot.tag2('widget-group-list', '<h4 if="{settings.title}" class="widget-title content"> {settings.title} </h4> <div class="widget-group-list content mb-0 slick-dotted-white"> <a each="{item in items}" class="widget-group-list__item block mb-0 block-transparent border-left border-x" riot-style="border-color: {item.color || \'#fff\'} !important;" href="{getGroupUrl(item)}"> <div class="block-content block-content-full p-0 d-flex align-items-center justify-content-between"> <p class="font-size-lg font-w600 text-white mb-0 px-20"> {item.name} </p> </div> </a> </div>', '', '', function(opts) {

        this.loading = true
        this.settings = opts.widget.settings

        this.items = []

        const slider = () => {
            $('.widget-group-list', this.root).slick({
                infinite: true,
                dots: this.items.length >= 5,
                slidesToShow: 5,
                slidesToScroll: 5,
                responsive: [
                    {
                        breakpoint: 769,
                        settings: {
                            dots: true,
                            arrows: false,
                            centerMode: true,
                            centerPadding: '40px',
                            slidesToShow: 2
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            dots: true,
                            arrows: false,
                            centerMode: true,
                            centerPadding: '40px',
                            slidesToShow: 1
                        }
                    }
                ]
            });
        }

        const init = async () => {
            this.items = await GroupService.find({
                parent: opts.widget.settings.group || 'null'
            })
            this.update()
            slider()
        }

        init()
});