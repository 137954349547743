import riot from 'riot'
 import { UserService } from '@common/services'
import moment from 'moment'
import store from '@td-premium/store'
riot.tag2('profile-edit', '<div class="mb-30"> <h3 class="profile-section-title">Editar Perfil</h3> <form onsubmit="{onsubmit}"> <div class="row profile-edit-form"> <div class="col"> <td-input label="Email" required="{true}" placeholder="Email" error="error.email" model="data.email" type="email"></td-input> <td-input label="Nome Completo" required="{true}" placeholder="Nome Completo" model="data.name" error="error.name"></td-input> <div class="row"> <div class="col-md-6 pr-5"> <td-input label="CPF" placeholder="CPF" model="data.document" error="error.document"></td-input> </div> <div class="col-md-6 pl-5"> <td-input label="Telefone" placeholder="Telefone" model="data.phone" error="error.phone"></td-input> </div> </div> </div> <div class="col pl-30"> <td-input label="Senha Atual" type="password" placeholder="Senha Atual" error="error.currentPassword" model="data.currentPassword"></td-input> <td-input label="Nova Senha" type="password" placeholder="Nova Senha" error="error.senha" model="data.password"></td-input> <td-input label="Confirmar Nova Senha" type="password" placeholder="Confirmar nova senha" model="data.confirmPassword" error="error.confirmPassword"></td-input> </div> </div> <button type="submit" class="save-form mt-20 btn btn-block btn-hero btn-noborder btn-rounded btn-alt-primary"> <i if="{loading}" class="fa fa-spinner fa-spin"></i> <span if="{!loading}"> Salvar </span> </button> </form> </div>', '', '', function(opts) {

        this.data = {}

        const loadUser = async () => {
            const data = await UserService.current.get()
            this.update({
                data: {
                    name: data.name,
                    email: data.email,
                    phone: data.phone,
                    document: data.document
                }
            })
        }

        loadUser()

        this.onsubmit = async (e) => {
            e.preventDefault()

            if (this.loading)
                return

            this.loading = true

            try {
                const user = await UserService.current.put(this.data)

                store.set('user', user)

                $success({
                    text: 'Alteração realizada com sucesso!'
                })

                this.update({ error: {}, loading: false })
                this.app.update()
            } catch (error) {
                this.update({ error: error.message, loading: false })
            }

        }

});