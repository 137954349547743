import riot from 'riot'
 
import { CustomerService } from '@common/services'
riot.tag2('profile-payment', '<div class="mb-30"> <form ref="form" onsubmit="{onsubmit}"> <div class="credit-card mb-20"></div> <div class="row justify-content-center"> <div class="col-md-4 pr-5"> <td-input required="{true}" name="number" placeholder="Número do Cartão" error="error.card_number" model="data.card_number"></td-input> </div> <div class="col-md-4 pl-5"> <td-input required="{true}" name="name" placeholder="Nome (igual ao cartão)" error="error.holder_name" model="data.holder_name"></td-input> </div> </div> <div class="row justify-content-center"> <div class="col-md-4 pr-5"> <td-input required="{true}" name="expiry" placeholder="Validade" error="error.card_expiration" model="data.card_expiration"></td-input> </div> <div class="col-md-4 pl-5"> <td-input required="{true}" name="cvc" placeholder="CVC" error="error.card_cvv" model="data.card_cvv"></td-input> </div> </div> <div if="{errorMessage}" class="text-center text-danger m-20 animated fadeIn"> {errorMessage} </div> <button type="submit" class="btn btn-block btn-hero btn-noborder btn-rounded btn-alt-primary"> <i if="{loading}" class="fa fa-spinner fa-spin"></i> <span if="{!loading}"> Salvar </span> </button> </form> </div>', '', '', function(opts) {

        this.on('mount', () => {
            new Card({
                form: this.refs.form,
                container: '.credit-card',
                masks: {
                    cardNumber: '•'
                },
                placeholders: {
                    number: '•••• •••• •••• ••••',
                    name: 'Nome Completo',
                    expiry: '••/••••',
                    cvc: '•••'
                },
                messages: {
                    validDate: 'expire\ndate',
                    monthYear: 'mm/yy'
                }
            })
        })

        this.onsubmit = async (e) => {
            e.preventDefault()

            if (this.loading)
                return

            this.loading = true

            try {
                await CustomerService.current.updateCard(this.data)

                $success({
                    text: 'Cartão de credito atualizado com sucesso!'
                })

                this.update({ errorMessage: null, loading: false })

            } catch (error) {
                this.update({ errorMessage: error.message, loading: false })
            }
        }
});