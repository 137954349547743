

import riot from 'riot'
import _get from 'lodash/get'
import _set from 'lodash/set'
import store from '@td-premium/store'
import moment from 'moment'


import { UserService, ContentService } from '@common/services'

let metadata = {}

riot.mixin('globalFunctions', {
    isSubActive: function() {
        let session = store.get('session')
        return session.active
    },
    isSubPending: function() {
        let session = store.get('session')
        return session.pending
    },
    currentUser: function() {
        let session = store.get('session') || {}
        return session.user
    },
    currentCustomer: function() {
        let session = store.get('session') || {}
        return session.user.customer
    },
    isAdmin: function() {
        let session = store.get('session') || {}
        return session.user.type === UserService.TYPE.ADMIN
    },
    isManagerOrAadmin: function() {
        let session = store.get('session') || {}
        return session.user.type >= UserService.TYPE.MANAGER
    },
    isFree: function() {
        let session = store.get('session') || {}
        return session.user.free
    },
    isLockContent: function(item) {
        let session = store.get('session') || {}
        let user = session.user
        let permissions = session.permissions

        if (ContentService.PRIVACY.FREE == item.privacy) return false
      
        if (permissions.all && (session.active && !session.pending)) return false

        if (item.category && item.category._id) {
            if (permissions.categories.indexOf(item.category._id) > -1) return false
        }

        if (item.trackers) {
            for (let i = 0; i < item.trackers.length; i++) {
                if (permissions.trackers.indexOf(item.trackers[i]._id) > -1) return false
            }
        }        
        
        if (item.groups) {
            for (let i = 0; i < item.groups.length; i++) {
                if (permissions.groups.indexOf(item.groups[i]) > -1) {
                    return false
                }
            }
        }

        if (item.course && item.course._id) {
            if (permissions.courses.indexOf(item.course._id) > -1) return false
        }

        if (permissions.contents.indexOf(item._id) > -1) return false

        return true
    },
    isOwner: function() {
        let session = store.get('session') || {}
        return session.user.owner
    },
    isOwnerOrAdmin: function() {
        return this.isAdmin() || this.isOwner()
    },  
    isOwnerAndAdmin: function() {
        return this.isAdmin() && this.isOwner()
    },
    isMaster: function() {
        let session = store.get('session') || {}
        return session.user.customer.master
    },
    isMultiUsers: function() {
        let session = store.get('session') || {}
        return session.permissions.users > 1
    },
    isAgency: function() {
        let session = store.get('session') || {}
        return session.user.customer.type === 'agency'
    },
    videoTime: function(secs) {
        let d = Number(secs)
        let h = Math.floor(d / 3600)
        let m = Math.floor(d % 3600 / 60)
        let s = Math.floor(d % 3600 % 60)

        let hDisplay = h > 0 ? h + ' h ' : '';
        let mDisplay = m > 0 ? m + ' min ' : '';
        let sDisplay = '';//s > 0 ? s + 'sec ' : '';
        return hDisplay + mDisplay + sDisplay; 
    },
    saveMetaData: async function() {
        const _metadata = JSON.parse(JSON.stringify(metadata))
        metadata = {}
        const result = await UserService.current.put({
            metadata: _metadata
        })
        return result
    },
    getMetaData: function(path, _default) {
        const session = store.get('session')
        const user = session.user
        if (!user.metadata) user.metadata = {}
        return _get(user.metadata, path, _default)
    },
    setMetaData: function(path, value) {
        const session = store.get('session')
        const user = session.user

        if (!user.metadata) user.metadata = {}        

        _set(metadata, path, value)

        _set(user.metadata, path, value)

        session.user = user

        store.set('session', session)

        return user.metadata
    },
    formatDate: (dateStr, format = 'll') => {
        return moment(new Date(dateStr)).format(format)
    },
    getGroupUrl: function(item, first = true) {
        // let path = []

        // if (item.parent) {
        //     path.push(this.getGroupUrl(item.parent, false))
        // } 

        // if (first) {
        //     path.push(item.slug)
        // } else if (!item.parent) {
        //     path.push(item.slug)
        // }

        //return (first ? 'grupo/': '') + path.join('-') + (first ? '/'+item._id+'/view' : '')

        return 'grupo/' + item._id + '/conteudo'
    },
    getFirstGroup: function(item) {
        if (!item.parent) {
            return item
        }

        return this.getFirstGroup(item.parent)
    }
}, true)
