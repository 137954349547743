import riot from 'riot'
 import { ContentService } from '@common/services'
riot.tag2('home-category', '<div if="{item.videos.length}" class="category-list" onmouseenter="{enterCarousel()}" onmouseleave="{leaveCarousel()}"> <a href="categoria/{item.slug}" class="category-list-title"> {item.name} </a> <video-carousel ref="videocarousel" items="{item.videos}"></video-carousel> </div>', '', '', function(opts) {

        this.item.videos = []

        const loadVideos = async () => {
            this.item.videos = await ContentService.find({
                category: this.item._id,
                limit: 1000
            })

            this.update()
        }

        this.on('mount', () => {
            loadVideos()
        })

        this.enterCarousel = (key) => {
            return (e) => {
                e.preventUpdate = true
                this.refs.videocarousel.showNavigation()
            }
        }

        this.leaveCarousel = (key) => {
            return (e) => {
                e.preventUpdate = true
                this.refs.videocarousel.hideNavigation()
            }
        }
});