import _get from 'lodash/get'
import { ContentService, GroupService } from '@common/services'

const modelService = {
    ContentService,
    GroupService
}

export const getDynamicItems = async (dynamic) => {
    const service = modelService[dynamic.service]
    const items = await service.find(dynamic.query)
    return items
}

export const mapItems = (items, map, _dynamic) => {
    return items.map(_item => {
        const result = { _dynamic, _item }
        Object.keys(map).forEach(key=> {
            result[key] = typeof map[key] == 'function' ? map[key](_item) : (_get(_item, map[key].key))
        })
        return result
    })
}

export const dynamicItems = async (items, map) => {
    let newItems = []

    for (let i = 0; i < items.length; i++) {
        const item = items[i]
        if (!item.dynamic) {
            newItems.push(item)
            continue
        }       

        const dynamicItems = await getDynamicItems(item.dynamic)
        
        const keys = Object.keys(map[item.dynamic.service])

        const mapService = map[item.dynamic.service]

        const mappedItems = mapItems(dynamicItems, mapService, item.dynamic).filter((item) => {
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i]

                if (mapService[key] && mapService[key].required && !_get(item, key)) {
                    return false
                }
            }

            return true
        })

        
        newItems = [...newItems, ...mappedItems]
    }

    return newItems
}