import riot from 'riot'
 riot.tag2('td-questions-empreendedorismo-digital', '<div class="onboarding"> <div class="header"> <div class="col"> <div class="py-30 px-5 logo"> <img riot-src="{app.imageLogo}" height="{app.imageSize}"> </div> </div> </div> <div class="content temas"> <div class="col-6 tema-big"> <h1 class="title">EMPREENDEDORISMO DIGITAL</h1> <p>Empreendedorismo é a disposição ou capacidade de idealizar, coordenar e realizar projetos, serviços e/ou negócios. Para concluir o diagnóstico, vamos entender como está sua percepção sobre empreender em um cenário que envolve o digital.</p> <p>As questões a seguir abordam sobre disrupção, transformação cultural, produtividade e crescimento exponencial.</p> <a href="onboarding/perguntas/experiencia-do-cliente" class="back-button btn text-white">Voltar</a> </div> <div class="col-6 question-list"> <td-question each="{item, index in questions}" index="{index+1}"></td-question> <button onclick="{save}" class="btn btn-hero">Finalizar</button> </div> </div> </div>', '', '', function(opts) {


        this.save = async () => {
            this.saveAnswers()
            globalRouter.page('/app/onboarding/finalizar')
        }

        this.questions = [
            { field: 'empreendedorismo_digital_1', weight: 2, tag: 'Empresas digitais', title: 'Do ponto de vista de um negócio digital, entendo com clareza a aplicação dos termos: escala, alcance e velocidade.' },
            { field: 'empreendedorismo_digital_2', weight: 1, tag: 'Disrupção', title: 'Encaro a disrupção como algo natural dos mercados e produtos, sendo uma oportunidade para novas ideias.' },
            { field: 'empreendedorismo_digital_3', weight: 1, reverse: true, tag: 'Crescimento Exponencial', title: 'Prefiro considerar um crescimento linear, onde consigo prever até onde vai o negócio, do que as variáveis para um crescimento exponencial.' },
            { field: 'empreendedorismo_digital_4', weight: 1, tag: 'Parcerias estratégicas', title: 'Procuro expandir minha rede de contatos constantemente, tendo em vista possíveis parcerias estratégicas.' },
            { field: 'empreendedorismo_digital_5', weight: 3, tag: 'Produtividade', title: 'Tenho atenção constante com o meu nível de produtividade, encontrando mecanismos para gestão de tempo e performance.' },
            { field: 'empreendedorismo_digital_6', weight: 2, reverse: true, tag: 'Cultura digital', title: 'Entendo que o desenvolvimento de uma cultura digital dentro do negócio se resume à contratação de ferramentas digitais.' },
            { field: 'empreendedorismo_digital_7', weight: 3, tag: 'Empresas digitais', title: 'Sempre observo o que acontece no mercado como uma maneira de encontrar oportunidades ou prever riscos.' },
        ]

        this.mixin('questions')
        this.prepare('empreendedorismo_digital')

});